<footer class="footer sec-padding">
  <div class="container">
      <div class="footer-wrapper" fxLayout="row wrap" fxLayoutAlign="space-between start">
        <div *ngIf="agence != null" fxFlex="100" fxFlex.gt-sm="30" fxFlex.sm="50" class="footer-widget about-widget">
          <div *ngIf="agence.adresse != null" fxLayout="row" class="tel-box">
            <div class="icon"><i class="fa fa-map-marker"></i></div>
            <div class="text">
              <p>{{agence.nom}}<br> {{agence.adresse.ligne1}}<br *ngIf="agence.adresse.ligne2"> {{agence.adresse.ligne2}}<br *ngIf="agence.adresse.ligne3"> {{agence.adresse.ligne3}}<br> {{agence.adresse.codepostal}} {{agence.adresse.ville}}</p>
            </div>
          </div>
        </div>

        <div *ngIf="agence.tel != null" fxFlex="100" fxFlex.gt-sm="23" fxFlex.sm="50" ngClass.xs="mt-2" class="footer-widget links-widget links-widget-pac">
          <div fxLayout="row" class="tel-box">
            <div class="icon"><i class="fa fa-phone"></i></div>
            <div class="text">
              <p>{{agence.tel}}</p>
            </div>
          </div>
        </div>


        <div *ngIf="agence.email != null" fxFlex="100" fxFlex.gt-sm="23" fxFlex.sm="50" ngClass.xs="mt-2" class="footer-widget subscribe-widget">
          <div fxLayout="row" class="tel-box">
            <div class="icon"><i class="fa fa-envelope"></i></div>
            <div class="text">
              <p><a href="mailto:{{agence.email}}">{{agence.email}}</a></p>
            </div>
          </div>
        </div>


        <div fxFlex="100" fxFlex.gt-sm="23" fxFlex.sm="50" ngClass.sm="mt-2" ngClass.xs="mt-2" class="footer-widget subscribe-widget">
          <ul fxLayout="row" class="social list-inline">
            <li><a href="https://etn.fr/" target="_blank"><i class="fa fa-globe"></i></a></li>
            <li><a href="https://www.linkedin.com/company/etn-fr/about/" target="_blank"><i class="fa fa-linkedin"></i></a></li>
            <li><a href="https://www.facebook.com/etndistribution" target="_blank"><i class="fa fa-facebook"></i></a></li>
          </ul>
        </div>

        <div fxFlex="100" class="text-center">
          <span style="font-size: 0.7rem; font-style: italic;">{{ 'footer.legalText'|translate }}</span>
        </div>
      </div>
  </div>
</footer>
