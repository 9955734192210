<mat-sidenav-container fullscreen>
    <mat-toolbar class="main-topbar" [ngClass]="{topbar_four: url == '/home'}">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center" class="top-bar container" >
                <span fxHide="false" fxHide.gt-sm   >
                    <button mat-icon-button>
                        <mat-icon>menu</mat-icon>
                    </button>
                 </span>
              <div fxLayout="row" class="welcome-message">
                <div *ngIf="authorizedCustomers != null && selectedCustomer != null && authorizedCustomers.length >= 1" class="widget-text">
                  <a mat-button [matMenuTriggerFor]="customerMenu" #customerMenuTrigger="matMenuTrigger">
                    {{selectedCustomer.fullname}}<mat-icon class="mat-icon-sm caret cur-icon">arrow_drop_down</mat-icon>
                  </a>
                  <mat-menu #customerMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="app-dropdown main-menu-header">
                    <span>
                        <button mat-menu-item *ngFor="let cust of authorizedCustomers" (click)="changeCustomer(cust)">
                            <span>{{cust.fullname}}</span>
                        </button>
                    </span>
                  </mat-menu>
                </div>
                <div class="widget-text">
                  <a mat-button [matMenuTriggerFor]="langMenu" #langMenuTrigger="matMenuTrigger">
                    <!-- <img [src]="flag.image" width="18"> -->
                    <span fxShow="false" fxShow.gt-sm class="flag-menu-title">{{flag.name}}</span>
                    <mat-icon class="mat-icon-sm caret cur-icon">arrow_drop_down</mat-icon>
                </a>
                <mat-menu #langMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="app-dropdown main-menu-header">
                    <span>
                        <button mat-menu-item *ngFor="let flag of flags" (click)="changeLang(flag)">
                            <!-- <img [src]="flag.image" width="18"> {{flag.name}} -->
                            <img width="18"> {{flag.name}}
                        </button>
                    </span>
                </mat-menu>
                </div>
              </div>
              <div class="widgets-left" fxLayout="row">

                <div class="widget-text">
                  <a fxShow="false" fxShow.gt-xs [routerLink]="['/pages/my-account']" routerLinkActive="router-link-active" ><mat-icon>person</mat-icon>{{ 'main.account'|translate }}</a>
                </div>
                <div class="widget-text">
                  <a fxShow="false" fxShow.gt-xs (click)="logout()" routerLinkActive="router-link-active" ><mat-icon>power_settings_new</mat-icon>{{ 'main.logout'|translate }}</a>
                </div>
              </div>
        </mat-toolbar-row>

      </mat-toolbar>

  <app-header></app-header>
  <router-outlet></router-outlet>

  <app-footer></app-footer>

</mat-sidenav-container>
