<div class="filter-list">
  <div *ngIf="filtresActifsView != null && filtresActifsView.length > 0" class="container">
    <ul class="list-inline">
      <li *ngFor="let fa of filtresActifsView">
        <a *ngIf="fa.type == null; else filtreAutre" (click)="supprimerFiltreMarqueFromListe(fa)">{{fa.title}}: {{fa.value}} <i class="fa fa-close"></i></a>
        <ng-template #filtreAutre>
          <a *ngIf="fa.type == 'STOCK'; else filtreFeature" (click)="supprimerEnStockFromListe(fa)">{{fa.title}}: {{fa.value}} <i class="fa fa-close"></i></a>
        </ng-template>
        <ng-template #filtreFeature>
          <a (click)="supprimerFiltreProduitFromListe(fa)">{{fa.title}}: {{fa.value}} <i class="fa fa-close"></i></a>
        </ng-template>
      </li>
    </ul>
  </div>
</div>

<div class="left-sidebar-wrapper sec-padding category-results">
  <div class="container">
    <ngx-spinner></ngx-spinner>

    <div class="sidebar-wrapper" fxLayout='row' fxLayout.lt-md="column" >
      <div fxFlex="25" fxFlex.lt-sm="100" class="filter-sidenav" perfectScrollbar>
        <div class="toggles-wrapper" fxLayout="column" fxLayout.lt-md="column">
          <div class="toggles" fxFlex.lt-md="100">
            <form *ngIf="selectedClass" class="filter-search-form" fxLayout="row" (ngSubmit)="onSubmitFilter()">
              <div class="filter-form-wrap">
                <input type="text" [(ngModel)]="filtreTexte" placeholder="{{ 'categories-search.textFilter'|translate }}" name="filter-search" id="filter-search-etim">
                <button class="filter-btn-search" type="submit"><mat-icon>search</mat-icon></button>
              </div>
            </form>
            <mat-accordion>
              <mat-expansion-panel [expanded]=classExpanded (opened)="changeExpandedClass()">
                <mat-expansion-panel-header [collapsedHeight]="'38px'" [expandedHeight]="'48px'">
                  <mat-panel-title>
                    <span>{{ 'categories-search.productFamily'|translate }}</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="brands">
                  <app-etimclasses [etimGroup]="groupe" [preselectedClass]="selectedClass" [isStock]="filtreEnStock" (classChanged)="onClassChanged($event)"></app-etimclasses>
                </div>
              </mat-expansion-panel>
              <mat-expansion-panel *ngIf="marques != null">
                <mat-expansion-panel-header [collapsedHeight]="'38px'" [expandedHeight]="'48px'">
                  <mat-panel-title>
                    <span>{{ 'categories-search.brands'|translate }}</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="category-contant">
                  <ul>
                    <li *ngFor="let m of getMarquesTriees()">
                      <mat-checkbox #check [checked]="m.selected" class="mat-checkbox" (change)="marqueCheckSelect(m, check.checked)" *ngIf="getMarqueCount(m) != null">{{m.marque__marque|uppercase}} ({{getMarqueCount(m)}})</mat-checkbox>
                    </li>
                  </ul>
                </div>
              </mat-expansion-panel>

              <div *ngFor="let c of caracteristiquesView.slice(0,NB_CARATERISTIQUES_VISIBLES)" class="filter-type">
                <div *ngIf="c.type == 'CHECK' && filtersFeatureCount[c.id] != null">
                  <span class="filter-title">{{c.name.slice(0,1)|uppercase}}{{c.name.slice(1)}}</span>
                  <ul class="filter-choice">
                    <mat-radio-group [(ngModel)]="c.value">
                      <mat-radio-button class="mat-radio-button" [value]="true" (change)="featureCheckSelect(c, true)">{{ 'categories-search.yes'|translate }} ({{getBooleanValueCount('TRUE', c.id)}})</mat-radio-button>
                      <mat-radio-button class="mat-radio-button" [value]="false" (change)="featureCheckSelect(c, false)">{{ 'categories-search.no'|translate }} ({{getBooleanValueCount('FALSE', c.id)}})</mat-radio-button>
                      <mat-radio-button class="mat-radio-button" [value]="null" (change)="featureCheckSelect(c, null)">{{ 'categories-search.none'|translate }}</mat-radio-button>
                    </mat-radio-group>
                  </ul>
                </div>
                <div *ngIf="c.type == 'LIST' && filtersFeatureCount[c.id] != null">
                  <span class="filter-title">{{c.name.slice(0,1)|uppercase}}{{c.name.slice(1)}} ({{filtersFeatureCount[c.id] == null ? 0 : filtersFeatureCount[c.id]}})</span>
                  <ul class="filter-choice">
                    <li *ngFor="let v of c.valuesList">
                      <mat-checkbox *ngIf="getValueCount(v, c.id) > 0" [checked]="v.selected" class="mat-checkbox" (change)="featureListSelect(c, v.id)">
                        {{v.libelle.slice(0,1)|uppercase}}{{v.libelle.slice(1)}} ({{getValueCount(v, c.id)}})
                      </mat-checkbox>
                    </li>
                    <li>
                      <mat-checkbox [checked]="c.autreSelected" class="mat-checkbox" (change)="featureListSelect(c, 'AUTRE')">{{ 'categories-search.empty'|translate }}</mat-checkbox>
                    </li>
                  </ul>
                </div>
                <div *ngIf="c.type == 'RANGE' && filtersFeatureCount[c.id] != null">
                  <span class="filter-title">{{c.name.slice(0,1)|uppercase}}{{c.name.slice(1)}} ({{filtersFeatureCount[c.id] == null ? 0 : filtersFeatureCount[c.id]}})</span>
                  <div class="price-slider" style="margin-bottom: 10px;">
                    <!--
                    <div fxLayout="row" fxLayoutAlign="space-between center" class="text-muted">
                      <span>De: <input type="number" class="input-range" [(ngModel)]="minRangeModels[c.id]" (change)="featureRange(c, minSlide.value, 'MIN', maxSlide.value)" value="{{minRangeModels[c.id]}}"> <b>{{c.unite}}</b></span>
                      <span>A: <input type="number" class="input-range" [(ngModel)]="maxRangeModels[c.id]" (change)="featureRange(c, maxSlide.value, 'MAX', minSlide.value)" value="{{maxRangeModels[c.id]}}"> <b>{{c.unite}}</b></span>
                    </div>-->
                    <div fxLayout="row" fxLayoutAlign="space-between center" class="text-muted">
                      <span>De: <input type="number" class="input-range" [(ngModel)]="minRangeModels[c.id]" (change)="featureRangeWithoutSlider($event, c, 'MIN')" value="{{minRangeModels[c.id]}}"> <b>{{c.unite}}</b></span>
                      <span>A: <input type="number" class="input-range" [(ngModel)]="maxRangeModels[c.id]" (change)="featureRangeWithoutSlider($event, c, 'MAX')" value="{{maxRangeModels[c.id]}}"> <b>{{c.unite}}</b></span>
                    </div>
                    <!--
                    <div fxLayout="row" fxLayoutAlign="space-between center" class="filter-price">
                      <mat-slider #minSlide (pointermove)="featureRangeValueUpdate(c, minSlide.value, 'MIN')" (change)="featureRange(c, minSlide.value, 'MIN', maxSlide.value)" color="primary" max="{{c.valeurMax}}" min="{{c.valeurMin}}" value="{{minRangeModels[c.id]}}" thumb-label="true"></mat-slider>
                      <mat-slider #maxSlide (pointermove)="featureRangeValueUpdate(c, maxSlide.value, 'MAX')" (change)="featureRange(c, maxSlide.value, 'MAX', minSlide.value)" color="warn" max="{{c.valeurMax}}" min="{{c.valeurMin}}" value="{{maxRangeModels[c.id]}}" thumb-label="true"></mat-slider>
                    </div> -->
                  </div>
                </div>
              </div>

              <mat-expansion-panel *ngIf="caracteristiquesView.length > 6">
                <mat-expansion-panel-header [collapsedHeight]="'38px'" [expandedHeight]="'48px'">
                  <mat-panel-title>
                    <span>{{ 'categories-search.otherFeatures'|translate }}</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div *ngFor="let c of caracteristiquesView.slice(NB_CARATERISTIQUES_VISIBLES)" class="filter-type">
                  <div *ngIf="c.type == 'CHECK' && filtersFeatureCount[c.id] != null">
                    <span class="filter-title">{{c.name.slice(0,1)|uppercase}}{{c.name.slice(1)}}</span>
                    <ul class="filter-choice">
                      <mat-radio-group [(ngModel)]="c.value">
                        <mat-radio-button class="mat-radio-button" [value]="true" (change)="featureCheckSelect(c, true)">{{ 'categories-search.yes'|translate }} ({{getBooleanValueCount('TRUE', c.id)}})</mat-radio-button>
                        <mat-radio-button class="mat-radio-button" [value]="false" (change)="featureCheckSelect(c, false)">{{ 'categories-search.no'|translate }} ({{getBooleanValueCount('FALSE', c.id)}})</mat-radio-button>
                      </mat-radio-group>
                    </ul>
                  </div>
                  <div *ngIf="c.type == 'LIST' && filtersFeatureCount[c.id] != null">
                    <span class="filter-title">{{c.name.slice(0,1)|uppercase}}{{c.name.slice(1)}} ({{filtersFeatureCount[c.id] == null ? 0 : filtersFeatureCount[c.id]}})</span>
                    <ul class="filter-choice">
                      <li *ngFor="let v of c.valuesList">
                        <mat-checkbox *ngIf="getValueCount(v, c.id) > 0" [checked]="v.selected" class="mat-checkbox" (change)="featureListSelect(c, v.id)">
                          {{v.libelle.slice(0,1)|uppercase}}{{v.libelle.slice(1)}} ({{getValueCount(v, c.id)}})
                        </mat-checkbox>
                      </li>
                      <li>
                        <mat-checkbox [checked]="c.autreSelected" class="mat-checkbox" (change)="featureListSelect(c, 'AUTRE')">{{ 'categories-search.empty'|translate }}</mat-checkbox>
                      </li>
                    </ul>
                  </div>
                  <div *ngIf="c.type == 'RANGE' && filtersFeatureCount[c.id] != null">
                    <span class="filter-title">{{c.name.slice(0,1)|uppercase}}{{c.name.slice(1)}} ({{filtersFeatureCount[c.id] == null ? 0 : filtersFeatureCount[c.id]}})</span>
                    <div class="price-slider" style="margin-bottom: 10px;">
                      <!--
                      <div fxLayout="row" fxLayoutAlign="space-between center" class="text-muted">
                        <span>De: <input type="number" class="input-range" [(ngModel)]="minRangeModels[c.id]" (change)="featureRange(c, minSlide.value, 'MIN', maxSlide.value)" value="{{minRangeModels[c.id]}}"> <b>{{c.unite}}</b></span>
                        <span>A: <input type="number" class="input-range" [(ngModel)]="maxRangeModels[c.id]" (change)="featureRange(c, maxSlide.value, 'MAX', minSlide.value)" value="{{maxRangeModels[c.id]}}"> <b>{{c.unite}}</b></span>
                      </div>-->
                      <div fxLayout="row" fxLayoutAlign="space-between center" class="text-muted">
                        <span>{{ 'categories-search.from'|translate }}: <input type="number" class="input-range" [(ngModel)]="minRangeModels[c.id]" (change)="featureRangeWithoutSlider($event, c, 'MIN')" value="{{minRangeModels[c.id]}}"> <b>{{c.unite}}</b></span>
                        <span>{{ 'categories-search.to'|translate }}: <input type="number" class="input-range" [(ngModel)]="maxRangeModels[c.id]" (change)="featureRangeWithoutSlider($event, c, 'MAX')" value="{{maxRangeModels[c.id]}}"> <b>{{c.unite}}</b></span>
                      </div>
                      <!--
                      <div fxLayout="row" fxLayoutAlign="space-between center" class="filter-price">
                        <mat-slider #minSlide (pointermove)="featureRangeValueUpdate(c, minSlide.value, 'MIN')" (change)="featureRange(c, minSlide.value, 'MIN', maxSlide.value)" color="primary" max="{{c.valeurMax}}" min="{{c.valeurMin}}" value="{{minRangeModels[c.id]}}" thumb-label="true"></mat-slider>
                        <mat-slider #maxSlide (pointermove)="featureRangeValueUpdate(c, maxSlide.value, 'MAX')" (change)="featureRange(c, maxSlide.value, 'MAX', minSlide.value)" color="warn" max="{{c.valeurMax}}" min="{{c.valeurMin}}" value="{{maxRangeModels[c.id]}}" thumb-label="true"></mat-slider>
                      </div> -->
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>

            </mat-accordion>
          </div>
        </div>

      </div>
      <div fxFlex="75" fxFlex.lt-sm="100" class="all-products" ngClass.gt-sm="p-left">
        <div class="mat-cell text-left" style="padding-top: 10px;">
          <mat-checkbox #check [checked]="filtreEnStock" class="mat-checkbox" (change)="inStockCheckSelect(check.checked)">{{ 'categories-search.onlyInStock'|translate }}</mat-checkbox>
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" fxHide.lt-md="true" class="filter-row mat-elevation-z1 text-muted">
          <div class="selected-class">
            <a (click)="goToFamille(famille.artfamilleid)" style="cursor: pointer;">{{getFamilleDescription(famille)}}</a> -> <a (click)="goToGroupe(famille.artfamilleid, groupe.artgroupid)" style="cursor: pointer;">{{getGroupeDescription(groupe)}}</a>
            <span *ngIf="selectedClass != null"> -> {{getSelectedClassDescription()}}</span>
          </div>
          <div>
            &nbsp;
          </div>
        </div>
        <div *ngIf="produits != null && produits.results != null && produits.results.length > 0" class="result-number">
          {{getNombreProduits()}} {{ 'categories-search.results'|translate }}
        </div>
        <div *ngIf="produits != null && produits.results != null && produits.results.length > 0; else noProduct" class="cart-wrapper sec-padding">
          <mat-card class="p-0">
            <div class="mat-table cart-table">
              <div class="mat-header-row">
                <div class="mat-header-cell" style="max-width: 150px;">{{ 'categories-search.product'|translate }}</div>
                <div class="mat-header-cell-sortable">
                  <div class="mat-header-title-block">
                    <span class="mat-header-title">{{ 'categories-search.ref'|translate }}</span>
                    <mat-icon *ngIf="sortedReference == null" (click)="trierResultatsParReference()">swap_vert</mat-icon>
                    <mat-icon *ngIf="sortedReference == 'ASC'" (click)="trierResultatsParReference()">arrow_upward</mat-icon>
                    <mat-icon *ngIf="sortedReference == 'DESC'" (click)="trierResultatsParReference()">arrow_downward</mat-icon>
                  </div>
                </div>
                <div class="mat-header-cell-sortable" style="max-width: 200px;">
                  <div class="mat-header-title-block">
                    <span class="mat-header-title">{{ 'categories-search.brand'|translate }}</span>
                    <mat-icon *ngIf="sortedMarque == null" (click)="trierResultatsParMarque()">swap_vert</mat-icon>
                    <mat-icon *ngIf="sortedMarque == 'ASC'" (click)="trierResultatsParMarque()">arrow_upward</mat-icon>
                    <mat-icon *ngIf="sortedMarque == 'DESC'" (click)="trierResultatsParMarque()">arrow_downward</mat-icon>
                  </div>
                </div>
                <div class="mat-header-cell" style="max-width: 100px;">{{ 'categories-search.available'|translate }}</div>
                <div class="mat-header-cell text-center" style="max-width: 50px;">
                  &nbsp;
                </div>
              </div>
              <div *ngFor="let p of produits.results | paginate: { itemsPerPage: itemPerPage, currentPage: page, totalItems: produits.count  }" class="mat-row">
                <div class="mat-cell" style="max-width: 150px;">
                  <a class="product-link" (click)="openProductDetailDialog(p)">
                    <img [src]="getImagePrincipale(p, false)" alt="">
                  </a>
                </div>
                <div class="mat-cell" style="text-align: left;">
                  <a class="product-name" (click)="openProductDetailDialog(p)">{{p.ref}} <span class="product-name-second">{{getLibelleProduit(p)}}</span></a><br>
                  <span *ngIf="getLibelleCodeArticle(p) != null" class="product-code-article">{{ 'categories-search.productCode'|translate }} : {{getLibelleCodeArticle(p)}}</span><br *ngIf="getLibelleCodeArticle(p) != null">
                  <a *ngIf="p.filAriane != null" class="fil-ariane-product" (click)="goToFamille(p.filAriane.familleid)">{{p.filAriane.familledesc}}</a>
                  <span *ngIf="p.filAriane != null" class="fil-ariane-product">&nbsp;->&nbsp;</span>
                  <a *ngIf="p.filAriane != null" class="fil-ariane-product" (click)="goToGroupe(p.filAriane.familleid, p.filAriane.groupeid)">{{p.filAriane.groupedesc}}</a>
                  <span *ngIf="p.filAriane != null" class="fil-ariane-product">&nbsp;->&nbsp;</span>
                  <a *ngIf="p.filAriane != null" class="fil-ariane-product" (click)="goToClasse(p.filAriane.familleid, p.filAriane.groupeid, p.filAriane.classeid)">{{p.filAriane.classedesc}}</a>
                </div>
                <div class="mat-cell" style="max-width: 200px;">{{p.marque.marque}}</div>

                <div class="mat-cell" style="max-width: 100px;">
                  <span *ngIf="isEnStock(p); else noStock" class="text-success">{{ 'categories-search.inStock'|translate }}</span>
                  <ng-template #noStock>
                    <span class="text-danger">{{ 'categories-search.inOrder'|translate }}</span>
                  </ng-template>
                </div>

                <div class="mat-cell text-center" style="max-width: 50px;">
                  <div class="p-1">
                    <mat-icon class="close" (click)="openProductDetailDialog(p)">shopping_cart</mat-icon>
                  </div>
                </div>

              </div>
            </div>
          </mat-card>
          <div fxLayout="row wrap ">
            <div fxFlex="100">
              <mat-card class="p-0 text-center no-shadow">
                <pagination-controls class="product-pagination" autoHide="true" maxSize="5" (pageChange)="onPageChanged($event)" previousLabel="{{ 'categories-search.previous'|translate }}" nextLabel="{{ 'categories-search.next'|translate }}"></pagination-controls>
              </mat-card>
            </div>
          </div>
          <!--
          <div *ngFor="let p of produits.results | paginate: { itemsPerPage: produits.results.length, currentPage: page, totalItems: produits.count  }" fxFlex="100" [fxFlex.gt-sm]="viewCol" fxFlex.sm="50" class="col">
            <div class="product-box">
              <app-product [produit]="p"></app-product>
            </div>
          </div>
          -->
        </div>
        <ng-template #noProduct>
          <div *ngIf="produits == null; else noSearch" fxLayout="row wrap" class="products-wrapper">
            <span class="text-center">{{ 'categories-search.noProductInStockMessage'|translate }}</span>
          </div>
          <ng-template #noSearch>
            <div fxLayout="row wrap" class="products-wrapper">
              <span class="text-center">{{ 'categories-search.noProductMessage'|translate }}</span>
            </div>
          </ng-template>
        </ng-template>
      </div>
    </div>
  </div>
</div>
