import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable, Subject} from 'rxjs';

import {CookieService} from 'ngx-cookie-service';
import {queryPaginated} from '../cors/handlers';
import {UserBack, UserLanguage} from '../models/userback';
import {Page} from '../models/page';
import {OAuthService, TokenResponse} from 'angular-oauth2-oidc';
import {ApiEnum} from '../enums/api.enum';
import {GeneralEnum} from '../enums/general.enum';
import {Customer} from '../models/customer';
import {CustomerService} from './customer.service';
import {AgenceService} from './agence.service';
import {Agence} from '../models/agence';
import {TranslateService} from '@ngx-translate/core';
import {LangueEnum} from '../enums/langue.enum';
import {ChangePassword, ResetPassword, SetPassword} from '../models/password';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<UserBack>;
  private updateAgenceSelection = new Subject<any>();
  public currentUser: Observable<UserBack>;

  constructor(private http: HttpClient,
              private cookieService: CookieService,
              private oauthService: OAuthService,
              private customerService: CustomerService,
              private agenceService: AgenceService,
              private translateService: TranslateService) {
    this.currentUserSubject = new BehaviorSubject<UserBack>(JSON.parse(localStorage.getItem(GeneralEnum.STORAGE_USER)));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): UserBack {
    return this.currentUserSubject.value;
  }

  async login(username: string, password: string) {
    const header = new HttpHeaders({'Content-type': 'application/x-www-form-urlencoded;'});

    const reponse: TokenResponse = await this.oauthService.fetchTokenUsingPasswordFlow(username, password, header).catch(x => Promise.reject('Identifiant/Mot de passe invalide'));
    this.oauthService.setupAutomaticSilentRefresh();

    if (localStorage.getItem(GeneralEnum.STORAGE_SELECTION)) {
      localStorage.removeItem(GeneralEnum.STORAGE_SELECTION);
    }

    const reponsePaginee: Page<UserBack> = await this.saveToken(reponse);
    localStorage.setItem(GeneralEnum.STORAGE_USER, JSON.stringify(reponsePaginee.results[0]));
    this.defineUserLanguage(reponsePaginee.results[0]);
    this.currentUserSubject.next(reponsePaginee.results[0]);

    const customer = await this.infosClient(reponsePaginee.results[0].customer_selected);
    localStorage.setItem(GeneralEnum.STORAGE_CUSTOMER, JSON.stringify(customer));

    if (customer != null && customer.agency != null) {
      const agence = await this.infosAgence(customer.agency);

      if (agence != null && agence.results != null) {
        localStorage.setItem(GeneralEnum.STORAGE_AGENCE, JSON.stringify(agence.results[0]));
      }
    }

    const authorizedCust = await this.authorizedCustomers();
    localStorage.setItem(GeneralEnum.STORAGE_CUSTOMERS_LIST, authorizedCust != null && authorizedCust.results != null ? JSON.stringify(authorizedCust.results) : null);

    // this.translateService.use('fr-FR');
    // this.translateService.setDefaultLang('en-GB');

    return reponsePaginee.results[0];
  }

  async punchoutLogin(token: string) {
    // Patch pour activer l'OAuth à partir du token (qui contient le refresh_token)
    sessionStorage.setItem('refresh_token', token);

    await this.oauthService.refreshToken()
      .then(async (tokenResponse) => {
        this.oauthService.setupAutomaticSilentRefresh();

        if (localStorage.getItem(GeneralEnum.STORAGE_SELECTION)) {
          localStorage.removeItem(GeneralEnum.STORAGE_SELECTION);
        }

        await this.saveToken(tokenResponse)
          .then(async (reponsePaginee) => {
            localStorage.setItem(GeneralEnum.STORAGE_USER, JSON.stringify(reponsePaginee.results[0]));
            this.defineUserLanguage(reponsePaginee.results[0]);
            this.currentUserSubject.next(reponsePaginee.results[0]);

            await this.infosClient(reponsePaginee.results[0].customer_selected)
              .then(async (customer) => {
                localStorage.setItem(GeneralEnum.STORAGE_CUSTOMER, JSON.stringify(customer));

                await this.authorizedCustomers()
                  .then(async (authorizedCust) => {
                    localStorage.setItem(GeneralEnum.STORAGE_CUSTOMERS_LIST, authorizedCust != null && authorizedCust.results != null ? JSON.stringify(authorizedCust.results) : null);

                    // this.translateService.use('fr-FR');
                    // this.translateService.setDefaultLang('en-GB');

                    if (customer != null && customer.agency != null) {
                      await this.infosAgence(customer.agency)
                        .then(async (agence) => {
                          if (agence != null && agence.results != null) {
                            localStorage.setItem(GeneralEnum.STORAGE_AGENCE, JSON.stringify(agence.results[0]));
                          }

                          await Promise.resolve();
                        }).catch(agErr => Promise.reject(agErr));
                    } else {
                      await Promise.resolve();
                    }
                  }).catch(authCustErr => Promise.reject(authCustErr));
              }).catch(custErr => Promise.reject(custErr));
          }).catch(saveErr => Promise.reject(saveErr));
      }).catch(x => Promise.reject(x));
  }

  async refreshToken() {
    const reponse: TokenResponse = await this.oauthService.refreshToken().catch(x => Promise.reject('Impossible de rafraichir le token de session'));

    const expireDate = new Date().getTime() + (1000 * reponse.expires_in);
    this.cookieService.set(GeneralEnum.COOKIE_TOKEN_NAME, reponse.access_token, expireDate);

    return reponse;
  }

  async refreshInfosUser() {
    const customer = JSON.parse(localStorage.getItem(GeneralEnum.STORAGE_CUSTOMER));

    if (customer != null && customer.agency != null) {
      this.agenceService.detail(customer.agency).subscribe({
        next: agence => {
          if (agence != null && agence.results != null) {
            localStorage.setItem(GeneralEnum.STORAGE_AGENCE, JSON.stringify(agence.results[0]));
            this.sendUpdateAgence();
          }
        },
        error: err => console.log('Impossible de récupérer les informations d\'agence')
      });
    }
  }

  defineUserLanguage(user: UserBack) {
    let langue = 'en-GB';
    if (user.languages != null && user.languages.length > 0) {
      langue = user.languages[0].code;
    } else {
      if (navigator.language === LangueEnum.ANGLAIS ||
        navigator.language === LangueEnum.FRANCAIS ||
        navigator.language === LangueEnum.ESPAGNOL ||
        navigator.language === LangueEnum.ALLEMAND) {
        langue = navigator.language;
      }
    }

    localStorage.setItem(GeneralEnum.STORAGE_LANGUAGE, langue);

    this.translateService.use(langue);
    this.translateService.setDefaultLang('en-GB');
  }

  saveUserLanguage(langue: string) {
    const user: UserBack = JSON.parse(localStorage.getItem(GeneralEnum.STORAGE_USER));
    const userLanguages: Array<UserLanguage> = new Array<UserLanguage>({ code: langue });

    const userToSave: UserBack = {
      id: user.id,
      username: user.username,
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      languages: userLanguages
    };

    return this.changeUserInformations(userToSave);
  }

  saveToken(token): Promise<Page<UserBack>> {
    const expireDate = new Date().getTime() + (1000 * token.expires_in);
    this.cookieService.set(GeneralEnum.COOKIE_TOKEN_NAME, token.access_token, expireDate);

    return queryPaginated<UserBack>(this.http, ApiEnum.USER_INFO).toPromise().catch(x => Promise.reject('Impossible de récupérer les informations utilisateur'));
  }

  saveTokenPunchout(token): Promise<Page<UserBack>> {
    const currentDate = new Date();
    /*
    sessionStorage.setItem('access_token', token.access_token);
    sessionStorage.setItem('access_token_stored_at', '1678430286838');
    sessionStorage.setItem('expires_at', token.expires_in);
    sessionStorage.setItem('granted_scopes', token.scope);
    sessionStorage.setItem('refresh_token', token.refresh_token);

    storageFactory().setItem('access_token', token.access_token);
    storageFactory().setItem('access_token_stored_at', currentDate.getTime().toString());
    storageFactory().setItem('expires_at', (currentDate.getTime() + (token.expires_in * 1000)).toString());
    storageFactory().setItem('granted_scopes', '["' + token.scope + '"]');
    storageFactory().setItem('refresh_token', token.refresh_token);
    storageFactory().setItem('id_token', token.id_token);

    console.log(storageFactory().getItem('access_token'));
    console.log(storageFactory().getItem('access_token_stored_at'));
    console.log(storageFactory().getItem('expires_at'));
    console.log(storageFactory().getItem('granted_scopes'));
    console.log(storageFactory().getItem('refresh_token'));
    console.log(storageFactory().getItem('id_token')); */

    const expireDate = new Date().getTime() + (1000 * token.expires_in);
    this.cookieService.set(GeneralEnum.COOKIE_TOKEN_NAME, token.access_token, expireDate);

    return queryPaginated<UserBack>(this.http, ApiEnum.USER_INFO).toPromise().catch(x => Promise.reject('Impossible de récupérer les informations utilisateur'));
  }

  infosClient(id): Promise<Customer> {
    return this.customerService.detail(id).toPromise().catch(x => Promise.reject('Impossible de récupérer les informations client'));
  }

  infosAgence(id): Promise<Page<Agence>> {
    return this.agenceService.detail(id).toPromise().catch(x => Promise.reject('Impossible de récupérer les informations agence'));
  }

  authorizedCustomers(): Promise<Page<Customer>> {
    return this.customerService.listCustomersAuthorized().toPromise().catch(x => Promise.reject('Impossible de récupérer la liste des clients autorisés'));
  }

  logout() {
    // remove user from local storage to log user out
    /*
    localStorage.removeItem(GeneralEnum.STORAGE_USER);
    localStorage.removeItem(GeneralEnum.STORAGE_SELECTION);
    localStorage.removeItem(GeneralEnum.STORAGE_FILTERS_CLASS_COUNT);*/

    localStorage.clear();
    // sessionStorage.clear();

    this.currentUserSubject.next(null);
    this.cookieService.delete(GeneralEnum.COOKIE_TOKEN_NAME);
  }

  changePassword(password: ChangePassword) {
    return this.http.patch<any>(ApiEnum.SECURITY_CHANGE_PASSWORD, password);
  }

  resetPassword(password: ResetPassword) {
    return this.http.post<any>(ApiEnum.SECURITY_RESET_PASSWORD, password);
  }

  setPasswordAfterReset(password: SetPassword) {
    return this.http.patch<any>(ApiEnum.SECURITY_SET_PASSWORD, password);
  }

  changeUserInformations(user: UserBack) {
    return this.http.put<any>(ApiEnum.USER_UPDATE + user.id + '/', user);
  }

  public sendUpdateAgence() {
    this.updateAgenceSelection.next();
  }

  public getUpdateAgence() {
    return this.updateAgenceSelection.asObservable();
  }
}
