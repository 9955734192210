import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ProduitService } from '../../../services/produit.service';
import { Page } from '../../../models/page';
import { ActivatedRoute, Router } from '@angular/router';
import { BasketService } from '../../../services/basket.service';
import { PricesService } from '../../../services/prices.service';
import { GeneralEnum } from '../../../enums/general.enum';
import { Produitautocompletefull } from '../../../models/produitautocompletefull';
import { environment } from '../../../../environments/environment';
import { SearchService } from '../../../services/search.service';
import { ArianeItem, Etimsearch } from '../../../models/etimsearch';
import { Famille } from '../../../models/famille';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { ProductDetailsDialogComponent } from '../../products/product-details-dialog/product-details-dialog.component';
import { Etimclassutil } from '../../../util/etimclassutil';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-product-search',
  templateUrl: './product-search-auto.component.html',
  styleUrls: ['./product-search-auto.component.sass'],
})
export class ProductSearchAutoComponent implements OnInit {
  @Output() eventOpenProductDialog: EventEmitter<any> = new EventEmitter();
  @Output() eventOpenProductDetailDialog: EventEmitter<any> =
    new EventEmitter();

  @ViewChild('tabGroup', { static: false }) tabGroup: MatTabGroup;

  recherche: string;

  public viewType: string = 'grid';
  public viewCol: number = 25;
  public page: any;

  error = '';

  sortedReference = null;
  sortedMarque = null;

  produits: Page<Produitautocompletefull>;
  searchEtimResults: Page<Etimsearch>;
  filAriane: Array<ArianeItem>;
  numeroAgence: number;
  produitsEnStock: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private produitService: ProduitService,
    private searchService: SearchService,
    private pricesService: PricesService,
    private route: ActivatedRoute,
    private router: Router,
    private basketService: BasketService,
    private dialog: MatDialog,
    private ngxSpinner: NgxSpinnerService
  ) {
    this.numeroAgence = JSON.parse(
      localStorage.getItem(GeneralEnum.STORAGE_AGENCE)
    ).numero;
  }

  ngOnInit() {
    const langue = localStorage.getItem(GeneralEnum.STORAGE_LANGUAGE);

    this.route.queryParams.subscribe((params) => {
      if (params) {
        this.recherche = JSON.parse(params.recherche);
        this.ngxSpinner.show();

        this.searchService.searchEtim(this.recherche).subscribe({
          next: (result) => {
            this.searchEtimResults = result;
            this.filAriane = new Array<ArianeItem>();
            this.construireFilsAriane();
          },
          error: (err) => (this.error = err),
        });

        this.produitService
          .searchAutocompleteFull(
            this.recherche,
            langue,
            null,
            null,
            this.produitsEnStock
          )
          .subscribe({
            next: (value) => {
              this.produits = value;

              if (this.produits != null && this.produits.results != null) {
                this.produits.results.forEach((p) => {
                  p.filAriane = Etimclassutil.getGroupeArianeFromClasse(
                    p.produit.artclassid
                  );
                });
              }

              // if (this.produits == null || this.produits.count === 0) {
              if (this.filAriane != null && this.filAriane.length > 0) {
                this.tabGroup.selectedIndex = this.tabGroup._tabs.length;
              } else {
                this.tabGroup.selectedIndex = 0;
              }

              this.page = 1;
              this.ngxSpinner.hide();
            },
            error: (err) => {
              this.error = err;
              this.ngxSpinner.hide();
            },
          });
      }
    });
  }

  public getLibelleProduit(produit: Produitautocompletefull) {}

  public getLibelleCodeArticle(produit: Produitautocompletefull) {
    const infosClient = JSON.parse(
      localStorage.getItem(GeneralEnum.STORAGE_CUSTOMER)
    );
    let libelle = null;

    if (produit.codeArticle != null && produit.codeArticle.length > 0) {
      produit.codeArticle.forEach((c) => {
        const tuple = c
          .replace('(', '')
          .replace(')', '')
          .replace("'", '')
          .split(',');

        if (+tuple[0] === infosClient.id) {
          if (libelle == null) {
            libelle = tuple[1].replace("'", '');
          } else {
            libelle += ' - ' + tuple[1].replace("'", '');
          }
        }
      });
    }

    return libelle;
  }

  public getNombreProduits() {
    if (this.produits != null) {
      return this.produits.count;
    } else {
      return 0;
    }
  }

  public onPageChanged(event) {
    const langue = localStorage.getItem(GeneralEnum.STORAGE_LANGUAGE);

    this.produitService
      .searchAutocompleteFull(this.recherche, langue, event)
      .subscribe({
        next: (value) => {
          this.produits = value;

          if (this.produits != null && this.produits.results != null) {
            this.produits.results.forEach((p) => {
              p.filAriane = Etimclassutil.getGroupeArianeFromClasse(
                p.produit.artclassid
              );
            });
          }

          this.page = event;
        },
        error: (err) => (this.error = err),
      });

    window.scrollTo(0, 0);
  }

  public addToCart(produit: Produitautocompletefull, quantity: number = 1) {
    const idcustomer = JSON.parse(
      localStorage.getItem(GeneralEnum.STORAGE_USER)
    ).customer_selected;

    this.produitService
      .get(+produit.idproduit)
      .toPromise()
      .then((prod) => {
        this.pricesService
          .list(prod.id, idcustomer)
          .toPromise()
          .then((prices) => {
            const prixProduit =
              prices != null && prices.results.length > 0
                ? prices.results[0].price
                : null;
            const prixDevise =
              prices != null && prices.results.length > 0
                ? prices.results[0].devise
                : null;
            const prixUnite =
              prices != null && prices.results.length > 0
                ? prices.results[0].unite_affi
                : null;
            const conditionnement =
              prices != null &&
              prices.results.length > 0 &&
              prices.results[0].unite_affi === 2
                ? prices.results[0].cdt
                : null;

            this.basketService.addProductToSelection(
              prod,
              quantity,
              prixProduit,
              prixDevise,
              prixUnite,
              conditionnement
            );
            this.router.navigate(['/pages/selection']);
          });
      });
  }

  public getImagePrincipale(p: Produitautocompletefull, miniature: boolean) {
    let image = 'assets/images/product/materiel_default.jpg';

    if (
      p.produit.documentations != null &&
      p.produit.documentations.length > 0
    ) {
      let imgList = p.produit.documentations.filter(
        (doc) => doc.typedoc.toUpperCase() === 'ISW'
      );

      if (imgList != null && imgList.length > 0) {
        imgList = imgList.sort((d1, d2) => d2.priorite - d1.priorite);
        image =
          environment.url_images +
          (miniature ? 'W50/' : 'W400/') +
          imgList[0].url;
      }
    }

    return image;
  }

  public construireFilsAriane() {
    if (
      this.searchEtimResults != null &&
      this.searchEtimResults.results != null
    ) {
      const familles = JSON.parse(
        localStorage.getItem(GeneralEnum.STORAGE_FAMILLES)
      );
      const langue = localStorage.getItem(GeneralEnum.STORAGE_LANGUAGE);

      this.searchEtimResults.results.forEach((res) => {
        const blocs = res.render.split(',');
        let familleId = null;
        let familleDesc = null;
        let groupId = null;
        let groupDesc = null;
        let classId = null;
        let classDesc = null;

        if (blocs != null) {
          if (blocs.length >= 1) {
            familleId = blocs[0];
            familleDesc = this.getFamilleDescFromId(
              familleId,
              familles,
              langue
            );
          }

          if (blocs.length >= 2) {
            groupId = blocs[1];
            groupDesc = this.getGroupeDescFromId(
              familleId,
              groupId,
              familles,
              langue
            );
          }

          if (blocs.length >= 1) {
            classId = blocs[2];
            classDesc = this.getClasseDescFromId(
              familleId,
              groupId,
              classId,
              familles,
              langue
            );
          }
        }

        this.filAriane.push({
          familleid: familleId,
          familledesc: familleDesc,
          groupid: groupId,
          groupdesc: groupDesc,
          classid: classId,
          classdesc: classDesc,
        });
      });

      // Tri du fil d'ariane par famille
      this.filAriane.sort((a, b) => {
        return (
          a.familledesc.localeCompare(b.familledesc) ||
          a.groupdesc.localeCompare(b.groupdesc) ||
          a.classdesc.localeCompare(b.classdesc)
        );
      });
    }
  }

  private getFamilleDescFromId(
    familleId: string,
    familles: Array<Famille>,
    langue: string
  ) {
    let description = null;
    const famille = familles.filter((f) => f.artfamilleid === familleId);

    if (famille != null && famille.length === 1) {
      const desc = famille[0].descriptions.filter((d) => d.langue === langue);

      if (desc != null && desc.length === 1) {
        description = desc[0].artfamilledesc;
      }
    }

    return description;
  }

  private getGroupeDescFromId(
    familleId: string,
    groupId: string,
    familles: Array<Famille>,
    langue: string
  ) {
    let description = null;
    const famille = familles.filter((f) => f.artfamilleid === familleId);

    if (famille != null && famille.length === 1) {
      const groupe = famille[0].groups.filter((g) => g.artgroupid === groupId);

      if (groupe != null && groupe.length === 1) {
        const desc = groupe[0].groupdesc.filter((d) => d.langue === langue);

        if (desc != null && desc.length === 1) {
          description = desc[0].groupdesc;
        }
      }
    }

    return description;
  }

  private getClasseDescFromId(
    familleId: string,
    groupId: string,
    classId: string,
    familles: Array<Famille>,
    langue: string
  ) {
    let description = null;
    const famille = familles.filter((f) => f.artfamilleid === familleId);

    if (famille != null && famille.length === 1) {
      const groupe = famille[0].groups.filter((g) => g.artgroupid === groupId);

      if (groupe != null && groupe.length === 1) {
        const classe = groupe[0].etimartclass.filter(
          (c) => c.artclassid === classId
        );

        if (classe != null && classe.length === 1) {
          const desc = classe[0].arclassdesc.filter((d) => d.langue === langue);

          if (desc != null && desc.length === 1) {
            description = desc[0].arclassdesc;
          }
        }
      }
    }

    return description;
  }

  inStockCheckSelect(checked) {
    this.produitsEnStock = checked;

    const langue = localStorage.getItem(GeneralEnum.STORAGE_LANGUAGE);

    this.produitService
      .searchAutocompleteFull(
        this.recherche,
        langue,
        null,
        null,
        this.produitsEnStock
      )
      .subscribe({
        next: (value) => {
          this.produits = value;

          if (this.produits != null && this.produits.results != null) {
            this.produits.results.forEach((p) => {
              p.filAriane = Etimclassutil.getGroupeArianeFromClasse(
                p.produit.artclassid
              );
            });
          }

          this.page = 1;
        },
        error: (err) => (this.error = err),
      });

    window.scrollTo(0, 0);
  }

  public openProductDetailDialog(produitautofull: Produitautocompletefull) {
    this.produitService.get(produitautofull.idproduit).subscribe({
      next: (value) => {
        this.dialog.open(ProductDetailsDialogComponent, {
          data: value,
          panelClass: 'product-dialog',
        });
      },
      error: (err) => (this.error = err),
    });
  }

  public isEnStockLocal(produit: Produitautocompletefull) {
    if (
      produit.produit != null &&
      produit.produit.stock != null &&
      produit.produit.stock.length > 0
    ) {
      const pStock = produit.produit.stock.filter(
        (s) => s.quantite > 0 && s.agence === this.numeroAgence
      );
      if (pStock != null && pStock.length > 0) {
        return true;
      }
    }

    return false;
  }

  public isEnStockGroupe(produit: Produitautocompletefull) {
    if (
      produit.produit != null &&
      produit.produit.stock_group != null &&
      produit.produit.stock_group.length > 0
    ) {
      const pStock = produit.produit.stock_group.filter(
        (s) => s.quantite__sum > 0
      );
      if (pStock != null && pStock.length > 0) {
        return true;
      }
    }

    return false;
  }

  public isEnStock(produit: Produitautocompletefull) {
    return this.isEnStockLocal(produit) || this.isEnStockGroupe(produit);
  }

  public trierResultatsParReference() {
    this.sortedMarque = null;

    if (this.sortedReference == null || this.sortedReference === 'DESC') {
      this.sortedReference = 'ASC';
      this.rechercherProduits('ref');
    } else {
      this.sortedReference = 'DESC';
      this.rechercherProduits('-ref');
    }
  }

  public trierResultatsParMarque() {
    this.sortedReference = null;

    if (this.sortedMarque == null || this.sortedMarque === 'DESC') {
      this.sortedMarque = 'ASC';
      this.rechercherProduits('marque');
    } else {
      this.sortedMarque = 'DESC';
      this.rechercherProduits('-marque');
    }
  }

  private rechercherProduits(ordering?: string) {
    const langue = localStorage.getItem(GeneralEnum.STORAGE_LANGUAGE);
    this.ngxSpinner.show();

    this.produitService
      .searchAutocompleteFull(
        this.recherche,
        langue,
        null,
        ordering,
        this.produitsEnStock
      )
      .subscribe({
        next: (value) => {
          this.produits = value;

          if (this.produits != null && this.produits.results != null) {
            this.produits.results.forEach((p) => {
              p.filAriane = Etimclassutil.getGroupeArianeFromClasse(
                p.produit.artclassid
              );
            });
          }

          this.page = 1;
          this.ngxSpinner.hide();
        },
        error: (err) => {
          this.error = err;
          this.ngxSpinner.hide();
        },
      });
  }

  public goToCategorieSearch(produit: Produitautocompletefull) {
    const hierarchie = Etimclassutil.getHierarchieIds(
      produit.produit.artclassid
    );
    this.router.navigate(['/catalogue/category/search'], {
      queryParams: {
        type: 'groupe',
        id: hierarchie.groupe,
        idFamille: hierarchie.famille,
        idClass: hierarchie.classe,
      },
    });
  }

  public goToFamille(familleid: string) {
    this.router.navigate(['/catalogue/groups/search'], {
      queryParams: { type: 'famille', id: familleid },
    });
  }

  public goToGroupe(familleid: string, groupeid: string) {
    this.router.navigate(['/catalogue/category/search'], {
      queryParams: { type: 'groupe', id: groupeid, idFamille: familleid },
    });
  }

  public goToClasse(familleid: string, groupeid: string, classeid: string) {
    this.router.navigate(['/catalogue/category/search'], {
      queryParams: {
        type: 'groupe',
        id: groupeid,
        idFamille: familleid,
        idClass: classeid,
      },
    });
  }
}
