import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {ProduitService} from '../../../services/produit.service';
import {Produit} from '../../../models/produit';
import {PricesService} from '../../../services/prices.service';
import {GeneralEnum} from '../../../enums/general.enum';
import {LangueEnum} from '../../../enums/langue.enum';
import {BasketService} from '../../../services/basket.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-product-dialog',
  templateUrl: './product-dialog.component.html',
  styleUrls: ['./product-dialog.component.sass']
})
export class ProductDialogComponent implements OnInit {

  public counter: number = 1;
  public variantImage: any = '';
  public selectedColor: any = '';
  public selectedSize: any = '';
  public price: number;
  public devisePrice: string;
  public unitePrix: number;
  public conditionnement?: number;

  constructor(private router: Router,
              public produitService: ProduitService,
              private pricesService: PricesService,
              private basketService: BasketService,
              private translate: TranslateService,
              public dialogRef: MatDialogRef<ProductDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public produit: Produit) { }

  ngOnInit() {
    const idcustomer = JSON.parse(localStorage.getItem(GeneralEnum.STORAGE_USER)).customer_selected;

    this.pricesService.list(this.produit.id, idcustomer).toPromise().then(prices => {
      this.price = (prices != null && prices.results.length > 0) ? prices.results[0].price : 0;
      this.devisePrice = (prices != null && prices.results.length > 0) ? prices.results[0].devise : null;
      this.unitePrix = (prices != null && prices.results.length > 0) ? prices.results[0].unite_affi : null;
      this.conditionnement = (prices != null && prices.results.length > 0 && prices.results[0].unite_affi === 2) ? prices.results[0].cdt : null;
    });
  }

  public getLibelleProduit() {
    if (this.produit != null && this.produit.ref != null) {
      return this.produit.ref;
    }

    const langue = localStorage.getItem(GeneralEnum.STORAGE_LANGUAGE);
    let libelle = '';

    if (this.produit.designations != null && this.produit.designations.length > 0) {
      this.produit.designations.forEach(designation => {
        if (designation.langue === langue) {
          libelle =  designation.libellecourt;
        }
      });

      if (libelle === '') {
        this.produit.designations.forEach(designation => {
          if (designation.langue === LangueEnum.ANGLAIS) {
            libelle =  designation.libellecourt;
          }
        });
      }

      if (libelle === '') {
        libelle = this.produit.designations[0].libellecourt;
      }
    }

    return libelle;
  }

  public getLibellePrix() {
    let libelle = this.translate.instant('product-dialog.consult');

    if (this.price != null && this.price > 0) {
      const prix = this.unitePrix === 2 ? (this.price * 100) : this.price;
      libelle = prix.toFixed(2);

      if (this.devisePrice) {
        libelle += ' ' + this.devisePrice;
      }

      if (this.unitePrix) {
        libelle += ' ' + (this.unitePrix === 2 ? '/ ' + this.translate.instant('product.hundred') : '/ ' + this.translate.instant('product.unit'));
      }
    }

    return libelle;
  }

  public getStockLocal() {
    let libelle = this.translate.instant('product-dialog.onOrder');

    if (this.produit != null && this.produit.stocks != null && this.produit.stocks.length > 0) {
      this.produit.stocks.forEach(stock => {
        if (stock.quantite > 0) {
          libelle = this.translate.instant('product-dialog.inStock');
        }
      });
    }

    return libelle;
  }

  public getStockGroupe() {
    let libelle = this.translate.instant('product-dialog.onOrder');

    if (this.produit != null && this.produit.stock_group != null && this.produit.stock_group.length > 0) {
      this.produit.stock_group.forEach(stock => {
        if (stock.quantite__sum > 0) {
          libelle = this.translate.instant('product-dialog.inStock');
        }
      });
    }

    return libelle;
  }

  public addToCart(quantity) {
    this.basketService.addProductToSelection(this.produit, quantity, this.price === 0 ? null : this.price, this.devisePrice, this.unitePrix, this.conditionnement);
    this.dialogRef.close();
    this.router.navigate(['/pages/selection']);
  }

  public close(): void {
    this.dialogRef.close();
  }

  public incrementer() {
    this.counter += 1;
  }

  public decrementer() {
    if (this.counter > 1) {
       this.counter -= 1;
    }
  }

}
