<div class="product-dialog">

  <div class="close-btn-outer">
    <button mat-mini-fab color="warn" (click)="close()"><mat-icon>close</mat-icon></button>
  </div>
  <div mat-dialog-content>
    <div fxLayout="row wrap" >
      <div fxFlex="100" fxFlex.gt-sm="100" ngClass.gt-sm="px-2 m-0" ngClass.sm="mt-2" ngClass.xs="mt-2">
        <div class="product-right">
          <div class="" fxLayout="row" fxLayoutAlign="space-between center" >
            <div class="mt-15">
              <form [formGroup]="commandeForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                  <mat-form-field class="" style="width: 300px;">
                    <input matInput placeholder="{{ 'devis-dialog.orderNumber'|translate }}" class="form-control" formControlName="numeroCommande">
                    <mat-error *ngIf="submitted && f.numeroCommande.errors">{{ diplayError(f.numeroCommande) }}</mat-error>
                  </mat-form-field>
                </div>
                <div class="form-group">
                  <mat-form-field class="" style="width: 300px;">
                    <input matInput placeholder="{{ 'devis-dialog.businessNumber'|translate }}" class="form-control" formControlName="numeroAffaire">
                  </mat-form-field>
                </div>
                <div class="form-group">
                  <mat-form-field class="" style="width: 300px;">
                    <textarea matInput rows="4" placeholder="{{ 'devis-dialog.comment'|translate }}" class="form-control" formControlName="commentaire"></textarea>
                  </mat-form-field>
                </div>
                <div class="form-group">
                  <mat-radio-group formControlName="livraison">
                    <mat-radio-button class="mat-radio-button" value="LIV">{{ 'devis-dialog.shipping'|translate }}</mat-radio-button>
                    <mat-radio-button class="mat-radio-button" value="ENL">{{ 'devis-dialog.pickup'|translate }}</mat-radio-button>
                  </mat-radio-group>
                </div>
                <div class="form-group text-center">
                  <button [disabled]="loading" mat-raised-button class="btn-project" color="primary">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    {{ 'devis-dialog.send'|translate }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
