import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router, UrlSerializer} from '@angular/router';
import {AuthenticationService} from '../../../services/authentication.service';
import {ChangePassword, SetPassword} from '../../../models/password';
import {Location} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-expired-password',
  templateUrl: './expiredPassword.component.html',
  styleUrls: ['./expiredPassword.component.sass']
})
export class ExpiredPasswordComponent implements OnInit {

  initForm: FormGroup;
  loading = false;
  submitted = false;
  error = '';

  token: string = null;
  uid: string = null;

  constructor(private formBuilder: FormBuilder,
              private authent: AuthenticationService,
              private translate: TranslateService,
              private route: ActivatedRoute,
              private urlSerializer: UrlSerializer,
              private location: Location,
              private router: Router) {
    this.route.queryParams.subscribe(params => {
      /*
      this.token = params.token;
      this.uid = params.uid;

      if (this.token == null || this.uid == null) {
        this.error = 'Identification non valide !';
      }*/
    });
  }

  ngOnInit() {
    this.initForm = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      newPassword1: ['', Validators.required],
      newPassword2: ['', Validators.required]
    });
  }

  get f() { return this.initForm.controls; }

  async onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.initForm.invalid) {
      return;
    }

    if (this.f.newPassword1.value !== this.f.newPassword2.value) {
      this.error = this.translate.instant('expiredPassword.pwdError');
      return;
    }

    this.loading = true;

    const pwd: ChangePassword = {
      old_password: this.f.oldPassword.value,
      new_password: this.f.newPassword1.value,
      new_password2: this.f.newPassword2.value
    };

    this.authent.changePassword(pwd).subscribe({
      next: value => {
        this.loading = false;
        this.submitted = false;

        if (value != null && value.code != null && value.code === 400) {
          this.error = value.message[0];
        } else {
          this.router.navigate(['/login']);
        }
      },
      error: err => {
        this.error = err;
        this.loading = false;
        this.submitted = false;
      }
    });
  }
}
