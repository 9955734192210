import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { queryPaginated } from '../cors/handlers';
import { Page } from '../models/page';
import {ApiEnum} from '../enums/api.enum';
import {Price} from '../models/prices';

@Injectable({
  providedIn: 'root'
})
export class PricesService {
  constructor(private httpClient: HttpClient) { }

  list(idprod: number, idclient: number): Observable<Page<Price>> {
    const params = { customer__id: idclient, idproduit: idprod };
    return queryPaginated<Price>(this.httpClient, ApiEnum.PRICE_LIST, params);
  }

  get(id: number): Observable<Price> {
    return this.httpClient.get<Price>(ApiEnum.PRICE_DETAIL + id);
  }
}
