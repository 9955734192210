<div class="my-account sec-padding">
  <div class="login-header">
    <img src="assets/images/etn-logo-groupe.png">
  </div>
  <div class="login-container">
    <mat-card>
      <div class="my-account-wrap p-0">
        <div class="login-wrapper">
          <div class="title">
            <h3 class="text-center">{{ 'confirmRequest.title'|translate }}</h3>
          </div>
          <form class="login-form">
            {{ 'confirmRequest.message'|translate }}<br>
          </form>
          <div class="form-group text-center" style="margin-top: 25px;">
            <button mat-raised-button class="btn-project" color="primary" (click)="backToHome()">
              {{ 'confirmRequest.back'|translate }}
            </button>
          </div>
        </div>
      </div>
    </mat-card>

  </div>
</div>

