import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router, UrlSerializer} from '@angular/router';
import {AuthenticationService} from '../../../services/authentication.service';
import {SetPassword} from '../../../models/password';
import {Location} from '@angular/common';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-init-password',
  templateUrl: './initPassword.component.html',
  styleUrls: ['./initPassword.component.sass']
})
export class InitPasswordComponent implements OnInit {

  initForm: FormGroup;
  loading = false;
  submitted = false;
  error = '';

  token: string = null;
  uid: string = null;

  constructor(private formBuilder: FormBuilder,
              private authent: AuthenticationService,
              private translate: TranslateService,
              private route: ActivatedRoute,
              private urlSerializer: UrlSerializer,
              private location: Location,
              private router: Router) {
    this.route.queryParams.subscribe(params => {
      this.token = params.token;
      this.uid = params.uid;

      if (this.token == null || this.uid == null) {
        this.error = this.translate.instant('initPassword.invalidId');
      }
    });
  }

  ngOnInit() {
    this.initForm = this.formBuilder.group({
      password1: ['', Validators.required],
      password2: ['', Validators.required]
    });
  }

  get f() { return this.initForm.controls; }

  async onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.initForm.invalid) {
      return;
    }

    if (this.f.password1.value !== this.f.password2.value) {
      this.error = this.translate.instant('initPassword.pwdError');
      return;
    }

    const tree = this.router.createUrlTree(['/login']);
    const returnUrl = this.urlSerializer.serialize(tree);

    this.loading = true;

    const password: SetPassword = {
      password: this.f.password1.value,
      return_url: window.location.origin + this.location.prepareExternalUrl(returnUrl),
      token: this.token,
      uid: this.uid
    };

    this.authent.setPasswordAfterReset(password).subscribe({
      next: value => {
        this.loading = false;
        this.router.navigate(['/login']);
      },
      error: err => {
        this.error = err;
        this.loading = false;
        this.submitted = false;
      }
    });
  }
}
