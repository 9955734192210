import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import {handleError, queryPaginated} from '../cors/handlers';
import { Page } from '../models/page';
import { EtimFeatureMap } from '../models/etimfeature';
import {ApiEnum} from '../enums/api.enum';
import {catchError} from 'rxjs/operators';
import {Featurevalue} from '../models/featurevalue';

@Injectable({
  providedIn: 'root'
})
export class EtimFeatureService {

  constructor(private httpClient: HttpClient) { }

  list(urlOrFilter?: string | object): Observable<Page<EtimFeatureMap>> {
    return queryPaginated<EtimFeatureMap>(this.httpClient, ApiEnum.ETIM_FEATURE_LIST, urlOrFilter);
  }

  detailFromIdAndClass(id, etimclass): Observable<Page<EtimFeatureMap>> {
    const params = {
      feature: id,
      artclass: etimclass
    };

    return queryPaginated<EtimFeatureMap>(this.httpClient, ApiEnum.ETIM_FEATURE_DETAIL, params);
  }

  detailFromUrl(url): Observable<EtimFeatureMap> {
    return this.httpClient.get<EtimFeatureMap>(url)
      .pipe(
        catchError(handleError)
      );
  }

  values(id): Observable<Featurevalue> {
    return this.httpClient.get<Featurevalue>(ApiEnum.ETIM_VALUES_DETAIL + id)
      .pipe(
        catchError(handleError)
      );
  }
}
