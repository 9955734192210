import {Component, Inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Produit} from '../../../models/produit';

@Component({
  selector: 'app-confirm-panier-dialog',
  templateUrl: './confirm-panier-dialog.component.html',
  styleUrls: ['./confirm-panier-dialog.component.sass']
})
export class ConfirmPanierDialogComponent implements OnInit {

  constructor(private router: Router,
              public dialogRef: MatDialogRef<ConfirmPanierDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public produit: Produit) { }

  ngOnInit() { }

  public close(): void {
    this.dialogRef.close();
  }

  public goToPanier() {
    this.close();
    this.router.navigate(['/pages/selection']);
  }
}
