import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Selection} from '../../../../pojo/selection';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Item} from '../../../../models/item';
import {Order} from '../../../../models/order';
import {OrderService} from '../../../../services/order.service';
import {BasketService} from '../../../../services/basket.service';
import {formatDate} from '@angular/common';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-devis-dialog',
  templateUrl: './devis-dialog.component.html',
  styleUrls: ['./devis-dialog.component.sass']
})
export class DevisDialogComponent implements OnInit {

  commandeForm: FormGroup;
  submitted = false;
  loading = false;
  error = '';

  constructor(private router: Router,
              private formBuilder: FormBuilder,
              private orderService: OrderService,
              private basketService: BasketService,
              private translate: TranslateService,
              public dialogRef: MatDialogRef<DevisDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public selections: Array<Selection>,
              @Inject(LOCALE_ID) private locale: string) { }

  ngOnInit() {
    this.commandeForm = this.formBuilder.group({
      numeroAffaire: [''],
      numeroCommande: [''],
      commentaire: [''],
      livraison: ['LIV']
    });
  }

  get f() { return this.commandeForm.controls; }

  public close(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    this.submitted = true;
    this.loading = true;
    let uniqueName = true;

    this.orderService.list().toPromise().then(result => {
      if (result != null && result.results != null && result.count > 0) {
        result.results.forEach(c => {
          if (c.num_commande === this.f.numeroCommande.value) {
            this.f.numeroCommande.setErrors({incorrect: true});
            uniqueName = false;
          }
        });
      }
    });

    if (uniqueName) {
      const itemSelection: Array<Item> = new Array<Item>();

      this.selections.forEach(s => {
        const it: Item = {
          idproduit: s.produit.id,
          quantite: s.quantite,
          price: s.price,
          delai_souhaite: s.delaiSouhaite != null ? formatDate(s.delaiSouhaite, 'yyyy-MM-dd', this.locale) : null
        };
        itemSelection.push(it);
      });

      const commande: Order = {
        comment: this.f.commentaire.value,
        type_order: {name: 'Commande'},
        num_affaire: this.f.numeroAffaire.value,
        num_commande: this.f.numeroCommande.value,
        items: itemSelection,
        order_delivery: this.f.livraison.value
      };

      this.orderService.add(commande).subscribe({
        next: async (result) => {
          for await (const sel of this.selections) {
            await this.basketService.removeProductFromSelection(sel.produit);
          }
          /*
          this.selections.forEach(selected => {
            this.basketService.removeProductFromSelection(selected.produit);
          });*/

          await this.dialogRef.close();
          await this.router.navigate(['/pages/ddp-list']);
        },
        error: err => console.log(err)
      });
    }

    this.loading = false;
  }

  diplayError(input) {
    if (input.errors.required) {
      return this.translate.instant('devis-dialog.fieldMandatory');
    } else if (input.errors.incorrect) {
      return this.translate.instant('devis-dialog.usedNumber');
    }
  }
}
