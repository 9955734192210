import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';

import { environment } from '../../environments/environment';
import {handleError, queryPaginated} from '../cors/handlers';
import { Page } from '../models/page';
import { EtimClass } from '../models/etimclass';
import {ApiEnum} from '../enums/api.enum';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EtimClassService {

  constructor(private httpClient: HttpClient) { }

  list(urlOrFilter?: string | object): Observable<Page<EtimClass>> {
    return queryPaginated<EtimClass>(this.httpClient, ApiEnum.ETIM_CLASS_LIST, urlOrFilter);
  }

  detail(id): Observable<EtimClass> {
    return this.httpClient.get<EtimClass>(ApiEnum.ETIM_CLASS_DETAIL + id)
      .pipe(
        catchError(handleError)
      );
  }
}
