import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import { Page } from '../models/page';
import { Basket } from '../models/basket';
import { queryPaginated } from '../cors/handlers';
import {ApiEnum} from '../enums/api.enum';
import {Produit} from '../models/produit';
import {GeneralEnum} from '../enums/general.enum';
import {Selection} from '../pojo/selection';
import {Item} from '../models/item';
import {ProduitService} from './produit.service';
import {PricesService} from './prices.service';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BasketService {
  private updateSelection = new Subject<any>();

  constructor(private httpClient: HttpClient,
              private produitService: ProduitService,
              private pricesService: PricesService) { }

  list(urlOrFilter?: string | object): Observable<Page<Basket>> {
    return queryPaginated<Basket>(this.httpClient, ApiEnum.BASKET_LIST, urlOrFilter);
  }

  listTemporaries(): Observable<Page<Basket>> {
    return this.list({is_saved: false});
  }

  get(id: number): Observable<Basket> {
    return this.httpClient.get<Basket>(ApiEnum.BASKET_DETAIL + id + '/');
  }

  add(order: Basket): Observable<Basket> {
    return this.httpClient.post<Basket>(ApiEnum.BASKET_CREATE, order);
  }

  update(order: Basket): Observable<Basket> {
    return this.httpClient.put<Basket>(ApiEnum.BASKET_UPDATE + order.id + '/', order);
  }

  delete(id: number): Observable<Basket> {
    return this.httpClient.delete<Basket>(ApiEnum.BASKET_DELETE + id + '/');
  }

  async reloadBasketInContext(idcustomer: number) {
    this.listTemporaries().toPromise().then(listTemp => {
      if (listTemp.count === 0) {
        const selection: Array<Selection> = new Array<Selection>();
        localStorage.setItem(GeneralEnum.STORAGE_SELECTION, JSON.stringify(selection));
        this.sendUpdatePanier();
        return;
      } else {
        const selection: Array<Selection> = new Array<Selection>();
        const panier: Basket = listTemp.results[0];

        panier.items.forEach(it => {
          this.produitService.get(+it.idproduit).toPromise().then(prod => {
            this.pricesService.list(prod.id, idcustomer).toPromise().then(prices => {
              const prixProduit = (prices != null && prices.results.length > 0) ? prices.results[0].price : null;
              const prixDevise = (prices != null && prices.results.length > 0) ? prices.results[0].devise : null;
              const prixUnite = (prices != null && prices.results.length > 0) ? prices.results[0].unite_affi : null;
              const conditionnement = (prices != null && prices.results.length > 0 && prices.results[0].unite_affi === 2) ? prices.results[0].cdt : null;

              const newSelection = new Selection();
              newSelection.produit = prod;
              newSelection.quantite = it.quantite;
              newSelection.price = prixProduit == null ? 0 : prixProduit;
              newSelection.isPriceDefined = (prixProduit != null && prixProduit > 0);
              newSelection.devise = prixDevise;
              newSelection.unitePrix = prixUnite;
              newSelection.conditionnement = conditionnement;
              selection.push(newSelection);

              localStorage.setItem(GeneralEnum.STORAGE_SELECTION, JSON.stringify(selection));
              this.sendUpdatePanier();
            });
          });
        });
      }
    });
  }

  addProductToSelection(produit: Produit, quantite: number, prix?: number, devise?: string, unitePrix?: number, conditionnement?: number) {
    let selection: Array<Selection> = new Array<Selection>();
    const newSelection = new Selection();
    newSelection.produit = produit;
    newSelection.quantite = quantite;
    newSelection.price = prix == null ? 0 : prix;
    newSelection.isPriceDefined = (prix != null && prix > 0);
    newSelection.devise = devise;
    newSelection.unitePrix = unitePrix;
    newSelection.conditionnement = conditionnement;

    if (localStorage.getItem(GeneralEnum.STORAGE_SELECTION)) {
      selection = JSON.parse(localStorage.getItem(GeneralEnum.STORAGE_SELECTION));
    }

    let exist = false;
    if (selection.length) {
      selection.forEach(sel => {
        if (sel.produit.id === produit.id) {
          sel.quantite = +sel.quantite + +quantite;
          exist = true;
        }
      });
    }

    if (!exist) {
      selection.push(newSelection);
    }

    this.saveProductToTemporaryBasket(produit, quantite, prix);

    localStorage.setItem(GeneralEnum.STORAGE_SELECTION, JSON.stringify(selection));
    this.sendUpdatePanier();
  }

  private saveProductToTemporaryBasket(produit: Produit, quantity: number, prix?: number) {
    const it: Item = {
      idproduit: produit.id,
      quantite: quantity,
      price: prix
    };

    this.listTemporaries().subscribe({
      next: listTemp => {
        if (listTemp.count === 0) {
          const panier: Basket = {
            name: 'TEMPORARY',
            is_saved: false,
            items: [it]
          };

          this.add(panier).subscribe({next: value => console.log('CREATE TEMPORARY OK !!'), error: err => console.log(err)});
        } else {
          const panier: Basket = listTemp.results[0];
          panier.items.push(it);

          this.update(panier).subscribe({next: value => console.log('SAVE TEMPORARY OK !!'), error: err => console.log(err)});
        }
      }
    });
  }

  removeProductFromSelection(produit: Produit): Promise<any> {
    return new Promise((resolve, reject) => {
      let selection: Array<Selection> = JSON.parse(localStorage.getItem(GeneralEnum.STORAGE_SELECTION));

      this.listTemporaries().subscribe({
        next: listTemp => {
          if (listTemp.count > 0) {
            const panier: Basket = listTemp.results[0];
            panier.items = panier.items.filter(item => item.idproduit !== produit.id);

            if (panier.items.length > 0) {
              this.update(panier).subscribe({
                next: value => {
                  console.log('DELETE IN TEMPORARY OK !!');
                  selection = selection.filter(item => item.produit.id !== produit.id);
                  localStorage.setItem(GeneralEnum.STORAGE_SELECTION, JSON.stringify(selection));
                  this.sendUpdatePanier();
                  resolve(selection);
                },
                error: err => {
                  console.log(err);
                  reject(err);
                }
              });
            } else {
              this.delete(panier.id).subscribe({
                next: value => {
                  console.log('DELETE TEMPORARY OK !!');
                  selection = selection.filter(item => item.produit.id !== produit.id);
                  localStorage.setItem(GeneralEnum.STORAGE_SELECTION, JSON.stringify(selection));
                  this.sendUpdatePanier();
                  resolve(selection);
                },
                error: err => {
                  console.log(err);
                  reject(err);
                }
              });
            }
          } else {
            selection = selection.filter(item => item.produit.id !== produit.id);
            localStorage.setItem(GeneralEnum.STORAGE_SELECTION, JSON.stringify(selection));
            resolve(selection);
          }
        },
        error: err => {
          console.log(err);
          reject(err);
        }
      });
    });
  }

  removeProductFromBasketWithId(produit: Produit, idBasket: number) {
    this.get(idBasket).subscribe({
      next: basket => {
        basket.items = basket.items.filter(it => it.idproduit !== produit.id);
        console.log(basket);

        if (basket.items.length > 0) {
          this.update(basket).subscribe({next: value => console.log('UPDATE BASKET OK !!'), error: err => console.log(err)});
        } else {
          this.delete(basket.id).subscribe({
            next: value => {
              console.log('DELETE BASKET OK !!');
            },
            error: err => {
              console.log(err);
            }
          });
        }
      }
    });
  }

  removeAllFromSelection(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.listTemporaries().subscribe({
        next: listTemp => {
          if (listTemp.count > 0) {
            listTemp.results.forEach(panier => {
              this.delete(panier.id).subscribe({
                next: value => {
                  console.log('DELETE TEMPORARY OK !!');
                  localStorage.removeItem(GeneralEnum.STORAGE_SELECTION);
                  this.sendUpdatePanier();
                  resolve(true);
                },
                error: err => {
                  console.log(err);
                  reject(err);
                }
              });
            });
          } else {
            localStorage.removeItem(GeneralEnum.STORAGE_SELECTION);
            this.sendUpdatePanier();
            resolve(true);
          }
        }
      });
    });
  }

  changeQuantityFromSelection(produit: Produit, quantity: number) {
    const selection: Array<Selection> = JSON.parse(localStorage.getItem(GeneralEnum.STORAGE_SELECTION));

    selection.forEach(sel => {
      if (sel.produit.id === produit.id) {
        sel.quantite = quantity;
      }
    });

    this.modifyQuantityFromTemporaryBasket(produit, quantity);

    localStorage.setItem(GeneralEnum.STORAGE_SELECTION, JSON.stringify(selection));
    this.sendUpdatePanier();
  }

  private modifyQuantityFromTemporaryBasket(produit: Produit, quantity: number) {
    this.listTemporaries().subscribe({
      next: listTemp => {
        if (listTemp.count > 0) {
          const panier = listTemp.results[0];
          panier.items.forEach(it => {
            if (it.idproduit === produit.id) {
              it.quantite = quantity;
            }
          });
          this.update(panier).subscribe({next: value => console.log('UPDATE QUANTITY IN TEMPORARY OK !!'), error: err => console.log(err)});
        }
      }
    });
  }

  modifyQuantityFromBasketWithId(produit: Produit, quantity: number, idBasket: number) {
    this.get(idBasket).subscribe({
      next: basket => {
        basket.items.forEach(it => {
          if (it.idproduit === produit.id) {
            it.quantite = quantity;
          }
        });
        this.update(basket).subscribe({next: value => console.log('UPDATE BASKET IN TEMPORARY OK !!'), error: err => console.log(err)});
      }
    });
  }

  public sendUpdatePanier() {
    this.updateSelection.next();
  }

  public getUpdatePanier() {
    return this.updateSelection.asObservable();
  }
}
