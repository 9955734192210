import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router, UrlSerializer} from '@angular/router';
import {AuthenticationService} from '../../../services/authentication.service';
import {ResetPassword} from '../../../models/password';
import {Location} from '@angular/common';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgotPassword.component.html',
  styleUrls: ['./forgotPassword.component.sass']
})
export class ForgotPasswordComponent implements OnInit {

  forgotForm: FormGroup;
  loading = false;
  submitted = false;
  error = '';

  constructor(private formBuilder: FormBuilder,
              private authent: AuthenticationService,
              private urlSerializer: UrlSerializer,
              private location: Location,
              private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit() {
    this.forgotForm = this.formBuilder.group({
      username: ['', Validators.required]
    });
  }

  get f() { return this.forgotForm.controls; }

  async onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.forgotForm.invalid) {
      return;
    }

    this.loading = true;

    const tree = this.router.createUrlTree(['/init-password']);
    const returnUrl = this.urlSerializer.serialize(tree);

    const password: ResetPassword = {
      username: this.f.username.value,
      return_url: window.location.origin + this.location.prepareExternalUrl(returnUrl)
    };

    this.authent.resetPassword(password).subscribe({
      next: value => {
        this.loading = false;
        this.router.navigate(['/confirm-request']);
      },
      error: err => {
        this.error = err;
        this.loading = false;
        this.submitted = false;
      }
    });
  }
}
