




<div class="home-header blue">
  <mat-toolbar color="primary" class="kuro" >
    <mat-toolbar-row class="container" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="20px" fxlayoutalign="space-between center">
    <div class="logo">
        <a [routerLink]="['']" routerLinkActive="router-link-active" ><img src="assets/images/etn-logo-groupe.png"></a>
        <a *ngIf="userLogo != null" [routerLink]="['']" routerLinkActive="router-link-active logo-client" ><img src="{{userLogo}}" height="45px"></a>
    </div>
    <div fxflexoffset.gt-sm="10"  fxFlex class="search-form">
        <form class="search-form" fxLayout="row" (ngSubmit)="onSubmit()">
          <div class="form-wrap">
             <input type="text" placeholder="{{ 'searchBar.placeholder'|translate }}" name="autocomplete-search" id="autocomplete-search-etim"
                    [(ngModel)]="asyncSelected"
                    (keyup.enter)="onSearchDirectly(searchSubmit)"
                    [typeahead]="dataSource"
                    [typeaheadIsFirstItemActive]="false"
                    (typeaheadLoading)="changeTypeaheadLoading($event)"
                    (typeaheadNoResults)="changeTypeaheadNoResults($event)"
                    (typeaheadOnSelect)="typeaheadOnSelect($event)"
                    typeaheadOptionsLimit="20"
                    typeaheadOptionField="autocomplete">
             <button class="btn-search" #searchSubmit type="submit">{{ 'searchBar.search'|translate }}</button>
          </div>
        </form>
       </div>
    <div class="call-bucket-wrap" fxLayout="row" fxLayout.sm="row" fxLayoutAlign="center center" >
      <div *ngIf="telephoneSupport != null" class="info-message-icon"  fxLayout="row" fxLayoutAlign="center center">
        <div class="icon"  fxLayout="row" >
          <mat-icon class="mat-icon-lg mat-icon material-icons">call</mat-icon>
        </div>
        <div class="info-text">
          <p>{{ 'searchBar.helpdesk'|translate }}:</p>
          <span>{{telephoneSupport}}</span>
        </div>
      </div>
        <div class="main-menu-wrapper-2" fxLayout="row" fxLayoutAlign="space-between center" >
            <mat-toolbar class="top-navbar top menu">
              <app-shopping-widgets></app-shopping-widgets>
            </mat-toolbar>
          </div>
      </div>
</mat-toolbar-row>
</mat-toolbar>

</div>
<div class="menu-container1">
    <app-menu></app-menu>
</div>

