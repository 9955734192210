<div class="my-account sec-padding">
  <div class="login-header">
    <img src="assets/images/etn-logo-groupe.png">
  </div>
  <div class="login-container">
    <mat-card>
      <div class="my-account-wrap p-0">
        <div class="login-wrapper">
          <div class="title">
            <h3 class="text-center">{{ 'forgotPassword.title'|translate }}</h3>
          </div>
          <form class="login-form" [formGroup]="forgotForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
              <mat-form-field class="w-200">
                <input matInput placeholder="{{ 'forgotPassword.id'|translate }}" class="form-control" formControlName="username" [ngClass]="{ 'is-invalid': submitted && f.username.errors }">
                <mat-error *ngIf="submitted && f.username.errors">{{ 'forgotPassword.errorId'|translate }}</mat-error>
              </mat-form-field>
            </div>
            <div class="form-group text-center">
              <button [disabled]="loading" mat-raised-button class="btn-project" color="primary">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                {{ 'forgotPassword.send'|translate }}
              </button>
            </div>
            <mat-error *ngIf="error">{{error}}</mat-error>
          </form>
        </div>
      </div>
    </mat-card>

  </div>
</div>

