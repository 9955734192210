import {Component, OnInit, OnDestroy} from '@angular/core';
import {Selection} from '../../../pojo/selection';
import {BasketService} from '../../../services/basket.service';
import {GeneralEnum} from '../../../enums/general.enum';
import {Produit} from '../../../models/produit';
import {LangueEnum} from '../../../enums/langue.enum';
import {NavigationEnd, Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-shopping-widgets',
  templateUrl: './shopping-widgets.component.html',
  styleUrls: ['./shopping-widgets.component.sass']
})
export class ShoppingWidgetsComponent implements OnInit, OnDestroy {

  produits: Array<Selection> = new Array<Selection>();
  indexProduct: number;

  navigationSubscription;
  updatePanierSubscription: Subscription;
  devise: string = '';

  public sidenavMenuItems: Array<any>;

  constructor(private basketService: BasketService, private router: Router) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.ngOnInit();
      }
    });

    this.updatePanierSubscription = this.basketService.getUpdatePanier().subscribe(
      value => {
        this.ngOnInit();
      }
    );
  }

  ngOnInit() {
    if (localStorage.getItem(GeneralEnum.STORAGE_SELECTION)) {
      this.produits = JSON.parse(localStorage.getItem(GeneralEnum.STORAGE_SELECTION));

      if (this.produits != null && this.produits.length > 0) {
        this.devise = this.produits[0].devise;
      }
    } else {
      this.produits = new Array<Selection>();
    }
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  public getLibelleProduit(produit: Produit) {
    if (produit != null && produit.ref != null) {
      return produit.ref;
    }

    if (produit != null && produit.designations != null && produit.designations.length > 0) {
      produit.designations.forEach(designation => {
        if (designation.langue === LangueEnum.FRANCAIS) {
          return designation.libellecourt;
        }
      });

      return produit.designations[0].libellecourt;
    } else {
      return '';
    }
  }

  public getImagePrincipale(produit: Produit, miniature: boolean) {
    let image = 'assets/images/product/materiel_default.jpg';

    if (produit.documentations != null && produit.documentations.length > 0) {
      let imgList = produit.documentations.filter(doc => doc.typedoc.toUpperCase() === 'ISW');

      if (imgList != null && imgList.length > 0) {
        imgList = imgList.sort((d1, d2) => d2.priorite - d1.priorite);
        image = environment.url_images + (miniature ? 'W50/' : 'W400/') + imgList[0].url;
      }
    }

    return image;
  }

  public removeItem(item: Selection) {
    this.produits = this.produits.filter(p => p.produit.id !== item.produit.id);
    this.basketService.removeProductFromSelection(item.produit);
  }

  public getTotal() {
    let total = 0;

    this.produits.forEach(p => {
      if (p.price === 0) {
        total = null;
        return;
      }
      total += this.getArrondi(p.price * p.quantite);
    });

    return total;
  }

  public getArrondi(nb: number) {
    return Math.round(nb * 100) / 100;
  }
}
