import { Component, OnInit } from '@angular/core';
import {Agence} from '../../../models/agence';
import {GeneralEnum} from '../../../enums/general.enum';
import {Subscription} from 'rxjs';
import {AuthenticationService} from '../../../services/authentication.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass']
})
export class FooterComponent implements OnInit {

  public agence: Agence;
  updateAgenceSubscription: Subscription;

  constructor(private authenticationService: AuthenticationService) {
    this.updateAgenceSubscription = this.authenticationService.getUpdateAgence().subscribe(
      value => {
        this.ngOnInit();
      }
    );
  }

  ngOnInit() {
    this.agence = JSON.parse(localStorage.getItem(GeneralEnum.STORAGE_AGENCE));
  }
}
