import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'reference'
})
export class ReferencePipe implements PipeTransform {
  transform(value: string, splitIndex: number) {
    if (splitIndex === 0) {
      return value.split(' ')[splitIndex];
    } else {
      const toRemove = value.split(' ')[0];
      return value.slice(toRemove.length);
    }
  }
}
