<div class="product-dialog">

  <div class="close-btn-outer">
    <button mat-mini-fab color="warn" (click)="close()"><mat-icon>close</mat-icon></button>
  </div>
  <div mat-dialog-content>
    <div fxLayout="row wrap" >
        <div fxFlex="100" fxFlex.gt-sm="50">
            <div class="swiper-container h-100">
                <div class="swiper-wrapper">
                  <img src="assets/images/product/materiel_default.jpg" alt="" class="img-fluid">
                </div>

            </div>
        </div>
        <div fxFlex="100" fxFlex.gt-sm="50" ngClass.gt-sm="px-2 m-0" ngClass.sm="mt-2" ngClass.xs="mt-2">
          <div class="product-right">
              <div class="product-details">
                <h2 class="mb-10">{{produit.ref}}</h2>
                <h4 class="mb-10" >{{getLibellePrix()}}</h4>
                <div class="discription">
                  <p class="bold">{{ 'product-dialog.details'|translate }}</p>
                  <p class="text-muted productDescription">{{getLibelleProduit()}}</p>
                </div>
            </div>
            <div class="py-1 mt-15">
              <p>
                <span class="text-muted fw-500">{{ 'product-dialog.localAvail'|translate }} :</span>
                <span class="avalibility"><span>{{getStockLocal()}}</span></span>
              </p>
              <p>
                <span class="text-muted fw-500">{{ 'product-dialog.groupAvail'|translate }} :</span>
                <span class="avalibility"><span>{{getStockGroupe()}}</span></span>
              </p>
            </div>
            <div class="" fxLayout="row" fxLayoutAlign="space-between center" >
              <div class="quantity mt-15">
                <span class="text-muted fw-500">{{ 'product-dialog.quantity'|translate }}</span>
                <div fxLayout="row" fxLayout.xs="column"  class="text-muted mt-15">
                  <div fxLayout="row" fxLayoutAlign="center start">
                      <button mat-icon-button matTooltip="Remove" (click)="decrementer()"><mat-icon>remove</mat-icon></button>
                      <input type="text" name="quantity" value="{{counter}}" class="form-control input-number">
                      <button mat-icon-button matTooltip="Add" (click)="incrementer()"><mat-icon>add</mat-icon></button>
                  </div>
              </div>
            </div>
            <div class="buttons mt-40">
              <mat-icon mat-stroked-button color="primary" (click)="addToCart(counter)" class="mat-icon-lg mat-icon material-icons">shopping_cart</mat-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
