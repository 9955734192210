import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { queryPaginated } from '../cors/handlers';
import { Page } from '../models/page';
import {ApiEnum} from '../enums/api.enum';
import {Etimsearch} from '../models/etimsearch';
import {GeneralEnum} from '../enums/general.enum';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private httpClient: HttpClient) { }

  search(pattern: string): Observable<Page<any>> {
    const langue = localStorage.getItem(GeneralEnum.STORAGE_LANGUAGE);
    const PARAM = {
      autocomplete: pattern,
      lang: langue
    };
    return queryPaginated<Page<any>>(this.httpClient, ApiEnum.PRODUITS_AUTOCOMPLETE, PARAM);
  }

  searchEtim(searchText?: string): Observable<Page<Etimsearch>> {
    const lang = localStorage.getItem(GeneralEnum.STORAGE_LANGUAGE);
    let param;

    if (searchText != null) {
      param = {
        q: searchText,
        langue: lang
      };
    } else {
      param = { langue: lang };
    }

    return queryPaginated<Etimsearch>(this.httpClient, ApiEnum.SEARCH_ETIM, param);
  }
}
