export class CaracteristiqueValue {
  id: string;
  libelle: string;
  selected: boolean;
}

export class Caracteristique {
  id: string;
  name: string;
  type: string;
  valeurMin?: string;
  valeurMax?: string;
  valuesList?: Array<CaracteristiqueValue>;
  unite?: string;
  value?: any;
  selectedMinValue?: string;
  selectedMaxValue?: string;
  selectedValuesList?: Array<string>;
  autreSelected: boolean;
}
