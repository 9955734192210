import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { queryPaginated } from '../cors/handlers';
import { Page } from '../models/page';
import { Order } from '../models/order';
import {ApiEnum} from '../enums/api.enum';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  constructor(private httpClient: HttpClient) { }

  list(urlOrFilter?: string | object): Observable<Page<Order>> {
    return queryPaginated<Order>(this.httpClient, ApiEnum.ORDER_LIST, urlOrFilter);
  }

  listByCriteres(url?: string, urlOrFilter?: string | object): Observable<Page<Order>> {
    if (url == null) {
      url = ApiEnum.ORDER_LIST;
    }

    return queryPaginated<Order>(this.httpClient, url, urlOrFilter);
  }

  get(id: string): Observable<Order> {
    return this.httpClient.get<Order>(ApiEnum.ORDER_DETAIL + id + '/');
  }

  add(order: Order): Observable<Order> {
    return this.httpClient.post<Order>(ApiEnum.ORDER_CREATE, order);
  }

  update(order: Order): Observable<Order> {
    return this.httpClient.put<Order>(ApiEnum.ORDER_UPDATE + order.id + '/', order);
  }

  delete(id: string): Observable<Order> {
    return this.httpClient.delete<Order>(ApiEnum.ORDER_DELETE + id + '/');
  }
}
