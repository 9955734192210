import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../../services/authentication.service';
import {GeneralEnum} from '../../../enums/general.enum';
import {FamilleService} from '../../../services/famille.service';
import {ProduitService} from '../../../services/produit.service';
import {BasketService} from '../../../services/basket.service';
import {PunchoutInfos} from '../../../models/punchoutInfos';

@Component({
  selector: 'app-punch-out',
  templateUrl: './punchOut.component.html',
  styleUrls: ['./punchOut.component.sass']
})
export class PunchOutComponent implements OnInit {

  token: string = null;
  returnUrl: string = null;
  buyerCookie: string = null;

  error = '';

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private authent: AuthenticationService,
              private familleService: FamilleService,
              private produitService: ProduitService,
              private basketService: BasketService) {
      this.activatedRoute.queryParams.subscribe(params => {
        this.token = params['token'];
        this.returnUrl = params['return_url'];
        this.buyerCookie = params['buyer_cookie'];
      });
  }

  async ngOnInit(): Promise<void> {
    localStorage.clear();

    const punchoutInfos = new PunchoutInfos();
    punchoutInfos.token = this.token;
    punchoutInfos.returnUrl = this.returnUrl;
    punchoutInfos.buyerCookie = this.buyerCookie;
    localStorage.setItem(GeneralEnum.STORAGE_PUNCHOUT, JSON.stringify(punchoutInfos));

    if (this.token != null && this.returnUrl != null && this.buyerCookie != null) {
      await this.authent.punchoutLogin(this.token)
        .then(async (response) => {
          const user = JSON.parse(localStorage.getItem(GeneralEnum.STORAGE_USER));

          if (user != null) {
            this.familleService.list().subscribe({
              next: result => {
                localStorage.setItem(GeneralEnum.STORAGE_FAMILLES, JSON.stringify(this.familleService.trierFamilles(result.results)));

                this.produitService.filtersClassCount().subscribe({
                  next: resultFilter => {
                    localStorage.setItem(GeneralEnum.STORAGE_FILTERS_CLASS_COUNT, JSON.stringify(resultFilter));

                    // Récupération du panier temporaire
                    this.basketService.reloadBasketInContext(user.customer_selected);

                    this.router.navigate(['/']);
                  },
                  error: err => {
                    this.error = err;
                    // this.router.navigate(['/login']);
                  }
                });
              },
              error: err => {
                this.error = err;
                // this.router.navigate(['/login']);
              }
            });
          } else {
            await this.router.navigate(['/login']);
          }
      })
        .catch((err) => { this.error = err; console.log('Appel punchout KO ! : ' + this.error); });

    } else {
      await this.router.navigate(['/login']);
    }
  }
}
