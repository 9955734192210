<div class="product-dialog">
  <div mat-dialog-content>
    <div fxLayout="row wrap" >
      <div fxFlex="100" fxFlex.gt-sm="100" ngClass.gt-sm="px-2 m-0" ngClass.sm="mt-2" ngClass.xs="mt-2">
        <div class="product-right">
          <div class="" fxLayout="row" fxLayoutAlign="space-between center" >
            <div class="mt-15">
              <div class="title text-center mb-20">
                <h4>{{ 'signalerErreur.title'|translate }}</h4>
              </div>

              <form [formGroup]="erreurForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                  <mat-select formControlName="motif" class="" style="width: 400px;">
                    <mat-option value="CAT">{{ 'signalerErreur.option1'|translate }}</mat-option>
                    <mat-option value="DOC">{{ 'signalerErreur.option2'|translate }}</mat-option>
                    <mat-option value="IMG">{{ 'signalerErreur.option3'|translate }}</mat-option>
                    <mat-option value="OTHER">{{ 'signalerErreur.option4'|translate }}</mat-option>
                  </mat-select>
                </div>
                <div class="form-group">
                  <mat-form-field class="" style="width: 400px;">
                    <textarea matInput rows="7" placeholder="{{ 'signalerErreur.comment'|translate }}" class="form-control" formControlName="commentaire"></textarea>
                  </mat-form-field>
                </div>
                <div class="form-group text-center">
                  <button mat-raised-button class="btn-project" color="primary" (click)="close()" type="button">
                    {{ 'signalerErreur.cancel'|translate }}
                  </button>&nbsp;
                  <button class="btn-project" [disabled]="loading" mat-raised-button color="primary" type="submit">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    {{ 'signalerErreur.send'|translate }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
