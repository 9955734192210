<div class="product-dialog">

  <div class="close-btn-outer">
    <button mat-mini-fab color="warn" (click)="close()"><mat-icon>close</mat-icon></button>
  </div>
  <div mat-dialog-content>
    <div fxLayout="row wrap" >
      <div fxFlex="100" fxFlex.gt-sm="100" ngClass.gt-sm="px-2 m-0" ngClass.sm="mt-2" ngClass.xs="mt-2">
        <div class="product-right">
          <div class="" fxLayout="row" fxLayoutAlign="space-between center" >
            <div class="mt-15">
              <form [formGroup]="saveSelectionForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                  <mat-form-field class="" style="width: 300px;">
                    <input matInput placeholder="{{ 'save-dialog.name'|translate }}" class="form-control" formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                    <mat-error *ngIf="submitted && f.name.errors">{{ diplayError(f.name) }}</mat-error>
                  </mat-form-field>
                </div>
                <div class="form-group text-center">
                  <button [disabled]="loading" mat-raised-button class="btn-project" color="primary">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    {{ 'save-dialog.save'|translate }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
