import {AfterViewInit, Component, EventEmitter, Inject, LOCALE_ID, OnInit, Output, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ProductZoomComponent} from '../product-details/product-zoom/product-zoom.component';
import {ProduitService} from '../../../services/produit.service';
import {Documentation, Produit} from '../../../models/produit';
import {LangueEnum} from '../../../enums/langue.enum';
import {EtimClassService} from '../../../services/etimclass.service';
import {EtimClass} from '../../../models/etimclass';
import {PricesService} from '../../../services/prices.service';
import {Price} from '../../../models/prices';
import {GeneralEnum} from '../../../enums/general.enum';
import {EtimFeatureService} from '../../../services/etimfeature.service';
import {EtimFeature, EtimFeatureMap, EtimUnit} from '../../../models/etimfeature';
import {BasketService} from '../../../services/basket.service';
import {environment} from '../../../../environments/environment';
import {ConfirmPanierDialogComponent} from '../../shared/confirm-panier-dialog/confirm-panier-dialog.component';
import {Featurevalue} from '../../../models/featurevalue';
import {SwiperConfigInterface, SwiperDirective} from 'ngx-swiper-wrapper';
import {SignalerErreurComponent} from '../signalerErreur/signalerErreur.component';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-product-details-dialog',
  templateUrl: './product-details-dialog.component.html',
  styleUrls: ['./product-details-dialog.component.sass']
})
export class ProductDetailsDialogComponent implements OnInit, AfterViewInit {

  @Output() onOpenProductDialog: EventEmitter<any> = new EventEmitter();
  @Output() onOpenConfirmDialog: EventEmitter<any> = new EventEmitter();
  @Output() onOpenSignalerErreur: EventEmitter<any> = new EventEmitter();

  @ViewChild('zoomViewer', { static: true }) zoomViewer;
  @ViewChild(SwiperDirective, { static: true }) directiveRef: SwiperDirective;

  public config: SwiperConfigInterface = {};

  private idProduit: number;
  private idCustomer: number;
  private numeroAgence: number;

  public categorie: EtimClass;
  public prices: Array<Price>;
  public features: Array<EtimFeatureMap> = new Array<EtimFeatureMap>();
  public descriptif: Array<any> = new Array<{id: string, titre: string, value: string}>();
  public images: Array<{mini?: string, full?: string}> = new Array<{mini?: string, full?: string}>();

  public zoomImage: any;
  public imageIndex = 0;
  public error = '';
  public inputError = null;
  public messageSignalement = null;

  public counter: number = 1;

  index: number;

  constructor(private route: ActivatedRoute,
              public produitService: ProduitService,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<ProductDetailsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public produit: Produit,
              private router: Router,
              private etimclassService: EtimClassService,
              private etimfeatureService: EtimFeatureService,
              private pricesService: PricesService,
              private basketService: BasketService,
              private translate: TranslateService) {

    this.idProduit = produit.id;
    this.idCustomer = JSON.parse(localStorage.getItem(GeneralEnum.STORAGE_USER)).customer_selected;
    this.numeroAgence = JSON.parse(localStorage.getItem(GeneralEnum.STORAGE_AGENCE)).numero;
   }

  ngOnInit() {
    this.produitService.get(this.idProduit).subscribe({
      next: value => {
        this.produit = value;

        if (this.produit != null) {
          this.pricesService.list(value.id, this.idCustomer).subscribe({
            next: prix => {
              this.prices = prix.results;

              if (this.prices != null && this.prices.length > 0) {
                this.counter = this.prices[0].cdt === 0 || this.prices[0].cdt === 1 ? 1 : this.prices[0].cdt;
              }
            },
            error: err => this.error = err
          });

          this.construireListeImages();
        }

        if (value.etimclass != null) {
          this.etimclassService.detail(value.etimclass.artclassid).subscribe({
            next: cat => {
              this.categorie = cat;
            },
            error: err => this.error = err
          });

          if (this.produit != null && this.produit.valuesEtim != null && this.produit.valuesEtim.length > 0) {
            value.valuesEtim.forEach(v => {
              this.etimfeatureService.detailFromIdAndClass(v.feature_id, value.etimclass.artclassid).subscribe({
                next: feat => {
                  feat.results.forEach(r => {
                    r.value = v.value;
                    this.features.push(r);

                    if (v.value != null && v.value.startsWith('EV')) {
                      this.etimfeatureService.values(v.value).subscribe({
                        next: featvalue => {
                          r.value = this.getValueDescription(featvalue).charAt(0).toUpperCase() + this.getValueDescription(featvalue).slice(1);
                          this.construireDescriptif();
                        }
                      });
                    } else {
                      this.construireDescriptif();
                    }
                  });
                },
                error: err => this.error = err
              });
            });
          } else {
            this.construireDescriptif();
          }
        }
      },
      error: err => this.error = err
    });
  }

  ngAfterViewInit() {
    this.config = {
      observer: true,
      slidesPerView: 3,
      spaceBetween: 10,
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,
      loop: false,
      preloadImages: false,
      lazy: true,
      breakpoints: {
        480: {
          slidesPerView: 1
        },
        740: {
          slidesPerView: 2,
        },
        960: {
          slidesPerView: 3,
        },
        1280: {
          slidesPerView: 3,
        },
        1600: {
          slidesPerView: 4,
        },
      }
    };
  }

  public close(): void {
    this.dialogRef.close();
  }

  getCategorieDescription() {
    const langue = localStorage.getItem(GeneralEnum.STORAGE_LANGUAGE);
    let desc = this.categorie.arclassdesc.filter(d => d.langue === langue);

    if (desc == null || desc.length === 0) {
      desc = this.categorie.arclassdesc.filter(d => d.langue === 'en-GB');
    }
    if ((desc == null || desc.length === 0) && this.categorie.arclassdesc.length > 0) {
      desc = this.categorie.arclassdesc;
    }

    return desc[0].arclassdesc;
  }

  getFeatureDescription(feature: EtimFeature) {
    const langue = localStorage.getItem(GeneralEnum.STORAGE_LANGUAGE);
    let desc = feature.featuredesc.filter(d => d.langue === langue);

    if (desc == null || desc.length === 0) {
      desc = feature.featuredesc.filter(d => d.langue === 'en-GB');
    }
    if ((desc == null || desc.length === 0) && feature.featuredesc.length > 0) {
      desc = feature.featuredesc;
    }

    return desc[0].featuredesc;
  }

  getUnitDescription(unit: EtimUnit) {
    const langue = localStorage.getItem(GeneralEnum.STORAGE_LANGUAGE);
    let desc = unit.unitdesc.filter(d => d.langue === langue);

    if (desc == null || desc.length === 0) {
      desc = unit.unitdesc.filter(d => d.langue === 'en-GB');
    }
    if ((desc == null || desc.length === 0) && unit.unitdesc.length > 0) {
      desc = unit.unitdesc;
    }

    return desc[0].unitdesc;
  }

  getValueDescription(value: Featurevalue) {
    const langue = localStorage.getItem(GeneralEnum.STORAGE_LANGUAGE);
    let desc = value.valuedesc.filter(d => d.langue === langue);

    if (desc == null || desc.length === 0) {
      desc = value.valuedesc.filter(d => d.langue === 'en-GB');
    }
    if ((desc == null || desc.length === 0) && value.valuedesc.length > 0) {
      desc = value.valuedesc;
    }

    return desc[0].valuedesc;
  }

  private async construireDescriptif() {
    this.descriptif = new Array<{id: string, titre: string, value: string}>();

    this.features = this.features.sort((f1, f2) => f1.sortnr - f2.sortnr);

    this.features.forEach(feat => {
      if (feat.value != null) {
        const titreDesc = this.getFeatureDescription(feat.feature).charAt(0).toUpperCase() + this.getFeatureDescription(feat.feature).slice(1);
        let valueDesc = feat.value;

        // Rustine pour les valeurs true/false
        if (valueDesc != null && valueDesc === 'FALSE') {
          valueDesc = 'Non';
        }
        if (valueDesc != null && valueDesc === 'TRUE') {
          valueDesc = 'Oui';
        }

        if (feat.unitofmeas != null && feat.unitofmeas.unitdesc != null) {
          valueDesc += ' ' + this.getUnitDescription(feat.unitofmeas);
        }

        if (feat.featuretype === 'R' && this.descriptif.map(tab => tab.id).indexOf(feat.feature.featureid) > -1) {
          this.descriptif.forEach(tabdesc => {
            if (tabdesc.id === feat.feature.featureid) {
              tabdesc.value += ' - ' + valueDesc;
            }
          });
        } else {
          this.descriptif.push({
            id: feat.feature.featureid,
            titre: titreDesc,
            value: valueDesc
          });
        }
      }
    });
  }

  public openProductDialog(product, bigProductImageIndex) {
    const dialogRef = this.dialog.open(ProductZoomComponent, {
      data: {product, index: bigProductImageIndex },
      panelClass: 'product-dialog',
    });
  }

  public openConfirmDialog(produit: Produit) {
    const dialogRef = this.dialog.open(ConfirmPanierDialogComponent, {
      data: produit,
      panelClass: 'confirm-panier-dialog',
    });

    dialogRef.afterClosed().subscribe(p => {
      this.close();
    });
  }

  public openSignalerErreur(produit: Produit) {
    const dialogRef = this.dialog.open(SignalerErreurComponent, {
      data: produit,
      panelClass: 'signaler-erreur',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.messageSignalement = this.translate.instant('product-details-dialog.reportSent');
      }
    });
  }

  public getRefProduit() {
    if (this.produit != null && this.produit.ref != null) {
      return this.produit.ref;
    }

    const langue = localStorage.getItem(GeneralEnum.STORAGE_LANGUAGE);
    let libelle = '';

    if (this.produit.designations != null && this.produit.designations.length > 0) {
      this.produit.designations.forEach(designation => {
        if (designation.langue === langue) {
          libelle =  designation.libellecourt;
        }
      });

      if (libelle === '') {
        this.produit.designations.forEach(designation => {
          if (designation.langue === LangueEnum.ANGLAIS) {
            libelle =  designation.libellecourt;
          }
        });
      }

      if (libelle === '') {
        libelle = this.produit.designations[0].libellecourt;
      }
    }

    return libelle;
  }

  public getLibelleCourtProduit() {
    const langue = localStorage.getItem(GeneralEnum.STORAGE_LANGUAGE);
    let libelle = '';

    if (this.produit != null && this.produit.designations != null && this.produit.designations.length > 0) {
      this.produit.designations.forEach(designation => {
        if (designation.langue === langue) {
          libelle =  designation.libellecourt;
        }
      });

      if (libelle === '') {
        this.produit.designations.forEach(designation => {
          if (designation.langue === LangueEnum.ANGLAIS) {
            libelle =  designation.libellecourt;
          }
        });
      }

      if (libelle === '') {
        libelle = this.produit.designations[0].libellecourt;
      }
    }

    return libelle;
  }

  public getLibelleLongProduit() {
    const langue = localStorage.getItem(GeneralEnum.STORAGE_LANGUAGE);
    let libelle = '';

    if (this.produit != null && this.produit.designations != null && this.produit.designations.length > 0) {
      this.produit.designations.forEach(designation => {
        if (designation.langue === langue) {
          libelle =  designation.libellelong;
        }
      });

      if (libelle === '' && !this.isLibelleCourtPresentAvecLangue(langue)) {
        this.produit.designations.forEach(designation => {
          if (designation.langue === LangueEnum.ANGLAIS) {
            libelle =  designation.libellelong;
          }
        });
      }

      /*
      if (libelle === '') {
        libelle = this.produit.designations[0].libellelong;
      }*/
    }

    return libelle;
  }

  public isLibelleCourtPresentAvecLangue(langue: string) {
    if (this.produit != null && this.produit.designations != null && this.produit.designations.length > 0) {
      this.produit.designations.forEach(designation => {
        if (designation.langue === langue) {
          return true;
        }
      });
    }

    return false;
  }

  public getStockLocal() {
    let libelle = this.translate.instant('product-details-dialog.onOrder');

    if (this.produit != null && this.produit.stocks != null && this.produit.stocks.length > 0) {
      this.produit.stocks.forEach(stock => {
        if (stock.agence === this.numeroAgence && stock.quantite > 0) {
          libelle = this.translate.instant('product-details-dialog.inStock');
        }
      });
    }

    return libelle;
  }

  public getStockGroupe() {
    let libelle = this.translate.instant('product-details-dialog.onOrder');

    if (this.produit != null && this.produit.stock_group != null && this.produit.stock_group.length > 0) {
      this.produit.stock_group.forEach(stock => {
        if (stock.quantite__sum > 0) {
          libelle = this.translate.instant('product-details-dialog.inStock');
        }
      });
    }

    return libelle;
  }

  public getStockGlobal() {
    let libelle = this.translate.instant('product-details-dialog.onOrder');

    if (this.produit != null && this.produit.stocks != null && this.produit.stocks.length > 0) {
      this.produit.stocks.forEach(stock => {
        if (stock.agence === this.numeroAgence && stock.quantite > 0) {
          libelle = this.translate.instant('product-details-dialog.inStock');
        }
      });
    }

    if (this.produit != null && this.produit.stock_group != null && this.produit.stock_group.length > 0) {
      this.produit.stock_group.forEach(stock => {
        if (stock.quantite__sum > 0) {
          libelle = this.translate.instant('product-details-dialog.inStock');
        }
      });
    }

    return libelle;
  }

  public getLibellePrix() {
    if (this.produit != null && this.prices != null && this.prices.length > 0 && this.prices[0].price != null) {
      const prix = this.prices[0].unite_affi === 2 ? (this.prices[0].price * 100) : this.prices[0].price;

      if (+prix === 0) {
        return this.translate.instant('product-details-dialog.consult');
      }

      return (+prix).toFixed(2) + ' ' + this.prices[0].devise + (this.prices[0].unite_affi === 2 ? ' / ' + this.translate.instant('product.hundred') : ' / ' + this.translate.instant('product.unit'));
    } else {
      return this.translate.instant('product-details-dialog.consult');
    }
  }

  public getImagePrincipale(miniature: boolean) {
    if (this.produit != null) {
      if (miniature) {
        return this.images[0].mini;
      } else {
        return this.images[0].full;
      }
    }

    /*
    let image = 'assets/images/product/materiel_default.jpg';

    if (this.produit != null && this.produit.documentations != null && this.produit.documentations.length > 0) {
      let imgList = this.produit.documentations.filter(doc => doc.typedoc.toUpperCase() === 'ISW');

      if (imgList != null && imgList.length > 0) {
        imgList = imgList.sort((d1, d2) => d2.priorite - d1.priorite);
        image = environment.url_images + (miniature ? 'W50/' : 'W400/') + imgList[0].url;
      }
    }

    return image;*/
  }

  private construireListeImages() {
    const imageDefaut = 'assets/images/product/materiel_default.jpg';

    if (this.produit != null && this.produit.documentations != null && this.produit.documentations.length > 0) {
      let imgList = this.produit.documentations.filter(doc => doc.typedoc.toUpperCase() === 'ISW');

      if (imgList != null && imgList.length > 0) {
        imgList = imgList.sort((d1, d2) => d2.priorite - d1.priorite);

        imgList.forEach(i => {
          this.images.push({
            mini: environment.url_images + 'W50/' + i.url,
            full: environment.url_images + 'W400/' + i.url
          });
        });
      } else {
        this.images.push({
          mini: imageDefaut,
          full: imageDefaut
        });
      }
    } else {
      this.images.push({
        mini: imageDefaut,
        full: imageDefaut
      });
    }
  }

  public getDocumentations() {
    const langue = localStorage.getItem(GeneralEnum.STORAGE_LANGUAGE);
    let docs: Array<Documentation> = new Array<Documentation>();

    if (langue != null) {
      docs = docs.concat(this.produit.documentations.filter(d => d.typedoc !== 'ISW' && d.langue === langue));
    }

    if (langue != null && langue !== LangueEnum.ANGLAIS) {
      docs = docs.concat(this.produit.documentations.filter(d => d.typedoc !== 'ISW' && d.langue === LangueEnum.ANGLAIS));
    }

    if (langue != null && langue !== LangueEnum.FRANCAIS) {
      docs = docs.concat(this.produit.documentations.filter(d => d.typedoc !== 'ISW' && d.langue === LangueEnum.FRANCAIS));
    }

    return docs;
  }

  public selectImage(index) {
    this.imageIndex = index;
  }

  public signalerErreurProduit(product: Produit) {
    this.openSignalerErreur(product);
  }

  public addToSelection(product: Produit, quantity) {
    if (!isNaN(quantity) && quantity > 0) {
      const prixProduit = (this.prices != null && this.prices.length > 0) ? this.prices[0].price : null;
      const deviseProduit = (this.prices != null && this.prices.length > 0) ? this.prices[0].devise : null;
      const uniteProduit = (this.prices != null && this.prices.length > 0) ? this.prices[0].unite_affi : null;
      const conditionnement = (this.prices != null && this.prices.length > 0 && this.prices[0].cdt !== 0 && this.prices[0].cdt !== 1) ? this.prices[0].cdt : 1;

      this.basketService.addProductToSelection(product, +quantity, prixProduit, deviseProduit, uniteProduit, conditionnement);
      this.openConfirmDialog(product);
    }
  }

  public increment() {
    this.inputError = null;

    if (this.produit != null && this.prices != null && this.prices.length > 0 && this.prices[0].cdt !== 0 && this.prices[0].cdt !== 1) {
      this.counter += this.prices[0].cdt;
    } else {
      this.counter++;
    }
  }

  public decrement() {
    this.inputError = null;

    if (this.produit != null && this.prices != null && this.prices.length > 0 && this.prices[0].cdt !== 0 && this.prices[0].cdt !== 1) {
      if (this.counter > this.prices[0].cdt) {
        this.counter -= this.prices[0].cdt;
      } else {
        this.counter = 0;
      }
    } else {
      if (this.counter > 1){
        this.counter--;
      }
    }
  }

  public modifierQuantite(event) {
    this.inputError = null;

    if (isNaN(+event.target.value) || +event.target.value <= 0) {
      this.inputError = this.translate.instant('product-details-dialog.invalidQuantity');
    } else {
      if (this.produit != null && this.prices != null && this.prices.length > 0 && this.prices[0].cdt !== 0 && this.prices[0].cdt !== 1) {
        if (event.target.value % this.prices[0].cdt === 0) {
          this.counter = event.target.value;
        } else {
          this.counter = event.target.value;
          // this.inputError = 'Multiple de ' + this.prices[0].cdt + ' attendu';
          this.inputError = this.translate.instant('product-details-dialog.unpacking');
        }
      } else {
        this.counter = event.target.value;
      }
    }
  }

  public onMouseMove(e){
    if (window.innerWidth >= 1280){
      let image;
      let offsetX;
      let offsetY;
      let x;
      let y;
      let zoomer;

      image = e.currentTarget;
      offsetX = e.offsetX;
      offsetY = e.offsetY;
      x = offsetX / image.offsetWidth * 100;
      y = offsetY / image.offsetHeight * 100;
      zoomer = this.zoomViewer.nativeElement.children[0];

      if (zoomer) {
        zoomer.style.backgroundPosition = x + '% ' + y + '%';
        zoomer.style.display = 'block';
        zoomer.style.height = image.height + 'px';
        zoomer.style.width = image.width + 'px';
      }
    }
  }

  public onMouseLeave(event){
    this.zoomViewer.nativeElement.children[0].style.display = 'none';
  }

  public openZoomViewer(){
    this.dialog.open(ProductZoomComponent, {
      data: this.zoomImage,
      panelClass: 'zoom-dialog'
    });
  }
}


