import {Produit} from '../models/produit';

export class Selection {
  produit: Produit;
  quantite: number;
  price: number;
  isPriceDefined: boolean;
  devise?: string;
  unitePrix?: number;
  conditionnement?: number;
  quantiteValide?: string;
  delaiSouhaite?: Date;
  selected?: boolean;
}
