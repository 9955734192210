import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Page } from '../models/page';
import {handleError, queryPaginated} from '../cors/handlers';
import {ApiEnum} from '../enums/api.enum';
import {catchError} from 'rxjs/operators';
import {Famille} from '../models/famille';
import {GeneralEnum} from '../enums/general.enum';
import {LangueEnum} from '../enums/langue.enum';

@Injectable({
  providedIn: 'root'
})
export class FamilleService {

  constructor(private httpClient: HttpClient) { }

  list(urlOrFilter?: string | object): Observable<Page<Famille>> {
    return queryPaginated<Famille>(this.httpClient, ApiEnum.ETIM_FAMILLE_LIST, urlOrFilter);
  }

  detail(id): Observable<Famille> {
    return this.httpClient.get<Famille>(ApiEnum.ETIM_FAMILLE_DETAIL + id)
      .pipe(
        catchError(handleError)
      );
  }

  trierFamilles(familles?: Array<Famille>): Array<Famille> {
    if (familles != null) {
      const familleDivers = familles.filter(f => f !== null).filter(f => f.artfamilleid === 'ETN000099');
      const sortedFamilles = familles.filter(f => f !== null).filter(f => f.artfamilleid !== 'ETN000099' && f.artfamilleid !== null).sort((f1, f2) =>
        this.getFamilleDescription(f1).localeCompare(this.getFamilleDescription(f2)));

      sortedFamilles.push(familleDivers[0]);

      return sortedFamilles;
    }

    return null;
  }

  private getFamilleDescription(famille: Famille) {
    const langue = localStorage.getItem(GeneralEnum.STORAGE_LANGUAGE);
    let desc = famille.descriptions.filter(d => d.langue === langue);

    if (desc == null || desc.length === 0) {
      desc = famille.descriptions.filter(d => d.langue === LangueEnum.ANGLAIS);
    }
    if ((desc == null || desc.length === 0) && famille.descriptions.length > 0) {
      desc = famille.descriptions;
    }

    return desc[0].artfamilledesc;
  }
}
