import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProduitService } from '../../../services/produit.service';
import { Page } from '../../../models/page';
import { Produitautocompletefull } from '../../../models/produitautocompletefull';
import { GeneralEnum } from '../../../enums/general.enum';
import { environment } from '../../../../environments/environment';
import { PricesService } from '../../../services/prices.service';
import { BasketService } from '../../../services/basket.service';
import { ProductDetailsDialogComponent } from '../../products/product-details-dialog/product-details-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Etimclassutil } from '../../../util/etimclassutil';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-product-search',
  templateUrl: './product-search.component.html',
  styleUrls: ['./product-search.component.sass'],
})
export class ProductSearchComponent implements OnInit {
  @Output() eventOpenProductDialog: EventEmitter<any> = new EventEmitter();
  @Output() eventOpenProductDetailDialog: EventEmitter<any> =
    new EventEmitter();

  public viewType: string = 'grid';
  public viewCol: number = 25;
  public page: any;

  searchForm: FormGroup;
  loading = false;
  submitted = false;
  error = '';

  sortedReference = null;
  sortedMarque = null;

  produits: Page<Produitautocompletefull>;
  numeroAgence: number;

  constructor(
    private formBuilder: FormBuilder,
    private produitService: ProduitService,
    private pricesService: PricesService,
    private basketService: BasketService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.numeroAgence = JSON.parse(
      localStorage.getItem(GeneralEnum.STORAGE_AGENCE)
    ).numero;
  }

  get f() {
    return this.searchForm.controls;
  }

  ngOnInit() {
    this.searchForm = this.formBuilder.group({
      marque: [''],
      referenceClient: [''],
      designation: [''],
      codeArticle: [''],
      inStock: [false],
    });
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.searchForm.invalid) {
      return;
    }

    this.loading = true;
    this.produits = null;

    if (
      (this.f.referenceClient.value == null ||
        this.f.referenceClient.value === '') &&
      (this.f.marque.value == null || this.f.marque.value === '') &&
      (this.f.designation.value == null || this.f.designation.value === '') &&
      (this.f.codeArticle.value == null || this.f.codeArticle.value === '')
    ) {
      this.error = this.translate.instant('product-search.criteriaError');
      this.loading = false;
    } else {
      this.rechercherProduits();
    }
  }

  private rechercherProduits(ordering: string = null) {
    const langue = localStorage.getItem(GeneralEnum.STORAGE_LANGUAGE);

    const paramsRequete = [];
    paramsRequete['langue'] = langue;
    if (
      this.f.referenceClient.value !== null &&
      this.f.referenceClient.value !== ''
    ) {
      paramsRequete['ref'] = this.f.referenceClient.value;
    }
    if (this.f.marque.value !== null && this.f.marque.value !== '') {
      paramsRequete['marque'] = this.f.marque.value;
    }
    if (this.f.designation.value !== null && this.f.designation.value !== '') {
      paramsRequete['libellecourt'] = this.f.designation.value;
    }
    if (this.f.codeArticle.value !== null && this.f.codeArticle.value !== '') {
      paramsRequete['refArticle'] = this.f.codeArticle.value;
    }
    if (this.f.inStock.value !== null && this.f.inStock.value !== '') {
      paramsRequete['isStock'] = this.f.inStock.value ? 1 : 0;
    }
    if (ordering != null) {
      paramsRequete['ordering'] = ordering;
    }

    this.produitService.searchAutocompleteWithParams(paramsRequete).subscribe({
      next: (value) => {
        this.produits = value;

        if (this.produits != null && this.produits.results != null) {
          this.produits.results.forEach((p) => {
            p.filAriane = Etimclassutil.getGroupeArianeFromClasse(
              p.produit.artclassid
            );
          });
        }

        this.page = 1;
        this.loading = false;
      },
      error: (err) => {
        this.error = err;
        this.loading = false;
      },
    });
  }

  public changeViewType(viewType, viewCol) {
    this.viewType = viewType;
    this.viewCol = viewCol;
  }

  public onPageChanged(event) {
    let url = '';

    if (event > this.page) {
      const nextPage = this.page + 1;
      url = this.produits.next.replace('page=' + nextPage, 'page=' + event);
    } else {
      const previousPage = this.page - 1;
      url = this.produits.previous.replace(
        'page=' + previousPage,
        'page=' + event
      );
    }

    this.produitService.searchAutocompleteWithUrl(url).subscribe({
      next: (value) => {
        this.produits = value;

        if (this.produits != null && this.produits.results != null) {
          this.produits.results.forEach((p) => {
            p.filAriane = Etimclassutil.getGroupeArianeFromClasse(
              p.produit.artclassid
            );
          });
        }

        this.page = event;
      },
      error: (err) => (this.error = err),
      complete: () => (this.loading = false),
    });

    window.scrollTo(0, 0);
  }

  public getImagePrincipale(p: Produitautocompletefull, miniature: boolean) {
    let image = 'assets/images/product/materiel_default.jpg';

    if (
      p.produit.documentations != null &&
      p.produit.documentations.length > 0
    ) {
      let imgList = p.produit.documentations.filter(
        (doc) => doc.typedoc.toUpperCase() === 'ISW'
      );

      if (imgList != null && imgList.length > 0) {
        imgList = imgList.sort((d1, d2) => d2.priorite - d1.priorite);
        image =
          environment.url_images +
          (miniature ? 'W50/' : 'W400/') +
          imgList[0].url;
      }
    }

    return image;
  }

  public addToCart(produit: Produitautocompletefull, quantity: number = 1) {
    const idcustomer = JSON.parse(
      localStorage.getItem(GeneralEnum.STORAGE_USER)
    ).customer_selected;

    this.produitService
      .get(+produit.idproduit)
      .toPromise()
      .then((prod) => {
        this.pricesService
          .list(prod.id, idcustomer)
          .toPromise()
          .then((prices) => {
            const prixProduit =
              prices != null && prices.results.length > 0
                ? prices.results[0].price
                : null;
            const prixDevise =
              prices != null && prices.results.length > 0
                ? prices.results[0].devise
                : null;
            const prixUnite =
              prices != null && prices.results.length > 0
                ? prices.results[0].unite_affi
                : null;
            const conditionnement =
              prices != null &&
              prices.results.length > 0 &&
              prices.results[0].unite_affi === 2
                ? prices.results[0].cdt
                : null;

            this.basketService.addProductToSelection(
              prod,
              quantity,
              prixProduit,
              prixDevise,
              prixUnite,
              conditionnement
            );
            this.router.navigate(['/pages/selection']);
          });
      });
  }

  public openProductDetailDialog(produitautofull) {
    this.produitService.get(produitautofull.idproduit).subscribe({
      next: (value) => {
        this.dialog.open(ProductDetailsDialogComponent, {
          data: value,
          panelClass: 'product-dialog',
        });
      },
      error: (err) => (this.error = err),
    });

    /*
    const dialogRef = this.dialog.open(ProductDetailsDialogComponent, {
      data: produit,
      panelClass: 'product-dialog',
    });
    dialogRef.afterClosed().subscribe(product => {
      if (product){
        this.router.navigate(['/products', product.id, product.name]);
      }
    });*/
  }

  public isEnStockLocal(produit: Produitautocompletefull) {
    if (
      produit.produit != null &&
      produit.produit.stock != null &&
      produit.produit.stock.length > 0
    ) {
      const pStock = produit.produit.stock.filter(
        (s) => s.quantite > 0 && s.agence === this.numeroAgence
      );
      if (pStock != null && pStock.length > 0) {
        return true;
      }
    }

    return false;
  }

  public isEnStockGroupe(produit: Produitautocompletefull) {
    if (
      produit.produit != null &&
      produit.produit.stock_group != null &&
      produit.produit.stock_group.length > 0
    ) {
      const pStock = produit.produit.stock_group.filter(
        (s) => s.quantite__sum > 0
      );
      if (pStock != null && pStock.length > 0) {
        return true;
      }
    }

    return false;
  }

  public isEnStock(produit: Produitautocompletefull) {
    return this.isEnStockLocal(produit) || this.isEnStockGroupe(produit);
  }

  public trierResultatsParReference() {
    this.sortedMarque = null;

    if (this.sortedReference == null || this.sortedReference === 'DESC') {
      this.sortedReference = 'ASC';
      this.rechercherProduits('ref');
    } else {
      this.sortedReference = 'DESC';
      this.rechercherProduits('-ref');
    }
  }

  public trierResultatsParMarque() {
    this.sortedReference = null;

    if (this.sortedMarque == null || this.sortedMarque === 'DESC') {
      this.sortedMarque = 'ASC';
      this.rechercherProduits('marque');
    } else {
      this.sortedMarque = 'DESC';
      this.rechercherProduits('-marque');
    }
  }

  public goToCategorieSearch(produit: Produitautocompletefull) {
    const hierarchie = Etimclassutil.getHierarchieIds(
      produit.produit.artclassid
    );
    this.router.navigate(['/catalogue/category/search'], {
      queryParams: {
        type: 'groupe',
        id: hierarchie.groupe,
        idFamille: hierarchie.famille,
        idClass: hierarchie.classe,
      },
    });
  }

  public goToFamille(familleid: string) {
    this.router.navigate(['/catalogue/groups/search'], {
      queryParams: { type: 'famille', id: familleid },
    });
  }

  public goToGroupe(familleid: string, groupeid: string) {
    this.router.navigate(['/catalogue/category/search'], {
      queryParams: { type: 'groupe', id: groupeid, idFamille: familleid },
    });
  }

  public goToClasse(familleid: string, groupeid: string, classeid: string) {
    this.router.navigate(['/catalogue/category/search'], {
      queryParams: {
        type: 'groupe',
        id: groupeid,
        idFamille: familleid,
        idClass: classeid,
      },
    });
  }

  public getLibelleCodeArticle(produit: Produitautocompletefull) {
    const infosClient = JSON.parse(
      localStorage.getItem(GeneralEnum.STORAGE_CUSTOMER)
    );
    let libelle = null;

    if (produit.codeArticle != null && produit.codeArticle.length > 0) {
      produit.codeArticle.forEach((c) => {
        const tuple = c
          .replace('(', '')
          .replace(')', '')
          .replace("'", '')
          .split(',');

        if (+tuple[0] === infosClient.id) {
          if (libelle == null) {
            libelle = tuple[1].replace("'", '');
          } else {
            libelle += ' - ' + tuple[1].replace("'", '');
          }
        }
      });
    }

    return libelle;
  }
}
