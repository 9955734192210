import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Selection} from '../../../../pojo/selection';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Item} from '../../../../models/item';
import {Order} from '../../../../models/order';
import {OrderService} from '../../../../services/order.service';
import {BasketService} from '../../../../services/basket.service';
import {formatDate} from '@angular/common';

@Component({
  selector: 'app-prices-dmd-dialog',
  templateUrl: './prices-dmd-dialog.component.html',
  styleUrls: ['./prices-dmd-dialog.component.sass']
})
export class PricesDmdDialogComponent implements OnInit {

  priceDmdForm: FormGroup;
  submitted = false;
  loading = false;
  error = '';

  constructor(private router: Router,
              private formBuilder: FormBuilder,
              private orderService: OrderService,
              private basketService: BasketService,
              public dialogRef: MatDialogRef<PricesDmdDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public selections: Array<Selection>,
              @Inject(LOCALE_ID) private locale: string) { }

  ngOnInit() {
    this.priceDmdForm = this.formBuilder.group({
      numeroAffaire: [''],
      commentaire: ['']
    });
  }

  get f() { return this.priceDmdForm.controls; }

  public close(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    this.submitted = true;
    this.loading = true;

    const itemSelection: Array<Item> = new Array<Item>();

    this.selections.forEach(s => {
      const it: Item = {
        idproduit: s.produit.id,
        quantite: s.quantite,
        price: s.price,
        delai_souhaite: s.delaiSouhaite != null ? formatDate(s.delaiSouhaite, 'yyyy-MM-dd', this.locale) : null
      };
      itemSelection.push(it);
    });

    const commande: Order = {
      comment: this.f.commentaire.value,
      num_affaire: this.f.numeroAffaire.value,
      type_order: {name: 'DDP'},
      items: itemSelection
    };

    this.orderService.add(commande).subscribe({
      next: result => {
        this.basketService.removeAllFromSelection().then(value => {
          this.close();
          this.router.navigate(['/pages/ddp-list']);
        }).catch(err => console.log(err));
      },
      error: err => this.error = err
    });

    this.loading = false;
  }
}
