import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralEnum } from '../../../enums/general.enum';
import { Famille } from '../../../models/famille';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass'],
})
export class HomeComponent implements OnInit {
  public familles: Array<any> = [];
  error: string;

  constructor(private router: Router) {}

  ngOnInit() {
    const familles = JSON.parse(
      localStorage.getItem(GeneralEnum.STORAGE_FAMILLES)
    );

    let row: Array<any> = [];
    let iCol = 0;
    let iRow = 0;
    for (let i = 0; i < familles.length; i++) {
      row[iCol] = familles[i];
      iCol++;
      if ((i + 1) % 4 === 0) {
        this.familles[iRow] = row;
        row = [];
        iRow++;
        iCol = 0;
      }
    }
    if (row.length > 0) {
      this.familles[iRow] = row;
    }
  }

  public getFamilleImage(famille) {
    return {
      'background-image':
        famille != null && famille.image != null
          ? 'url(' + famille.image + ')'
          : 'url(' + 'assets/images/categories/inventory.png' + ')',
    };
  }

  public afficherProduitsFamille(famille) {
    this.router.navigate(['/catalogue/groups/search'], {
      queryParams: { type: 'famille', id: famille.artfamilleid },
    });
  }

  public getFamilleDescription(famille: Famille) {
    if (famille != null) {
      const langue = localStorage.getItem(GeneralEnum.STORAGE_LANGUAGE);
      let desc = famille.descriptions.filter((d) => d.langue === langue);

      if (desc == null || desc.length === 0) {
        desc = famille.descriptions.filter((d) => d.langue === 'en-GB');
      }
      if (
        (desc == null || desc.length === 0) &&
        famille.descriptions.length > 0
      ) {
        desc = famille.descriptions;
      }

      return desc[0].artfamilledesc;
    } else {
      return null;
    }
  }
}
