import {Component, Inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BasketService} from '../../../../services/basket.service';
import {Selection} from '../../../../pojo/selection';

@Component({
  selector: 'app-confirm-delete-selection',
  templateUrl: './confirm-delete-selection.component.html',
  styleUrls: ['./confirm-delete-selection.component.sass']
})
export class ConfirmDeleteSelectionComponent implements OnInit {

  constructor(private router: Router,
              public dialogRef: MatDialogRef<ConfirmDeleteSelectionComponent>,
              private basketService: BasketService,
              @Inject(MAT_DIALOG_DATA) public item: Selection) { }

  ngOnInit() { }

  public close(): void {
    this.dialogRef.close();
  }

  public async removeItem() {
    await this.basketService.removeProductFromSelection(this.item.produit);
    await this.dialogRef.close();
    await this.router.navigate(['/pages/selection']);
  }
}
