import { Component, OnInit } from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {ProduitService} from '../../../services/produit.service';
import {TypeaheadMatch} from 'ngx-bootstrap/typeahead';
import {debounceTime, mergeMap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {GeneralEnum} from '../../../enums/general.enum';
import {Agence} from '../../../models/agence';
import {AuthenticationService} from '../../../services/authentication.service';
import {Customer} from '../../../models/customer';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {

  public currencies = ['EUR'];
  public currency: any;
  public flags = [
    { name: 'Français', image: 'assets/images/flags/fr.svg' },
    { name: 'English', image: 'assets/images/flags/gb.svg' },
    { name: 'Deutsch', image: 'assets/images/flags/de.svg' },
    { name: 'Nederlands', image: 'assets/images/flags/nl.svg' }
  ];
  public flag: any;

  indexProduct: number;

  dataSource: Observable<any>;
  asyncSelected: string;
  typeaheadLoading: boolean;
  typeaheadNoResults: boolean;
  semaphoreAffichageResultats: boolean = true;
  majAgenceSubscription: Subscription;
  telephoneSupport: string;
  userLogo: string = null;

  constructor(private produitService: ProduitService, private authenticationService: AuthenticationService, private router: Router) {
    this.majAgenceSubscription = this.authenticationService.getUpdateAgence().subscribe(
      value => {
        this.setTelephoneSupport();
        this.setLogoClient();
      }
    );

    this.dataSource = new Observable<any>((observer: any) => {
      // Runs on every search
      observer.next(this.asyncSelected);
    }).pipe(mergeMap((token: string) => this.searchAutocompleteProducts(token)), debounceTime(500));
  }

  ngOnInit() {
    this.currency = this.currencies[0];
    this.flag = this.flags[0];
    this.setTelephoneSupport();
    this.setLogoClient();
  }

  public changeCurrency(currency){
    this.currency = currency;
  }
  public changeLang(flag){
    this.flag = flag;
  }

  onSubmit() {
    this.semaphoreAffichageResultats = false;
    this.router.navigate(['/catalogue/product/search-autocomplete'], {queryParams: {recherche: JSON.stringify(this.asyncSelected)}});
  }

  onSearchDirectly(btnElement) {
    this.semaphoreAffichageResultats = false;
    btnElement.click();
  }

  async searchAutocompleteProducts(text) {
    const langue = localStorage.getItem(GeneralEnum.STORAGE_LANGUAGE);

    if (text != null && text.length >= 2 && this.semaphoreAffichageResultats) {
      return this.produitService.searchAutocomplete(text, langue).toPromise().then(value => value.results);
    }
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  changeTypeaheadNoResults(e: boolean): void {
    this.typeaheadNoResults = e;
  }

  typeaheadOnSelect(e: TypeaheadMatch): void {
    this.router.navigate(['/catalogue/product/' + e.item.idproduit]);
  }

  setTelephoneSupport() {
    const agence: Agence = JSON.parse(localStorage.getItem(GeneralEnum.STORAGE_AGENCE));

    this.telephoneSupport = (agence != null && agence.tel != null) ? agence.tel : null;
  }

  setLogoClient() {
    const customer: Customer = JSON.parse(localStorage.getItem(GeneralEnum.STORAGE_CUSTOMER));
    this.userLogo = customer.logo;
  }
}
