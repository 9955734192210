import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProduitService } from '../../../services/produit.service';
import { EtimClassService } from '../../../services/etimclass.service';
import { Page } from '../../../models/page';
import { Produit, SearchMarque } from '../../../models/produit';
import { Artclass, EtimClass, EtimGroup } from '../../../models/etimclass';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  Caracteristique,
  CaracteristiqueValue,
} from '../../../pojo/caracteristique';
import { EtimFeatureService } from '../../../services/etimfeature.service';
import { FamilleService } from '../../../services/famille.service';
import { GeneralEnum } from '../../../enums/general.enum';
import { EtimFeature, EtimUnit, EtimValue } from '../../../models/etimfeature';
import { Famille } from '../../../models/famille';
import { ProduitFilter } from '../../../models/produitfilter';
import { LangueEnum } from '../../../enums/langue.enum';
import { MatDialog } from '@angular/material/dialog';
import { ProductDetailsDialogComponent } from '../../products/product-details-dialog/product-details-dialog.component';
import { environment } from '../../../../environments/environment';
import { Etimclassutil } from '../../../util/etimclassutil';
import { Filtersclasscount } from '../../../models/filtersclasscount';
import {
  FiltersEtimMarque,
  FiltersEtimValue,
} from '../../../models/filtersglobalcount';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-categories-search',
  templateUrl: './categories-search.component.html',
  styleUrls: ['./categories-search.component.sass'],
})
export class CategoriesSearchComponent implements OnInit, AfterViewInit {
  @Output() eventOpenProductDialog: EventEmitter<any> = new EventEmitter();
  @Output() eventOpenProductDetailDialog: EventEmitter<any> =
    new EventEmitter();

  NB_CARATERISTIQUES_VISIBLES = environment.nb_filtres_visibles;

  public viewType: string = 'list';
  public viewCol: number = 100;
  public itemPerPage: number = 50;
  public page: any;
  public title: string;

  produits: Page<Produit>;
  marques: Array<SearchMarque>;
  famille: Famille;
  groupe: EtimGroup;
  selectedClass: Artclass;
  minRangeModels: { [key: string]: string } = {};
  maxRangeModels: { [key: string]: string } = {};
  classExpanded: boolean = true;
  initScreenDone: boolean = false;

  filtreCaracteristique: Array<Caracteristique> = new Array<Caracteristique>();
  filtreListCaracteristique: Array<Caracteristique> =
    new Array<Caracteristique>();
  filtreRangeCaracteristique: Array<Caracteristique> =
    new Array<Caracteristique>();
  filtreExcludeCaracteristique: Array<Caracteristique> =
    new Array<Caracteristique>();
  filtreMarque: Array<SearchMarque> = new Array<SearchMarque>();
  filtreEnStock: boolean = true;
  filtreTexte: string = null;

  error = '';
  sortedReference = null;
  sortedMarque = null;

  caracteristiquesView: Array<Caracteristique> = new Array<Caracteristique>();

  filtresActifs: Array<ProduitFilter> = new Array<ProduitFilter>();
  filtresActifsView: Array<ProduitFilter> = new Array<ProduitFilter>();

  filtersClassCount: Array<Filtersclasscount>;
  filtersMarqueCount: Array<FiltersEtimMarque>;
  filtersFeatureCount: { [key: string]: number } = {};
  filtersValuesCount: Array<FiltersEtimValue>;

  constructor(
    private route: ActivatedRoute,
    public produitService: ProduitService,
    private router: Router,
    private etimclassService: EtimClassService,
    private familleService: FamilleService,
    private featureService: EtimFeatureService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private ngxSpinner: NgxSpinnerService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.route.queryParams.subscribe((params) => {
      const id = params.id;
      const idFamille = params.idFamille;
      const familles = JSON.parse(
        localStorage.getItem(GeneralEnum.STORAGE_FAMILLES)
      );
      const selectedFamille = familles
        .filter((f) => f !== null)
        .filter((f) => f.artfamilleid === idFamille)[0];

      this.famille = selectedFamille;
      selectedFamille.groups.forEach((g) => {
        if (g.artgroupid === id) {
          this.groupe = g;
          this.title =
            this.getFamilleDescription(selectedFamille) +
            ' -\u{003e} ' +
            this.getGroupeDescription(g);

          if (params.hasOwnProperty('idClass')) {
            const classe = this.groupe.etimartclass.filter(
              (c) => c.artclassid === params.idClass
            );
            if (classe != null && classe.length === 1) {
              this.onClassChanged(classe[0]);
            }
          } else {
            let filter = null;
            if (this.filtreEnStock) {
              filter = 'isStock=1';
            }

            this.produitService
              .listMarquesForEtimGroup(this.groupe.artgroupid, filter)
              .subscribe({
                next: (value) => {
                  this.marques = value;
                  this.updateProductList();
                },
                error: (err) => {
                  this.error = err;
                },
              });
          }
        }
      });
    });
  }

  ngOnInit() {
    this.produits = null;
    this.marques = null;

    this.selectedClass = null;
    this.minRangeModels = {};
    this.maxRangeModels = {};

    this.filtreCaracteristique = new Array<Caracteristique>();
    this.filtreListCaracteristique = new Array<Caracteristique>();
    this.filtreRangeCaracteristique = new Array<Caracteristique>();
    this.filtreExcludeCaracteristique = new Array<Caracteristique>();
    this.filtreMarque = new Array<SearchMarque>();
    this.filtreEnStock = true;

    this.error = '';
    this.sortedReference = null;
    this.sortedMarque = null;

    this.caracteristiquesView = new Array<Caracteristique>();

    this.filtresActifs = new Array<ProduitFilter>();
    this.filtresActifsView = new Array<ProduitFilter>();
  }

  ngAfterViewInit() {
    this.initScreenDone = true;
  }

  public getFamilleDescription(famille: Famille) {
    const langue = localStorage.getItem(GeneralEnum.STORAGE_LANGUAGE);
    let desc = famille.descriptions.filter((d) => d.langue === langue);

    if (desc == null || desc.length === 0) {
      desc = famille.descriptions.filter(
        (d) => d.langue === LangueEnum.ANGLAIS
      );
    }
    if (
      (desc == null || desc.length === 0) &&
      famille.descriptions.length > 0
    ) {
      desc = famille.descriptions;
    }

    return desc[0].artfamilledesc;
  }

  public getGroupeDescription(groupe: EtimGroup) {
    const langue = localStorage.getItem(GeneralEnum.STORAGE_LANGUAGE);
    let desc = groupe.groupdesc.filter((d) => d.langue === langue);

    if (desc == null || desc.length === 0) {
      desc = groupe.groupdesc.filter((d) => d.langue === LangueEnum.ANGLAIS);
    }

    if (desc == null && groupe.groupdesc.length > 0) {
      desc = groupe.groupdesc;
    }

    return desc[0].groupdesc;
  }

  getSelectedClassDescription() {
    const langue = localStorage.getItem(GeneralEnum.STORAGE_LANGUAGE);

    if (this.selectedClass != null) {
      let desc = this.selectedClass.arclassdesc.filter(
        (d) => d.langue === langue
      );

      if (desc == null || desc.length === 0) {
        desc = this.selectedClass.arclassdesc.filter(
          (d) => d.langue === LangueEnum.ANGLAIS
        );
      }
      if (
        (desc == null || desc.length === 0) &&
        this.selectedClass.arclassdesc.length > 0
      ) {
        desc = this.selectedClass.arclassdesc;
      }

      return desc[0].arclassdesc;
    }

    return null;
  }

  getNombreProduits() {
    return this.produits == null ? 0 : this.produits.count;
  }

  onClassChanged(etimclass: Artclass) {
    this.ngxSpinner.show();

    this.filtresActifs = new Array<ProduitFilter>();
    this.filtresActifsView = new Array<ProduitFilter>();
    this.caracteristiquesView = new Array<Caracteristique>();
    this.filtreTexte = null;

    if (etimclass == null) {
      this.selectedClass = null;
      this.produits = null;
      this.classExpanded = true;

      this.produitService
        .listMarquesForEtimGroup(this.groupe.artgroupid)
        .subscribe({
          next: (value) => {
            this.marques = value;
          },
          error: (err) => {
            this.error = err;
          },
        });
    } else {
      this.classExpanded = false;

      let criteresProduits = null;
      criteresProduits = {
        isStock: this.filtreEnStock ? 1 : 0,
      };

      criteresProduits.etimclass__artclassid = etimclass.artclassid;

      this.produitService.listByCriteres(null, criteresProduits).subscribe({
        next: (value) => {
          this.produits = value;

          let filtreGlobalCount = null;
          if (this.filtreEnStock) {
            filtreGlobalCount = 'isStock=1';
          }

          this.produitService
            .filtersGlobalCount(
              this.groupe.artgroupid,
              etimclass.artclassid,
              filtreGlobalCount
            )
            .subscribe({
              next: (result) => {
                if (result != null) {
                  result.etim_features.forEach((r) => {
                    this.filtersFeatureCount[r.featureid] = r.ref__count;
                  });

                  this.filtersValuesCount = result.etim_values;
                  this.filtersMarqueCount = result.marque;
                }
              },
              error: (err) => {
                console.log(err);
              },
            });

          if (this.produits != null && this.produits.results != null) {
            this.produits.results.forEach((p) => {
              p.filAriane =
                p.etimclass != null
                  ? Etimclassutil.getGroupeArianeFromClasse(
                      p.etimclass.artclassid
                    )
                  : null;
            });
          }

          this.selectedClass = etimclass;
          this.contruireCaracteristiques();
          this.page = 1;
          this.ngxSpinner.hide();
        },
        error: (err) => {
          this.error = err;
          this.ngxSpinner.hide();
        },
      });

      this.produitService
        .listMarquesForEtimClass(etimclass.artclassid)
        .subscribe({
          next: (value) => {
            this.marques = value;
          },
          error: (err) => {
            this.error = err;
          },
        });
    }
  }

  onMarqueChanged(marque: SearchMarque) {
    if (marque.selected) {
      this.filtreMarque.push(marque);
      this.ajouterFiltreMarque(marque);
    } else {
      this.filtreMarque = this.filtreMarque.filter(
        (m) => m.marque__marque !== marque.marque__marque
      );
      this.supprimerFiltreFromCaracteristique(marque.marque__marque);
    }

    if (this.selectedClass == null && this.filtreMarque.length === 0) {
      this.filtresActifs = new Array<ProduitFilter>();
      this.filtresActifsView = new Array<ProduitFilter>();
      this.caracteristiquesView = new Array<Caracteristique>();
      this.filtreTexte = null;
    }

    if (this.selectedClass !== null) {
      this.updateProductList(false);
    } else {
      this.updateProductList(false);

      if (this.selectedClass != null || this.filtreMarque.length > 0) {
        this.contruireCaracteristiques();
      }
      this.updateClassesCount();
    }
  }

  onFiltreStockChanged() {
    this.updateProductList();

    if (this.filtreEnStock) {
      this.ajouterFiltreEnStock();
    } else {
      this.supprimerFiltreFromCaracteristique('EN_STOCK');
    }
  }

  onFeatureChanged(feature: Caracteristique) {
    if (feature.type === 'CHECK') {
      this.filtreCaracteristique = this.filtreCaracteristique.filter(
        (c) => c.id !== feature.id
      );
      this.supprimerFiltreFromCaracteristique(feature.id);

      if (feature.value != null) {
        this.filtreCaracteristique.push(feature);
        this.ajouterFiltreProduit(feature);
      }
    }

    if (feature.type === 'LIST') {
      const temp = this.filtreListCaracteristique.filter(
        (c) => c.id === feature.id
      );
      const tempExclude = this.filtreExcludeCaracteristique.filter(
        (c) => c.id === feature.id
      );

      if (feature.value === 'AUTRE') {
        if (tempExclude != null && tempExclude.length === 1) {
          this.filtreExcludeCaracteristique =
            this.filtreExcludeCaracteristique.filter(
              (c) => c.id !== feature.id
            );
          this.supprimerFiltreFromCaracteristique(feature.id);
        } else {
          this.filtreExcludeCaracteristique.push(feature);
          this.ajouterFiltreProduit(feature);
        }
      } else {
        if (temp != null && temp.length === 1) {
          const selectedValues = temp[0].selectedValuesList;

          if (selectedValues != null && selectedValues.length >= 1) {
            if (
              selectedValues.filter((sv) => sv === feature.value).length === 1
            ) {
              temp[0].selectedValuesList = selectedValues.filter(
                (sv) => sv !== feature.value
              );
            } else {
              temp[0].selectedValuesList.push(feature.value);
            }
          } else {
            if (temp[0].selectedValuesList == null) {
              temp[0].selectedValuesList = new Array<string>();
            }
            temp[0].selectedValuesList.push(feature.value);
          }

          this.filtreListCaracteristique =
            this.filtreListCaracteristique.filter((c) => c.id !== feature.id);
          this.supprimerFiltreFromCaracteristique(feature.id);

          if (
            temp[0].selectedValuesList != null &&
            temp[0].selectedValuesList.length > 0
          ) {
            this.filtreListCaracteristique.push(temp[0]);
            this.ajouterFiltreProduit(feature);
          }
        } else {
          if (feature.selectedValuesList == null) {
            feature.selectedValuesList = new Array<string>();
          }
          feature.selectedValuesList.push(feature.value);
          this.filtreListCaracteristique =
            this.filtreListCaracteristique.filter((f) => f.id !== feature.id);
          this.supprimerFiltreFromCaracteristique(feature.id);
          this.filtreListCaracteristique.push(feature);
          this.ajouterFiltreProduit(feature);
        }
      }
    }

    if (feature.type === 'RANGE') {
      const temp = this.filtreRangeCaracteristique.filter(
        (c) => c.id === feature.id
      );

      if (
        feature.selectedMinValue !== String(feature.valeurMin) ||
        feature.selectedMaxValue !== String(feature.valeurMax)
      ) {
        if (temp != null && temp.length === 1) {
          this.filtreRangeCaracteristique =
            this.filtreRangeCaracteristique.filter((c) => c.id !== feature.id);
          this.supprimerFiltreFromCaracteristique(feature.id);
        }
        this.filtreRangeCaracteristique.push(feature);
        this.ajouterFiltreProduit(feature);
      } else {
        this.filtreRangeCaracteristique =
          this.filtreRangeCaracteristique.filter((c) => c.id !== feature.id);
        this.supprimerFiltreFromCaracteristique(feature.id);
      }
    }

    this.updateProductList();
  }

  ajouterFiltreMarque(marque: SearchMarque) {
    this.filtresActifs.push({
      id: marque.marque__marque,
      title: 'Marque',
      value: marque.marque__marque,
    });
  }

  ajouterFiltreEnStock() {
    this.filtresActifs.push({
      id: 'EN_STOCK',
      type: 'STOCK',
      title: this.translate.instant('categories-search.productInStock'),
      value: this.translate.instant('categories-search.yes'),
    });
  }

  ajouterFiltreProduit(caracteristique: Caracteristique) {
    const idFiltre = caracteristique.id;
    const titreFiltre = caracteristique.name;
    const typeFiltre = caracteristique.type;
    let valeurFiltre = '';

    if (caracteristique.type === 'CHECK') {
      valeurFiltre = caracteristique.value
        ? this.translate.instant('categories-search.yes')
        : this.translate.instant('categories-search.no');
    }

    if (caracteristique.type === 'LIST') {
      if (caracteristique.value === 'AUTRE') {
        valeurFiltre = this.translate.instant('categories-search.notFilled');
      } else {
        caracteristique.selectedValuesList.forEach((v) => {
          if (valeurFiltre !== '') {
            valeurFiltre += ' - ';
          }

          caracteristique.valuesList.forEach((vl) => {
            if (vl.id === v) {
              valeurFiltre += vl.libelle;
            }
          });
        });
      }
    }

    if (caracteristique.type === 'RANGE') {
      valeurFiltre =
        caracteristique.selectedMinValue +
        '-' +
        caracteristique.selectedMaxValue;
      if (caracteristique.unite != null) {
        valeurFiltre += ' ' + caracteristique.unite;
      }
    }

    this.filtresActifs.push({
      id: idFiltre,
      title: titreFiltre,
      type: typeFiltre,
      value: valeurFiltre,
    });
  }

  supprimerFiltreMarqueFromListe(filtre: ProduitFilter) {
    this.filtresActifs = this.filtresActifs.filter((f) => f.id !== filtre.id);
    this.filtreMarque = this.filtreMarque.filter(
      (m) => m.marque__marque !== filtre.id
    );

    this.marques.forEach((m) => {
      if (m.marque__marque === filtre.id) {
        m.selected = false;
      }
    });

    this.updateProductList();
  }

  supprimerEnStockFromListe(filtre: ProduitFilter) {
    this.filtresActifs = this.filtresActifs.filter((f) => f.id !== filtre.id);
    this.filtreEnStock = false;

    this.updateProductList();
  }

  /** Suppression du filtre à partir des pastilles de filtres actifs */
  supprimerFiltreProduitFromListe(filtre: ProduitFilter) {
    this.filtresActifs = this.filtresActifs.filter((f) => f.id !== filtre.id);

    if (filtre.type === 'CHECK') {
      this.filtreCaracteristique = this.filtreCaracteristique.filter(
        (c) => c.id !== filtre.id
      );

      this.caracteristiquesView.forEach((c) => {
        if (c.id === filtre.id) {
          c.value = null;
        }
      });
    }

    if (filtre.type === 'LIST') {
      if (
        filtre.value === this.translate.instant('categories-search.notFilled')
      ) {
        this.filtreExcludeCaracteristique =
          this.filtreExcludeCaracteristique.filter((c) => c.id !== filtre.id);
      } else {
        this.filtreListCaracteristique = this.filtreListCaracteristique.filter(
          (f) => f.id !== filtre.id
        );

        this.caracteristiquesView.forEach((cl) => {
          if (cl.id === filtre.id) {
            cl.valuesList.forEach((vl) => {
              vl.selected = false;
            });

            cl.autreSelected = false;
          }
        });
      }
    }

    if (filtre.type === 'RANGE') {
      this.filtreRangeCaracteristique = this.filtreRangeCaracteristique.filter(
        (r) => r.id !== filtre.id
      );

      this.caracteristiquesView.forEach((c) => {
        if (c.id === filtre.id) {
          this.minRangeModels[c.id] = c.valeurMin;
          this.maxRangeModels[c.id] = c.valeurMax;
        }
      });
    }

    if (filtre.type === 'TEXTE') {
      this.filtreTexte = null;
    }

    this.updateProductList();
  }

  /** Suppression du filtre à partir des caractéristiques */
  supprimerFiltreFromCaracteristique(id: string) {
    this.filtresActifs = this.filtresActifs.filter((f) => f.id !== id);
  }

  updateClassesCount() {
    let marqueValues: string = '';
    this.filtreMarque.forEach((m) => {
      marqueValues += m.marque__marque + ',';
    });
    if (marqueValues !== '') {
      marqueValues = marqueValues.slice(0, -1);
    }

    /*
    this.produitService.filtersClassCountFiltered(this.groupe.artgroupid, marqueValues).subscribe({
      next: value => {

      }
    });*/
  }

  updateProductList(withMarque: boolean = false) {
    this.ngxSpinner.show();

    let featureValues: Array<any> = new Array<any>();
    this.filtreCaracteristique.forEach((fc) => {
      featureValues.push({ featureid: fc.id, value: fc.value });
    });

    this.filtreListCaracteristique.forEach((fc) => {
      featureValues.push({ featureid: fc.id, value: fc.selectedValuesList });
    });

    this.filtreExcludeCaracteristique.forEach((fc) => {
      let values = this.caracteristiquesView
        .filter((c) => c.id === fc.id)
        .map((c) => c.valuesList)[0]
        .map((v) => v.id);
      const sameFeat = this.filtreListCaracteristique.filter(
        (f) => f.id === fc.id
      );

      // On exclut toutes les autres valeurs sauf celles qui sont sélectionnées
      if (sameFeat != null && sameFeat.length === 1) {
        sameFeat[0].selectedValuesList.forEach((v) => {
          values = values.filter((vs) => vs !== v);
        });

        // Et on supprime la valeur sélectionnée pour ne garder que les exclude_values
        featureValues = featureValues.filter((fv) => fv.featureid !== fc.id);
      }

      featureValues.push({ featureid: fc.id, exclude_value: values });
    });

    this.filtreRangeCaracteristique.forEach((fc) => {
      featureValues.push({
        featureid: fc.id,
        value_range: [fc.selectedMinValue, fc.selectedMaxValue],
      });
    });

    let marqueValues: string = '';
    this.filtreMarque.forEach((m) => {
      marqueValues += m.marque__marque + ',';
    });
    if (marqueValues !== '') {
      marqueValues = marqueValues.slice(0, -1);
    }

    let criteresProduits = null;
    if (this.sortedReference == null && this.sortedMarque == null) {
      criteresProduits = {
        // etimclass__artclassid: this.selectedClass.artclassid,
        etimfeature_value: JSON.stringify(featureValues),
        marque__marque: marqueValues,
        isStock: this.filtreEnStock ? 1 : 0,
      };
      if (this.selectedClass != null) {
        criteresProduits.etimclass__artclassid = this.selectedClass.artclassid;
      } else {
        criteresProduits.etimgroup__artgroupid = this.groupe.artgroupid;
      }
    } else {
      let sortedParam = null;
      if (this.sortedReference != null) {
        sortedParam = this.sortedReference === 'ASC' ? 'ref' : '-ref';
      } else {
        sortedParam = this.sortedMarque === 'ASC' ? 'marque' : '-marque';
      }

      criteresProduits = {
        // etimclass__artclassid: this.selectedClass.artclassid,
        etimfeature_value: JSON.stringify(featureValues),
        marque__marque: marqueValues,
        isStock: this.filtreEnStock ? 1 : 0,
        ordering: sortedParam,
      };
      if (this.selectedClass != null) {
        criteresProduits.etimclass__artclassid = this.selectedClass.artclassid;
      } else {
        criteresProduits.etimgroup__artgroupid = this.groupe.artgroupid;
      }
    }

    if (this.filtreTexte != null) {
      criteresProduits.query = this.filtreTexte;
    }

    let criteresFilterCount =
      'etimfeature_value=' + JSON.stringify(featureValues);
    if (marqueValues !== '') {
      criteresFilterCount += '&marque__marque=' + marqueValues;
    }
    if (this.filtreEnStock) {
      criteresFilterCount += '&isStock=1';
    }

    // console.log('Critères Produits = ' + JSON.stringify(criteresProduits));
    // console.log('Critères Filtres = ' + criteresFilterCount);

    this.produitService.listByCriteres(null, criteresProduits).subscribe({
      next: (value) => {
        this.produits = value;

        // Init des nombres de produits sur les filtres
        for (const item in this.filtersFeatureCount) {
          this.filtersFeatureCount[item] = 0;
        }

        const classid =
          this.selectedClass != null ? this.selectedClass.artclassid : null;
        this.produitService
          .filtersGlobalCount(
            this.groupe.artgroupid,
            classid,
            criteresFilterCount
          )
          .subscribe({
            next: (result) => {
              if (result != null) {
                result.etim_features.forEach((r) => {
                  this.filtersFeatureCount[r.featureid] = r.ref__count;
                });

                this.filtersValuesCount = result.etim_values;
                this.filtersMarqueCount = result.marque;
              }

              // this.contruireCaracteristiques();
            },
          });

        if (this.produits != null && this.produits.results != null) {
          this.produits.results.forEach((p) => {
            if (p.etimclass != null) {
              p.filAriane = Etimclassutil.getGroupeArianeFromClasse(
                p.etimclass.artclassid
              );
            }
          });
        }

        this.page = 1;
        this.filtresActifsView = this.filtresActifs;
        this.ngxSpinner.hide();
      },
      error: (err) => {
        this.error = err;
        this.ngxSpinner.hide();
      },
    });

    if (withMarque) {
      /*
      this.produitService.listMarquesForEtimClass(this.selectedClass.artclassid, criteresFilterCount).subscribe({
        next: value => {
          this.marques = value;
        },
        error: err => {
          this.error = err;
        }
      });*/
    }
  }

  public onPageChanged(event) {
    let url = '';

    this.ngxSpinner.show();

    if (event > this.page) {
      const nextPage = this.page + 1;
      url = this.produits.next.replace('page=' + nextPage, 'page=' + event);
    } else {
      const previousPage = this.page - 1;
      url = this.produits.previous.replace(
        'page=' + previousPage,
        'page=' + event
      );
    }

    this.produitService.listByCriteres(url).subscribe({
      next: (value) => {
        this.produits = value;

        if (this.produits != null && this.produits.results != null) {
          this.produits.results.forEach((p) => {
            p.filAriane = Etimclassutil.getGroupeArianeFromClasse(
              p.etimclass.artclassid
            );
          });
        }

        this.page = event;
        this.ngxSpinner.hide();
      },
      error: (err) => {
        this.error = err;
        this.ngxSpinner.hide();
      },
    });

    window.scrollTo(0, 0);
  }

  public changeViewType(viewType, viewCol) {
    this.viewType = viewType;
    this.viewCol = viewCol;
  }

  getFeatureDescription(feature: EtimFeature) {
    const langue = localStorage.getItem(GeneralEnum.STORAGE_LANGUAGE);
    let desc = feature.featuredesc.filter((d) => d.langue === langue);

    if (desc == null || desc.length === 0) {
      desc = feature.featuredesc.filter((d) => d.langue === 'en-GB');
    }
    if ((desc == null || desc.length === 0) && feature.featuredesc.length > 0) {
      desc = feature.featuredesc;
    }

    return desc[0].featuredesc;
  }

  getUnitDescription(unit: EtimUnit) {
    const langue = localStorage.getItem(GeneralEnum.STORAGE_LANGUAGE);
    let desc = unit.unitdesc.filter((d) => d.langue === langue);

    if (desc == null || desc.length === 0) {
      desc = unit.unitdesc.filter((d) => d.langue === 'en-GB');
    }
    if ((desc == null || desc.length === 0) && unit.unitdesc.length > 0) {
      desc = unit.unitdesc;
    }

    return desc[0].unitdesc;
  }

  getValueDescrption(value: EtimValue) {
    const langue = localStorage.getItem(GeneralEnum.STORAGE_LANGUAGE);
    let desc = value.valuedesc.filter((d) => d.langue === langue);

    if (desc == null || desc.length === 0) {
      desc = value.valuedesc.filter((d) => d.langue === 'en-GB');
    }
    if ((desc == null || desc.length === 0) && value.valuedesc.length > 0) {
      desc = value.valuedesc;
    }

    return desc[0].valuedesc;
  }

  contruireCaracteristiques() {
    this.caracteristiquesView = new Array<Caracteristique>();

    let filtre = null;
    if (this.selectedClass != null) {
      filtre = { artclass: this.selectedClass.artclassid };
    } else {
      filtre = { etimgroup: this.groupe.artgroupid };
    }

    // console.log('Filtre Cractéristiques : ' + JSON.stringify(filtre));

    this.featureService.list(filtre).subscribe({
      next: (feat) => {
        feat.results.forEach((c) => {
          const caract = new Caracteristique();
          caract.id = c.feature.featureid;
          caract.name = this.getFeatureDescription(c.feature);
          caract.type =
            c.featuretype === 'L'
              ? 'CHECK'
              : c.featuretype === 'R' || c.featuretype === 'N'
              ? 'RANGE'
              : 'LIST';

          if (caract.type === 'RANGE' && c.range_value != null) {
            caract.valeurMin = c.range_value.value__min;
            caract.valeurMax = c.range_value.value__max;

            if (c.unitofmeas != null) {
              caract.unite = this.getUnitDescription(c.unitofmeas);
            }

            this.minRangeModels[c.feature.featureid] = c.range_value.value__min;
            this.maxRangeModels[c.feature.featureid] = c.range_value.value__max;
          }

          if (caract.type === 'LIST' && c.featurevalues != null) {
            caract.valuesList = new Array<CaracteristiqueValue>();
            c.featurevalues.forEach((v) => {
              const caractValue = new CaracteristiqueValue();
              caractValue.id = v.value.valueid;
              caractValue.libelle = this.getValueDescrption(v.value);
              caract.valuesList.push(caractValue);
            });
          }

          if (caract.type === 'CHECK') {
            caract.value = null;
          }

          this.caracteristiquesView.push(caract);
        });
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  marqueCheckSelect(marque, checked) {
    marque.selected = checked;
    this.onMarqueChanged(marque);
  }

  inStockCheckSelect(checked) {
    this.filtreEnStock = checked;
    this.onFiltreStockChanged();
  }

  featureCheckSelect(feature, checked) {
    feature.value = checked;
    this.onFeatureChanged(feature);
  }

  featureListSelect(feature, id) {
    feature.value = id;
    this.onFeatureChanged(feature);
  }

  featureRange(feature, value, type, other) {
    if (type === 'MIN') {
      this.minRangeModels[feature.id] = value;
      this.maxRangeModels[feature.id] = other;

      if (this.minRangeModels[feature.id] > this.maxRangeModels[feature.id]) {
        this.maxRangeModels[feature.id] = this.minRangeModels[feature.id];
      }
      feature.selectedMinValue = String(value);
      feature.selectedMaxValue = String(other);
    } else {
      this.maxRangeModels[feature.id] = value;
      this.minRangeModels[feature.id] = other;

      if (this.maxRangeModels[feature.id] < this.minRangeModels[feature.id]) {
        this.minRangeModels[feature.id] = this.maxRangeModels[feature.id];
      }
      feature.selectedMaxValue = String(value);
      feature.selectedMinValue = String(other);
    }

    this.onFeatureChanged(feature);
  }

  featureRangeWithoutSlider($event, feature, type) {
    if (type === 'MIN') {
      this.minRangeModels[feature.id] = $event.target.value;

      if (this.minRangeModels[feature.id] > this.maxRangeModels[feature.id]) {
        this.maxRangeModels[feature.id] = this.minRangeModels[feature.id];
      }
      feature.selectedMinValue = String(this.minRangeModels[feature.id]);
      feature.selectedMaxValue = String(this.maxRangeModels[feature.id]);
    } else {
      this.maxRangeModels[feature.id] = $event.target.value;

      if (this.maxRangeModels[feature.id] < this.minRangeModels[feature.id]) {
        this.minRangeModels[feature.id] = this.maxRangeModels[feature.id];
      }
      feature.selectedMaxValue = String(this.maxRangeModels[feature.id]);
      feature.selectedMinValue = String(this.minRangeModels[feature.id]);
    }

    this.onFeatureChanged(feature);
  }

  featureRangeValueUpdate(feature, value, type) {
    if (type === 'MIN') {
      this.minRangeModels[feature.id] = value;
    } else {
      this.maxRangeModels[feature.id] = value;
    }
  }

  public getImagePrincipale(p: Produit, miniature: boolean) {
    let image = 'assets/images/product/materiel_default.jpg';

    if (p.documentations != null && p.documentations.length > 0) {
      let imgList = p.documentations.filter(
        (doc) => doc.typedoc.toUpperCase() === 'ISW'
      );

      if (imgList != null && imgList.length > 0) {
        imgList = imgList.sort((d1, d2) => d2.priorite - d1.priorite);
        image =
          environment.url_images +
          (miniature ? 'W50/' : 'W400/') +
          imgList[0].url;
      }
    }

    return image;
  }

  public getLibelleProduit(produit: Produit) {
    const langue = localStorage.getItem(GeneralEnum.STORAGE_LANGUAGE);
    let libelle = '';

    if (produit.designations != null && produit.designations.length > 0) {
      produit.designations.forEach((designation) => {
        if (designation.langue === langue) {
          libelle = designation.libellecourt;
        }
      });

      if (libelle === '') {
        produit.designations.forEach((designation) => {
          if (designation.langue === LangueEnum.ANGLAIS) {
            libelle = designation.libellecourt;
          }
        });
      }

      if (libelle === '') {
        libelle = produit.designations[0].libellecourt;
      }
    }

    return libelle;
  }

  public openProductDetailDialog(produit) {
    const dialogRef = this.dialog.open(ProductDetailsDialogComponent, {
      data: produit,
      panelClass: 'product-dialog',
    });
    dialogRef.afterClosed().subscribe((product) => {
      if (product) {
        this.router.navigate(['/products', product.id, product.name]);
      }
    });
  }

  public isEnStockLocal(produit: Produit) {
    if (
      produit != null &&
      produit.stocks != null &&
      produit.stocks.length > 0
    ) {
      const pStock = produit.stocks.filter((s) => s.quantite > 0);
      if (pStock != null && pStock.length > 0) {
        return true;
      }
    }

    return false;
  }

  public isEnStockGroupe(produit: Produit) {
    if (
      produit != null &&
      produit.stock_group != null &&
      produit.stock_group.length > 0
    ) {
      const pStock = produit.stock_group.filter((s) => s.quantite__sum > 0);
      if (pStock != null && pStock.length > 0) {
        return true;
      }
    }

    return false;
  }

  public isEnStock(produit: Produit) {
    return this.isEnStockLocal(produit) || this.isEnStockGroupe(produit);
  }

  public trierResultatsParReference() {
    this.sortedMarque = null;

    if (this.sortedReference == null || this.sortedReference === 'DESC') {
      this.sortedReference = 'ASC';
      this.updateProductList();
    } else {
      this.sortedReference = 'DESC';
      this.updateProductList();
    }
  }

  public trierResultatsParMarque() {
    this.sortedReference = null;

    if (this.sortedMarque == null || this.sortedMarque === 'DESC') {
      this.sortedMarque = 'ASC';
      this.updateProductList();
    } else {
      this.sortedMarque = 'DESC';
      this.updateProductList();
    }
  }

  public getMarquesTriees() {
    return this.marques.sort((m1, m2) => {
      if (
        m1.marque__marque.normalize('NFD').replace(/[\u0300-\u036f]/g, '') >
        m2.marque__marque.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      ) {
        return 1;
      }
      if (
        m1.marque__marque.normalize('NFD').replace(/[\u0300-\u036f]/g, '') <
        m2.marque__marque.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      ) {
        return -1;
      }
      return 0;
    });
  }

  public changeExpandedClass() {
    if (this.initScreenDone) {
      this.classExpanded = !this.classExpanded;
    }
  }

  public goToCategorieSearch(produit: Produit) {
    const hierarchie = Etimclassutil.getHierarchieIds(
      produit.etimclass.artclassid
    );
    this.router.navigate(['/catalogue/category/search'], {
      queryParams: {
        type: 'groupe',
        id: hierarchie.groupe,
        idFamille: hierarchie.famille,
        idClass: hierarchie.classe,
      },
    });
  }

  public goToFamille(familleid: string) {
    this.router.navigate(['/catalogue/groups/search'], {
      queryParams: { type: 'famille', id: familleid },
    });
  }

  public goToGroupe(familleid: string, groupeid: string) {
    this.router.navigate(['/catalogue/category/search'], {
      queryParams: { type: 'groupe', id: groupeid, idFamille: familleid },
    });
  }

  public goToClasse(familleid: string, groupeid: string, classeid: string) {
    this.router.navigate(['/catalogue/category/search'], {
      queryParams: {
        type: 'groupe',
        id: groupeid,
        idFamille: familleid,
        idClass: classeid,
      },
    });
  }

  public getMarqueCount(marque: SearchMarque) {
    if (this.filtersMarqueCount != null && this.filtersMarqueCount.length > 0) {
      const filter = this.filtersMarqueCount.filter(
        (m) => m.marque__marque === marque.marque__marque
      );

      if (filter != null && filter.length > 0) {
        return filter[0].ref__count;
      }
    } else if (marque.ref__count !== null) {
      return marque.ref__count;
    }

    return null;
  }

  public getValueCount(value: CaracteristiqueValue, feature: string) {
    if (this.filtersValuesCount != null && this.filtersValuesCount.length > 0) {
      const filter = this.filtersValuesCount.filter(
        (v) => v.value === value.id && v.featureid === feature
      );

      if (filter != null && filter.length > 0) {
        return filter[0].ref__count;
      }
    }

    return 0;
  }

  public getBooleanValueCount(value: string, feature: string) {
    if (this.filtersValuesCount != null && this.filtersValuesCount.length > 0) {
      const filter = this.filtersValuesCount.filter(
        (v) => v.value === value && v.featureid === feature
      );

      if (filter != null && filter.length > 0) {
        return filter[0].ref__count;
      }
    }

    return 0;
  }

  public getLibelleCodeArticle(produit: Produit) {
    const infosClient = JSON.parse(
      localStorage.getItem(GeneralEnum.STORAGE_CUSTOMER)
    );
    let libelle = null;

    if (produit.codeArticle != null && produit.codeArticle.length > 0) {
      produit.codeArticle.forEach((c) => {
        const tuple = c
          .replace('(', '')
          .replace(')', '')
          .replace("'", '')
          .split(',');

        if (+tuple[0] === infosClient.id) {
          if (libelle == null) {
            libelle = tuple[1].replace("'", '');
          } else {
            libelle += ' - ' + tuple[1].replace("'", '');
          }
        }
      });
    }

    return libelle;
  }

  public onSubmitFilter() {
    if (this.filtreTexte === '') {
      this.filtreTexte = null;
    }

    this.filtresActifs = this.filtresActifs.filter((f) => f.id !== 'TEXTE');

    if (this.filtreTexte != null) {
      this.filtresActifs.push({
        id: 'TEXTE',
        title: this.translate.instant('categories-search.search'),
        type: 'TEXTE',
        value: this.filtreTexte,
      });
    }

    this.updateProductList();
  }
}
