import { Component, OnInit, Input } from '@angular/core';
import {AuthenticationService} from '../../services/authentication.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {GeneralEnum} from '../../enums/general.enum';
import {FamilleService} from '../../services/famille.service';
import {ProduitService} from '../../services/produit.service';
import {BasketService} from '../../services/basket.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';

  constructor(private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private authent: AuthenticationService,
              private familleService: FamilleService,
              private produitService: ProduitService,
              private basketService: BasketService) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  get f() { return this.loginForm.controls; }

  async onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    await this.authent.login(this.f.username.value, this.f.password.value).then(u => {
      const user = JSON.parse(localStorage.getItem(GeneralEnum.STORAGE_USER));

      if (user.profile.pwd_expired) {
        this.router.navigate(['/expired-password']);
      } else if (user.profile.pwd_expire_in != null && user.profile.pwd_expire_in < 30) {
        this.router.navigate(['/almost-expired-password'], {queryParams: {returnUrl: this.returnUrl}});
      } else {
        // Récupération de la liste des familles
        this.familleService.list().subscribe({
          next: result => {
            // const sortedFamilles = result.results.sort((f1, f2) => f1.description > f2.description ? 1 : (f1.description < f2.description ? -1 : 0));
            localStorage.setItem(GeneralEnum.STORAGE_FAMILLES, JSON.stringify(this.familleService.trierFamilles(result.results)));
            this.router.navigate([this.returnUrl]);
            this.loading = false;
          },
          error: err => {
            this.error = err;
            this.loading = false;
          }
        });

        // Récupération du comptage de produits par classe
        this.produitService.filtersClassCount().subscribe({
          next: result => {
            localStorage.setItem(GeneralEnum.STORAGE_FILTERS_CLASS_COUNT, JSON.stringify(result));
            this.loading = false;
          },
          error: err => {
            this.error = err;
            this.loading = false;
          }
        });

        // Récupération du panier temporaire
        this.basketService.reloadBasketInContext(user.customer_selected);
      }
    }).catch((err) => {
      this.error = err;
      this.loading = false;
    });
  }
}
