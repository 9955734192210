import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ProductDetailsComponent } from '../products/product-details/product-details.component';
import { ProductSearchComponent } from './product-search/product-search.component';
import { ProductSearchAutoComponent } from './product-search-auto/product-search-auto.component';
import { CategoriesSearchComponent } from './categories-search/categories-search.component';
import { HomeGroupsComponent } from './home-groups/home-groups.component';

// Routes
const routes: Routes = [
  { path: 'category/search', component: CategoriesSearchComponent },
  { path: 'default', component: HomeComponent },
  { path: 'groups/search', component: HomeGroupsComponent },
  { path: 'product/search', component: ProductSearchComponent },
  {
    path: 'product/search-autocomplete',
    component: ProductSearchAutoComponent,
  },
  { path: 'product/:id', component: ProductDetailsComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CatalogueRoutingModule {}
