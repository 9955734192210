<div class="my-account sec-padding">
  <div class="login-header">
    <img src="assets/images/etn-logo-groupe.png">
  </div>
  <div class="login-container">
    <mat-card>
        <div class="my-account-wrap p-0">
          <div class="login-wrapper" fxFlex="100" fxFlex.md="100">
            <div class="title">
              <h3 class="text-center">{{ 'login.title'|translate }}</h3>
            </div>
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
              <div class="form-group">
                <mat-form-field class="w-200">
                  <input matInput placeholder="{{ 'login.id'|translate }}" class="form-control" formControlName="username" [ngClass]="{ 'is-invalid': submitted && f.username.errors }">
                  <mat-error *ngIf="submitted && f.username.errors">{{ 'login.errorId'|translate }}</mat-error>
                </mat-form-field>
              </div>
              <div class="form-group">
                <mat-form-field class="w-200">
                  <input type="password" matInput placeholder="{{ 'login.password'|translate }}" class="form-control" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                  <mat-error *ngIf="submitted && f.password.errors">{{ 'login.errorPassword'|translate }}</mat-error>
                </mat-form-field>
              </div>
              <div class="form-group text-center">
                <button [disabled]="loading" mat-raised-button class="btn-project" color="primary">
                  <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                  {{ 'login.btConnect'|translate }}
                </button>
              </div>
              <mat-error *ngIf="error">{{error}}</mat-error>
            </form>
            <div class="forgot-link">
              <a [routerLink]="['/forgot-password']">{{ 'login.forgot'|translate }}</a>
            </div>
          </div>
        </div>
    </mat-card>

  </div>
</div>

