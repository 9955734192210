<!--
  <mat-toolbar-row fxHide fxShow.gt-sm class="container" fxLayout="row"  fxLayout.xs="row" fxLayoutAlign="space-between center">
    <div class="main-menu" fxLayout="row" fxHide fxShow.gt-sm>
        <a [routerLink]="['/catalogue/default']" routerLinkActive="router-link-active" mat-button class="mat-button">{{ 'menu.home'|translate }}</a>
        <a [routerLink]="['/catalogue/product/search']" routerLinkActive="router-link-active"  mat-button class="mat-button">{{ 'menu.searchProducts'|translate }}</a>
        <a  mat-button [matMenuTriggerFor]="shop" class="mat-button" (click)="openMegaMenu()">{{ 'menu.categories'|translate }}<mat-icon class="caret">arrow_drop_down</mat-icon></a>
        <mat-menu #shop="matMenu" [overlapTrigger]="false" class="app-dropdown mega-menu">
            <div fxLayout="row wrap">
                <div *ngFor="let famille of familles" fxFlex="33" fxLayout="column" class="p-1">
                    <a mat-menu-item [routerLink]="['/catalogue/groups/search']" [queryParams]="{type: 'famille', id: famille.artfamilleid}"><b>{{getFamilleDescription(famille)}}</b></a>
                    <mat-divider></mat-divider>
                    <a *ngFor="let groupe of famille.groups" mat-menu-item [routerLink]="['/catalogue/category/search']" [queryParams]="{type: 'groupe', id: groupe.artgroupid, idFamille: famille.artfamilleid}">{{getGroupeDescription(groupe)}}</a>
                </div>
            </div>

        </mat-menu>
        <a [routerLink]="['/pages/selection-list']" routerLinkActive="router-link-active" mat-button class="mat-button">{{ 'menu.savedSelections'|translate }}</a>
        <a [routerLink]="['/pages/commande-list']" routerLinkActive="router-link-active" mat-button class="mat-button">{{ 'menu.orders'|translate }}</a>
        <a  mat-button [matMenuTriggerFor]="pages" class="mat-button">{{ 'menu.pages'|translate }}<mat-icon class="caret">arrow_drop_down</mat-icon></a>
        <mat-menu #pages="matMenu" [overlapTrigger]="false" class="app-dropdown">
          <a mat-menu-item [routerLink]="['/pages/catalogues']" >{{ 'menu.etnCatalogs'|translate }}</a>
          <a mat-menu-item [routerLink]="['/pages/liens']">{{ 'menu.links'|translate }}</a>
          <a mat-menu-item [routerLink]="['/pages/help']">{{ 'menu.help'|translate }}</a>
        </mat-menu>
        <a [routerLink]="['/pages/contact']" routerLinkActive="router-link-active"   mat-button class="mat-button">{{ 'menu.contact'|translate }}</a>
      </div>
  </mat-toolbar-row>

-->



<mat-toolbar-row fxHide fxShow.gt-sm class="container" fxLayout="row"  fxLayout.xs="row" fxLayoutAlign="space-between center">
  <div class="main-menu" fxLayout="row" fxHide fxShow.gt-sm>
    <a [routerLink]="['/catalogue/default']" routerLinkActive="router-link-active" mat-button class="mat-button">{{ 'menu.home'|translate }}</a>

    <a mat-button [matMenuTriggerFor]="shop" class="mat-button">{{ 'menu.categories'|translate }}<mat-icon class="caret">arrow_drop_down</mat-icon></a>
    <mat-menu #shop="matMenu" [overlapTrigger]="false" class="app-dropdown mega-menu">
      <ul class="dropdown">
        <li *ngFor="let famille of getFamillesTriees()"><a [routerLink]="['/catalogue/groups/search']" [queryParams]="{type: 'famille', id: famille.artfamilleid}">{{getFamilleDescription(famille)}}<mat-icon class="caret">arrow_right</mat-icon></a>
          <ul>
            <li *ngFor="let groupe of getGroupesTries(famille.groups)"><a [routerLink]="['/catalogue/category/search']" [queryParams]="{type: 'groupe', id: groupe.artgroupid, idFamille: famille.artfamilleid}">{{getGroupeDescription(groupe)}}</a></li>
          </ul>
        </li>
      </ul>
    </mat-menu>

    <a [routerLink]="['/catalogue/product/search']" routerLinkActive="router-link-active"  mat-button class="mat-button">{{ 'menu.searchProducts'|translate }}</a>
    <a [routerLink]="['/pages/selection-list']" routerLinkActive="router-link-active" mat-button class="mat-button">{{ 'menu.savedSelections'|translate }}</a>
    <a [routerLink]="['/pages/ddp-list']" routerLinkActive="router-link-active" mat-button class="mat-button">{{ 'menu.ddpOffres'|translate }}</a>
    <a *ngIf="menuCommandesActive" [routerLink]="['/pages/commande-list']" routerLinkActive="router-link-active" mat-button class="mat-button">{{ 'menu.orders'|translate }}</a>
    <a href="https://etn.fr/documentation/" target="_blank" mat-button class="mat-button">{{ 'menu.etnCatalogs'|translate }}</a>

    <!-- <a [routerLink]="['/pages/catalogues']" routerLinkActive="router-link-active" mat-button class="mat-button">{{ 'menu.etnCatalogs'|translate }}</a> -->

    <!--
    <a  mat-button [matMenuTriggerFor]="pages" class="mat-button">{{ 'menu.pages'|translate }}<mat-icon class="caret">arrow_drop_down</mat-icon></a>
    <mat-menu #pages="matMenu" [overlapTrigger]="false" class="app-dropdown">
      <a mat-menu-item [routerLink]="['/pages/catalogues']" >{{ 'menu.etnCatalogs'|translate }}</a>
      <a mat-menu-item [routerLink]="['/pages/liens']">{{ 'menu.links'|translate }}</a>
      <a mat-menu-item [routerLink]="['/pages/help']">{{ 'menu.help'|translate }}</a>
    </mat-menu>-->
    <a [routerLink]="['/pages/contact']" routerLinkActive="router-link-active"   mat-button class="mat-button">{{ 'menu.contact'|translate }}</a>
    <a *ngIf="menuHelpActive" href="{{ getHelpLink() }}" target="_blank" mat-button class="mat-button">{{ 'menu.help'|translate }}</a>
  </div>
</mat-toolbar-row>

