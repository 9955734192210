import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxSpinnerModule } from 'ngx-spinner';
import { CatalogueModule } from './components/catalogue/catalogue.module';
import { SharedModule } from './components/shared/shared.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent, createTranslateLoader } from './app.component';
import { MainComponent } from './components/main/main.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CookieService } from 'ngx-cookie-service';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { LoginComponent } from './components/login/login.component';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TypeaheadConfig, TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { InitPasswordComponent } from './components/security/initPassword/initPassword.component';
import { ConfirmRequestComponent } from './components/security/confirmRequest/confirmRequest.component';
import { ForgotPasswordComponent } from './components/security/forgotPassword/forgotPassword.component';
import {
  SWIPER_CONFIG,
  SwiperConfigInterface,
  SwiperModule,
} from 'ngx-swiper-wrapper';
import { empty, Observable } from 'rxjs';
import { ComponentLoaderFactory } from 'ngx-bootstrap/component-loader';
import { PositioningService } from 'ngx-bootstrap/positioning';
import { PunchOutComponent } from './components/security/punchOut/punchOut.component';
import { ExpiredPasswordComponent } from './components/security/expiredPassword/expiredPassword.component';
import { AlmostExpiredPasswordComponent } from './components/security/almostExpiredPassword/almostExpiredPassword.component';

registerLocaleData(localeFr, 'fr');

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',
};

const emptyPositioningService = {
  setOptions(i: any) {},
  position(o: any) {},
  addPositionElement(o: any) {},
  get event$(): Observable<number | Event> {
    return empty();
  },
  calcPosition() {},
  deletePositionElement(e: any) {},
  enable() {},
  disable() {},
};

const typeaheadModule = {
  ngModule: TypeaheadModule,
  providers: [
    ComponentLoaderFactory,
    { provide: PositioningService, useValue: emptyPositioningService },
    TypeaheadConfig,
  ],
};

/*
export function storageFactory(): OAuthStorage {
  return sessionStorage;
}*/

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    InitPasswordComponent,
    ExpiredPasswordComponent,
    AlmostExpiredPasswordComponent,
    ConfirmRequestComponent,
    PunchOutComponent,
    MainComponent,
  ],
  imports: [
    NgxSpinnerModule,
    SwiperModule,
    ReactiveFormsModule,
    CatalogueModule,
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    OAuthModule.forRoot(),
    typeaheadModule,
    NgbModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: SWIPER_CONFIG, useValue: DEFAULT_SWIPER_CONFIG },
    // {provide: OAuthStorage, useFactory: storageFactory}
  ],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
