<div class="container min-container">
    <div class="details-wrapper sec-padding" >
       <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="80">
          <div fxLayout="row wrap" fxFlex="100" fxFlex.gt-md="100" fxFlex.gt-sm="100">
              <div fxFlex="100" fxFlex.gt-md="50" fxFlex.md="45">
                  <!-- <mat-card class="product-image">
                     <div *ngFor="let image of product.pictures">
                        <ngx-image-zoom   [thumbImage]="image" [fullImage]="image" zoomMode="hover"
                    ></ngx-image-zoom>
                     </div>
                  </mat-card> -->
                <mat-card class="product-image">
                  <div style="text-align: center;">
                    <button mat-icon-button  fxHide="false" fxHide.gt-md>
                      <mat-icon>fullscreen</mat-icon>
                    </button>
                    <img [src]="images[imageIndex]?.full"/>
                  </div>
                  <a (click)="openProductDialog(produit, imageIndex)" style="cursor: pointer;"><mat-icon>zoom_in</mat-icon></a>
                </mat-card>
                <div *ngIf="images.length > 1" class="small-carousel">
                  <div class="swiper-container" [swiper]="config">
                    <div class="swiper-wrapper">
                      <div *ngFor="let img of images; let i=index" class="swiper-slide">
                        <mat-card (click)="selectImage(i)" class="p-1">
                          <img [src]="img.mini" class="swiper-lazy"/>
                          <div class="swiper-lazy-preloader"></div>
                        </mat-card>
                      </div>
                    </div>
                    <button mat-icon-button class="swiper-button-prev swipe-arrow"><mat-icon>keyboard_arrow_left</mat-icon></button>
                    <button mat-icon-button class="swiper-button-next swipe-arrow"><mat-icon>keyboard_arrow_right</mat-icon></button>
                  </div>
                </div>
               </div>
               <div fxFlex="100" fxFlex.gt-md="50" fxFlex.md="55" ngClass.gt-sm="px-3 m-0" ngClass.sm="mt-2" ngClass.xs="mt-2">

                  <div class="product-right">
                     <div class="product-details">
                        <h2>{{getRefProduit()}}</h2>
                        <!--<h4 *ngIf="!produitService?.catalogMode"><del>{{produit.salePrice | currency:produitService?.currency:'symbol'}}</del><span>{{produit.discount}}% off</span></h4>
                        <h3 class="price" *ngIf="!produitService?.catalogMode">{{produit.price | currency:produitService?.currency:'symbol'}}</h3>
                        <p class="text-muted description">{{produit.description}}</p>-->
                       <h3 class="price" *ngIf="produit != null">{{produit.marque.marque}}</h3>
                       <h3 class="price" *ngIf="prices != null && prices.length > 0; else noPrices">
                         {{ 'product-details.price'|translate }} : {{getLibellePrix()}}
                       </h3>
                       <ng-template #noPrices>
                         <h3 class="price">{{ 'product-details.priceConsult'|translate }}</h3>
                       </ng-template>
                       <p class="text-muted description">{{getLibelleCourtProduit()}}</p>
                       <p class="text-muted description">{{getLibelleLongProduit()}}</p>
                     </div>
                     <div class="py-1 mt-15">
                        <p style="margin-bottom: 0.2rem;">
                           <span class="text-muted fw-500">{{ 'product-details.availability'|translate }} : </span>
                           <span class="avalibility"><span>{{getStockGlobal()}}</span></span>
                           <!--<span class="avalibility" *ngIf="counter > produit.stock"><span>Out of Stock</span></span>-->
                        </p>
                       <p>
                     </div>
                    <div class="quantity">
                      <div fxLayout="row" fxLayout.xs="column"  class="text-muted">
                        <div fxLayout="row" fxLayoutAlign="center start">
                          <span class="text-muted fw-500" style="margin: auto;">{{ 'product-details.quantity'|translate }} :</span>
                          <button mat-icon-button matTooltip="Remove" (click)="decrement()">
                            <mat-icon>remove</mat-icon>
                          </button>
                          <input type="text" name="quantity" value="{{counter}}" class="form-control input-number" (change)="modifierQuantite($event)">
                          <button mat-icon-button matTooltip="Add" (click)="increment()">
                            <mat-icon>add</mat-icon>
                          </button>
                        </div>
                      </div>
                      <div *ngIf="inputError" fxLayout="row" fxLayout.xs="column"  class="text-muted">
                        <div fxLayout="row" fxLayoutAlign="center start" class="text-danger">
                          <span>{{inputError}}</span>
                        </div>
                      </div>
                    </div>
                     <div class="buttons mt-20">
                        <button mat-raised-button color="primary" class="btn-project mr-10" (click)="addToSelection(produit, counter)">{{ 'product-details.addBasket'|translate }}</button>
                     </div>
                  </div>
               </div>
          </div>

          <div class="review-wrapper" fxFlex="100"  fxFlex.md="75" style="padding-right: 0;">
            <mat-card>
               <mat-tab-group [@.disabled]="true" [selectedIndex]="0">
               <mat-tab label="Caractéristiques">
                 <div *ngIf="features != null && features.length > 0; else noCaract" class="full-desc lh" style="overflow: auto;">
                   <p class="text-muted">
                     <span class="titre-caracteristique">{{ 'product-details.category'|translate }} : </span>
                     <span *ngIf="categorie != null; else noCategorie">{{getCategorieDescription()}}</span>
                     <ng-template #noCategorie>
                       {{ 'product-details.notCategorized'|translate }}
                     </ng-template>
                   </p>
                   <p *ngFor="let desc of descriptif" class="text-muted">
                     <span class="titre-caracteristique">{{desc.titre}} : </span> <span>{{desc.value}}</span>
                   </p>
                 </div>
                 <ng-template #noCaract>
                   <div class="full-desc lh">
                     <p class="text-muted">
                       <span class="titre-caracteristique">{{ 'product-details.features'|translate }} : </span> {{ 'product-details.noFeatures'|translate }}
                     </p>
                   </div>
                 </ng-template>
               </mat-tab>
               <mat-tab label="Documentations">
                  <div *ngIf="produit != null && produit.documentations != null && getDocumentations().length > 0; else noDoc" class="full-desc lh">
                   <mat-list class="commentaires">
                     <mat-list-item *ngFor="let d of getDocumentations(); let index = index" class="content">
                       <!--<a href="{{d.url}}" target="_blank"><img matListAvatar [src]="'assets/images/product/download.png'" alt="image" class="review-author"></a>-->
                       <p matLine fxLayoutAlign="start center">
                         <a href="{{d.url}}" target="_blank"><span class="name">{{d.docname}} ({{d.langue.substring(0,2)}})</span></a>
                       </p>
                     </mat-list-item>
                   </mat-list>
                 </div>
                 <ng-template #noDoc>
                   <div class="full-desc lh">
                     <p class="text-muted">{{ 'product-details.noDocumentation'|translate }}</p>
                   </div>
                 </ng-template>
               </mat-tab>
               </mat-tab-group>
            </mat-card>
         </div>
       </div>

      <!--
       <div fxLayout="row wrap" fxHide fxShow.gt-sm fxFlex="100" fxFlex.gt-sm="22">
        <div fxFlex="100"  >
          <div fxLayout="row wrap" class="info-bar" ngClass.gt-md="m-0">
             <div fxFlex="100" >
                <mat-card class="light-block" fxLayout="row"  fxLayoutAlign="start center">
                   <mat-icon class="mat-icon-xlg  m-0">card_giftcard</mat-icon>
                   <div class="content ml-10">
                      <p>BONUS PLUS</p>
                      <span class="text-muted m-0">Get a bonus plus for buying more that three products</span>
                   </div>
                </mat-card>
             </div>
             <div fxFlex="100" >
                <mat-card class="light-block" fxLayout="row"  fxLayoutAlign="start center">
                   <mat-icon class="mat-icon-xlg  m-0">local_shipping</mat-icon>
                   <div class="content ml-10">
                      <p>FREE SHIPPING</p>
                      <span class="text-muted m-0">Free shipping on all orders over $99</span>
                   </div>
                </mat-card>
             </div>
             <div fxFlex="100"  class="mt-16">
                <mat-card class="light-block" fxLayout="row"  fxLayoutAlign="start center">
                   <mat-icon class="mat-icon-xlg m-0">monetization_on</mat-icon>
                   <div class="content ml-10">
                      <p>MONEY BACK GUARANTEE</p>
                      <span class="text-muted m-0">100% money back guarantee</span>
                   </div>
                </mat-card>
             </div>
             <div fxFlex="100"  class="mt-16">
                <mat-card class="light-block no-border" fxLayout="row"  fxLayoutAlign="start center">
                   <mat-icon class="mat-icon-xlg m-0">history</mat-icon>
                   <div class="content ml-10">
                      <p>SUPPORT 24/7</p>
                      <span class="text-muted m-0">Appelez-nous: 02.31.35.62.00</span>
                   </div>
                </mat-card>
             </div>

          </div>

       </div>
       </div>
       -->

    </div>
</div>
