import {Component, OnInit, Input, Inject} from '@angular/core';
import { Router } from '@angular/router';
import {Produit} from '../../../models/produit';
import {ProduitService} from '../../../services/produit.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ErreurProduit} from '../../../models/erreurProduit';

@Component({
  selector: 'app-signaler-erreur',
  templateUrl: './signalerErreur.component.html',
  styleUrls: ['./signalerErreur.component.sass']
})
export class SignalerErreurComponent implements OnInit {

  erreurForm: FormGroup;
  loading = false;
  submitted = false;
  error = '';

  constructor(private produitService: ProduitService,
              private formBuilder: FormBuilder,
              public dialogRef: MatDialogRef<SignalerErreurComponent>,
              @Inject(MAT_DIALOG_DATA) public produit: Produit,
              private router: Router ) { }

  ngOnInit() {
    this.erreurForm = this.formBuilder.group({
      motif: ['CAT'],
      commentaire: ['', Validators.required]
    });
  }

  get f() {
    return this.erreurForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.erreurForm.invalid) {
      return;
    }

    this.loading = true;

    const signalement: ErreurProduit = {
      type_issue: this.f.motif.value,
      ref: this.produit.ref,
      comment: this.f.commentaire.value
    };

    this.produitService.signalerErreurProduit(signalement).subscribe({
      next: async (result) => {
        await this.dialogRef.close(true);
      },
      error: err => console.log(err)
    });
  }

  public close() {
    this.dialogRef.close(false);
  }

  public envoyer() {

  }
}
