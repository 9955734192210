<div class="product-dialog">
  <div class="close-btn-outer">
    <button mat-mini-fab color="warn" (click)="close()"><mat-icon>close</mat-icon></button>
  </div>
  <div mat-dialog-content>
    <div fxLayout="row wrap" >
      <div fxFlex="100" fxFlex.gt-sm="100" ngClass.gt-sm="px-2 m-0" ngClass.sm="mt-2" ngClass.xs="mt-2">
        <div class="product-right">
          <div class="" fxLayout="row" fxLayoutAlign="space-between center" >
            <div class="mt-15">
              <p class="text-danger">{{ 'confirm-delete-selection.delete'|translate }} {{item.produit.ref}} ?</p>
            </div>
          </div>
          <div class="" fxLayout="row" fxLayoutAlign="space-between center" >
            <div class="mt-15">
              <div class="text-center">
                <button mat-raised-button class="btn-project" color="primary" (click)="close()">
                  {{ 'confirm-delete-selection.cancel'|translate }}
                </button>&nbsp;
                <button mat-raised-button class="btn-project" color="warn" (click)="removeItem()">
                  {{ 'confirm-delete-selection.confirm'|translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
