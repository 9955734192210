import { Component, OnInit } from '@angular/core';
import { GeneralEnum } from '../../../enums/general.enum';
import { Famille, FamilleGroup } from '../../../models/famille';
import { LangueEnum } from '../../../enums/langue.enum';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.sass'],
})
export class MenuComponent implements OnInit {
  public familles: Array<Famille> = new Array<Famille>();
  error: string;
  menuCommandesActive = true;
  menuHelpActive = false;

  constructor() {}

  ngOnInit() {
    const user = JSON.parse(localStorage.getItem(GeneralEnum.STORAGE_USER));
    user.groups.forEach((g) => {
      if (g.name === 'PunchOut') {
        this.menuCommandesActive = false;
      }
    });

    this.familles = JSON.parse(
      localStorage.getItem(GeneralEnum.STORAGE_FAMILLES)
    );

    const langue = localStorage.getItem(GeneralEnum.STORAGE_LANGUAGE);

    if (langue === LangueEnum.FRANCAIS || langue === LangueEnum.ANGLAIS) {
      this.menuHelpActive = true;
    }
  }

  openMegaMenu() {
    const pane = document.getElementsByClassName('cdk-overlay-pane');
    [].forEach.call(pane, (el) => {
      if (el.children.length > 0) {
        if (el.children[0].classList.contains('mega-menu')) {
          el.classList.add('mega-menu-pane');
        }
      }
    });
  }

  public getFamilleDescription(famille: Famille) {
    if (famille != null) {
      const langue = localStorage.getItem(GeneralEnum.STORAGE_LANGUAGE);
      let desc = famille.descriptions.filter((d) => d.langue === langue);

      if (desc == null || desc.length === 0) {
        desc = famille.descriptions.filter((d) => d.langue === 'en-GB');
      }
      if (
        (desc == null || desc.length === 0) &&
        famille.descriptions.length > 0
      ) {
        desc = famille.descriptions;
      }

      return desc[0].artfamilledesc;
    } else {
      return '';
    }
  }

  public getGroupeDescription(group: FamilleGroup) {
    const langue = localStorage.getItem(GeneralEnum.STORAGE_LANGUAGE);
    let desc = group.groupdesc.filter((d) => d.langue === langue);

    if (desc == null || desc.length === 0) {
      desc = group.groupdesc.filter((d) => d.langue === LangueEnum.ANGLAIS);
    }
    if ((desc == null || desc.length === 0) && group.groupdesc.length > 0) {
      desc = group.groupdesc;
    }

    return desc[0].groupdesc;
  }

  public getFamillesTriees() {
    return this.familles
      .filter((f) => f != null)
      .sort((f1, f2) => {
        const fd1 = this.getFamilleDescription(f1);
        const fd2 = this.getFamilleDescription(f2);

        if (
          fd1.normalize('NFD').replace(/[\u0300-\u036f]/g, '') >
          fd2.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        ) {
          return 1;
        }
        if (
          fd1.normalize('NFD').replace(/[\u0300-\u036f]/g, '') <
          fd2.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        ) {
          return -1;
        }
        return 0;
      });
  }

  public getGroupesTries(groups) {
    return groups.sort((g1, g2) => {
      const gd1 = this.getGroupeDescription(g1);
      const gd2 = this.getGroupeDescription(g2);

      if (
        gd1.normalize('NFD').replace(/[\u0300-\u036f]/g, '') >
        gd2.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      ) {
        return 1;
      }
      if (
        gd1.normalize('NFD').replace(/[\u0300-\u036f]/g, '') <
        gd2.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      ) {
        return -1;
      }
      return 0;
    });
  }

  public getHelpLink() {
    const langue = localStorage.getItem(GeneralEnum.STORAGE_LANGUAGE);

    if (this.isUserPunchout()) {
      switch (langue) {
        case LangueEnum.FRANCAIS: {
          return environment.help_fr_punchout;
        }
        default: {
          return environment.help_en_punchout;
        }
      }
    } else {
      switch (langue) {
        case LangueEnum.FRANCAIS: {
          return environment.help_fr_client;
        }
        default: {
          return environment.help_en_client;
        }
      }
    }
  }

  isUserPunchout(): boolean {
    let isPunchout = false;
    const user = JSON.parse(localStorage.getItem(GeneralEnum.STORAGE_USER));
    user.groups.forEach((g) => {
      if (g.name === 'PunchOut') {
        isPunchout = true;
      }
    });

    return isPunchout;
  }
}
