<div class="contact-page sec-padding">
  <div class="container">
    <mat-card fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start start" class="p-0" fxFill="100">
      <div class="billing-details pr-15" fxFlex="100" fxFlex.gt-md="100" fxFlex.md="100" [style.margin]="'15px'">
        <div class="header-title">
          <h2>{{ 'product-search.advancedSearch'|translate }}</h2>
        </div>
        <form fxLayout="row wrap" [formGroup]="searchForm" (ngSubmit)="onSubmit()">
          <div class="form-group pl-5 mt-20" fxFlex="50" fxFlex.gt-md="50" fxFlex.md="50" ngClass.sm="mt-10" ngClass.xs="mt-10">
            <mat-form-field class="w-100">
              <input matInput placeholder="{{ 'product-search.productCode'|translate }}" formControlName="referenceClient" name="referenceClient" [ngClass]="{ 'is-invalid': submitted && f.referenceClient.errors }">
              <mat-error *ngIf="submitted && f.referenceClient.errors">{{ 'product-search.productCodeError'|translate }}</mat-error>
            </mat-form-field>
          </div>
          <div class="form-group pl-5 mt-20" fxFlex="50" fxFlex.gt-md="50" fxFlex.md="50" ngClass.sm="mt-10" ngClass.xs="mt-10">
            <mat-form-field class="w-100">
              <input matInput placeholder="{{ 'product-search.brand'|translate }}" formControlName="marque" name="marque" [ngClass]="{ 'is-invalid': submitted && f.marque.errors }">
              <mat-error *ngIf="submitted && f.marque.errors">{{ 'product-search.brandError'|translate }}</mat-error>
            </mat-form-field>
          </div>
          <div class="form-group pl-5 mt-20" fxFlex="50" fxFlex.gt-md="50" fxFlex.md="50" ngClass.sm="mt-10" ngClass.xs="mt-10">
            <mat-form-field class="w-100">
              <input matInput placeholder="{{ 'product-search.name'|translate }}" formControlName="designation" name="designation" [ngClass]="{ 'is-invalid': submitted && f.designation.errors }">
              <mat-error *ngIf="submitted && f.designation.errors">{{ 'product-search.nameError'|translate }}</mat-error>
            </mat-form-field>
          </div>
          <div class="form-group pl-5 mt-20" fxFlex="50" fxFlex.gt-md="50" fxFlex.md="50" ngClass.sm="mt-10" ngClass.xs="mt-10">
            <mat-form-field class="w-100">
              <input matInput placeholder="{{ 'product-search.articleCode'|translate }}" formControlName="codeArticle" name="codeArticle" [ngClass]="{ 'is-invalid': submitted && f.codeArticle.errors }">
              <mat-error *ngIf="submitted && f.codeArticle.errors">{{ 'product-search.articleCodeError'|translate }}</mat-error>
            </mat-form-field>
          </div>
          <div class="form-group mt-20 pr-5" fxFlex="50" fxFlex.gt-md="50" fxFlex.md="50" ngClass.sm="mt-10" ngClass.xs="mt-10">
            <div class="w-100" style="margin-left: 45px; margin-top: 25px;">
              <mat-checkbox class="mat-checkbox" formControlName="inStock" name="inStock">{{ 'product-search.onlyInStock'|translate }}</mat-checkbox>
            </div>
          </div>
          <div class="button-wrap text-center px-1" fxFlex="100" fxFlex.gt-sm="100" ngClass.sm="mt-1" ngClass.xs="mt-1">
            <button class="btn-project" [disabled]="loading" mat-raised-button  color="primary" type="submit">
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
              {{ 'product-search.search'|translate }}
            </button>
          </div>
          <mat-error *ngIf="error">{{error}}</mat-error>
        </form>
      </div>
    </mat-card>
  </div>
</div>

<div *ngIf="produits != null && produits.results != null && produits.results.length > 0" class="cart-wrapper sec-padding">
  <div class="container">
    <span class="titre-resultats-recherche">{{ 'product-search.searchResult'|translate }}</span>&nbsp;<span class="nombre-resultat-recherche">{{produits.count}} {{ 'product-search.results'|translate }}</span>
    <mat-card *ngIf="produits != null && produits.results != null && produits.results.length > 0" class="p-0">
      <div class="mat-table cart-table">
        <div class="mat-header-row">
          <div class="mat-header-cell" style="max-width: 150px;">{{ 'product-search.product'|translate }}</div>
          <div class="mat-header-cell-sortable">
            <div class="mat-header-title-block">
              <span class="mat-header-title">{{ 'product-search.ref'|translate }}</span>
              <mat-icon *ngIf="sortedReference == null" (click)="trierResultatsParReference()">swap_vert</mat-icon>
              <mat-icon *ngIf="sortedReference == 'ASC'" (click)="trierResultatsParReference()">arrow_upward</mat-icon>
              <mat-icon *ngIf="sortedReference == 'DESC'" (click)="trierResultatsParReference()">arrow_downward</mat-icon>
            </div>
          </div>
          <div class="mat-header-cell-sortable" style="max-width: 200px;">
            <div class="mat-header-title-block">
              <span class="mat-header-title">{{ 'product-search.brand'|translate }}</span>
              <mat-icon *ngIf="sortedMarque == null" (click)="trierResultatsParMarque()">swap_vert</mat-icon>
              <mat-icon *ngIf="sortedMarque == 'ASC'" (click)="trierResultatsParMarque()">arrow_upward</mat-icon>
              <mat-icon *ngIf="sortedMarque == 'DESC'" (click)="trierResultatsParMarque()">arrow_downward</mat-icon>
            </div>
          </div>
          <div class="mat-header-cell" style="max-width: 100px;">{{ 'product-search.available'|translate }}</div>
          <div class="mat-header-cell text-center" style="max-width: 50px;">
            &nbsp;
          </div>
        </div>
        <div *ngFor="let p of produits.results | paginate: { itemsPerPage: produits.results.length, currentPage: page, totalItems: produits.count  }" class="mat-row">
          <div class="mat-cell" style="max-width: 150px;">
            <a class="product-link" (click)="openProductDetailDialog(p)">
              <img [src]="getImagePrincipale(p, false)" alt="">
            </a>
          </div>
          <div class="mat-cell" style="text-align: left;">
            <a class="product-name" (click)="openProductDetailDialog(p)">{{p.autocomplete | reference:0}} <span class="product-name-second">{{p.autocomplete | reference:1}}</span></a><br>
            <span *ngIf="getLibelleCodeArticle(p) != null" class="product-code-article">{{ 'product-search.articleCodes'|translate }} : {{getLibelleCodeArticle(p)}}</span><br *ngIf="getLibelleCodeArticle(p) != null">
            <a *ngIf="p != null && p.filAriane != null" class="fil-ariane-product" (click)="goToFamille(p.filAriane.familleid)">{{p.filAriane.familledesc}}</a>
            <span *ngIf="p != null && p.filAriane != null" class="fil-ariane-product">&nbsp;->&nbsp;</span>
            <a *ngIf="p != null && p.filAriane != null" class="fil-ariane-product" (click)="goToGroupe(p.filAriane.familleid, p.filAriane.groupeid)">{{p.filAriane.groupedesc}}</a>
            <span *ngIf="p != null && p.filAriane != null" class="fil-ariane-product">&nbsp;->&nbsp;</span>
            <a *ngIf="p != null && p.filAriane != null" class="fil-ariane-product" (click)="goToClasse(p.filAriane.familleid, p.filAriane.groupeid, p.filAriane.classeid)">{{p.filAriane.classedesc}}</a>
          </div>
          <div class="mat-cell" style="max-width: 200px;">{{p.marque}}</div>
          <div class="mat-cell" style="max-width: 100px;">
            <span *ngIf="isEnStock(p); else noStock" class="text-success">{{ 'product-search.inStock'|translate }}</span>
            <ng-template #noStock>
              <span class="text-danger">{{ 'product-search.onOrder'|translate }}</span>
            </ng-template>
          </div>

          <div class="mat-cell text-center" style="max-width: 50px;">
            <div class="p-1">
              <mat-icon class="close" (click)="openProductDetailDialog(p)">shopping_cart</mat-icon>
            </div>
          </div>

        </div>
      </div>
    </mat-card>
    <div *ngIf="produits.results != null && produits.results.length > 0" fxLayout="row wrap ">
      <div fxFlex="100">
        <mat-card class="p-0 text-center no-shadow">
          <pagination-controls class="product-pagination" autoHide="true" maxSize="5" (pageChange)="onPageChanged($event)" previousLabel="{{ 'product-search.previous'|translate }}" nextLabel="{{ 'product-search.next'|translate }}"></pagination-controls>
        </mat-card>
      </div>
    </div>

    <!--
    <div fxFill="100" fxFlex="100" fxFlex.lt-sm="100" class="all-products">

      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" fxHide.lt-md="true" class="filter-row mat-elevation-z1 text-muted">
        <div></div>
        <div>
          <button mat-icon-button (click)="changeViewType('list', 100)">
            <mat-icon>view_list</mat-icon>
          </button>
          <button mat-icon-button (click)="changeViewType('list', 33.3)">
            <mat-icon>view_module</mat-icon>
          </button>
          <button mat-icon-button (click)="changeViewType('list', 25)">
            <mat-icon>view_comfy</mat-icon>
          </button>
        </div>
      </div>

      <div fxLayout="row wrap" class="products-wrapper">
        <div *ngFor="let p of produits.results | paginate: { itemsPerPage: produits.results.length, currentPage: page, totalItems: produits.count  }" fxFlex="100" [fxFlex.gt-sm]="viewCol" fxFlex.sm="50" class="col">
          <div class="product-box">
            <app-product-auto [produit]="p"></app-product-auto>
          </div>
        </div>
      </div>

      <div *ngIf="produits.results != null && produits.results.length > 0" fxLayout="row wrap ">
        <div fxFlex="100">
          <mat-card class="p-0 text-center no-shadow">
            <pagination-controls class="product-pagination" autoHide="true" maxSize="5" (pageChange)="onPageChanged($event)" previousLabel="Précédent" nextLabel="Suivant"></pagination-controls>
          </mat-card>
        </div>
      </div>
    </div>
    -->
  </div>
</div>
