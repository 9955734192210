export const GeneralEnum = {
  COOKIE_TOKEN_NAME : 'etn_token',
  STORAGE_USER : 'currentUser',
  STORAGE_CUSTOMER : 'selectedCustomer',
  STORAGE_AGENCE : 'selectedAgency',
  STORAGE_SELECTION : 'currentSelection',
  STORAGE_ETIMGROUPS : 'etimGroups',
  STORAGE_FAMILLES : 'familles',
  STORAGE_LANGUAGE : 'userLanguage',
  STORAGE_CUSTOMERS_LIST : 'authorizedCustomers',
  STORAGE_FILTERS_CLASS_COUNT : 'filtersClassCount',
  STORAGE_PUNCHOUT : 'punchoutInfos'
};
