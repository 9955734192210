<div class="home-1-wrap">
  <div class="container min-container">
    <div class="banners-container" fxLayout="column" fxLayout.gt-sm="row" *ngIf="familles.length > 0">
      <div fxLayout="row" fxLayout.gt-sm="column" fxLayout.xs="column" fxFlex="100" fxFlex.gt-sm="100">
        <div *ngFor="let row of familles" class="bn-wrap" fxLayout="column" fxLayout.gt-sm="row" fxFlexOrder="2" fxFlexOrder.gt-sm="1">
          <div *ngFor="let famille of row" class="baner seccond pd-xs" fxFlex [ngStyle]="getFamilleImage(famille)">
            <div class="home-bloc-info" (click)="afficherProduitsFamille(famille)">
              <div class="text-box" fxLayout="column" >
                <h6>{{getFamilleDescription(famille)}}</h6>
                <div color="primary" class="edit" (click)="afficherProduitsFamille(famille)">{{ 'home.consult'|translate }}</div>
              </div>
            </div>
          </div>
          <div *ngIf="row.length < 4" class="baner seccond pd-xs" fxFlex [ngStyle]="{'background': 'none', 'border': 'none'}"></div>
          <div *ngIf="row.length < 3" class="baner seccond pd-xs" fxFlex [ngStyle]="{'background': 'none', 'border': 'none'}"></div>
          <div *ngIf="row.length < 2" class="baner seccond pd-xs" fxFlex [ngStyle]="{'background': 'none', 'border': 'none'}"></div>
        </div>
      </div>
    </div>
  </div>
</div>
