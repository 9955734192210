import {Component, Inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {BasketService} from '../../../../services/basket.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Selection} from '../../../../pojo/selection';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Basket} from '../../../../models/basket';
import {Item} from '../../../../models/item';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-save-selection-dialog',
  templateUrl: './save-dialog.component.html',
  styleUrls: ['./save-dialog.component.sass']
})
export class SaveDialogComponent implements OnInit {

  saveSelectionForm: FormGroup;
  submitted = false;
  loading = false;
  error = '';

  constructor(private router: Router,
              private formBuilder: FormBuilder,
              private basketService: BasketService,
              private translate: TranslateService,
              public dialogRef: MatDialogRef<SaveDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public selections: Array<Selection>) { }

  ngOnInit() {
    this.saveSelectionForm = this.formBuilder.group({
      name: ['', Validators.required]
    });
  }

  get f() { return this.saveSelectionForm.controls; }

  public close(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.saveSelectionForm.invalid) {
      return;
    }

    this.loading = true;

    let uniqueName = true;
    this.basketService.list({is_saved: true}).toPromise().then(paniers => {
      if (paniers.count > 0) {
        paniers.results.forEach(p => {
          if (p.name.toLowerCase() === this.f.name.value.toLowerCase()) {
            this.f.name.setErrors({incorrect: true});
            uniqueName = false;
          }
        });
      }

      if (uniqueName) {
        const itemSelection: Array<Item> = new Array<Item>();

        this.selections.forEach(s => {
          const it: Item = {
            idproduit: s.produit.id,
            quantite: s.quantite,
            price: s.price
          };
          itemSelection.push(it);
        });

        const panier: Basket = {
          name: this.f.name.value,
          is_saved: true,
          items: itemSelection
        };

        this.basketService.add(panier).subscribe({
          next: async (result) => {
            for await (const sel of this.selections) {
              await this.basketService.removeProductFromSelection(sel.produit);
            }
            /*
            this.selections.forEach(selected => {
              await this.basketService.removeProductFromSelection(selected.produit).then(res => {console.log('remove ok'); });
            });*/

            await this.close();
            await this.router.navigate(['/pages/selection-list']);
          },
          error: err => this.error = err
        });
      }

      this.loading = false;
    });
  }

  async getSelections() {
    return this.selections;
  }

  diplayError(input) {
    if (input.errors.required) {
      return this.translate.instant('save-dialog.fieldMandatory');
    } else if (input.errors.incorrect) {
      return this.translate.instant('save-dialog.usedName');
    }
  }
}
