import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MainComponent } from './components/main/main.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './helpers/auth.guard';
import { ForgotPasswordComponent } from './components/security/forgotPassword/forgotPassword.component';
import { InitPasswordComponent } from './components/security/initPassword/initPassword.component';
import { ConfirmRequestComponent } from './components/security/confirmRequest/confirmRequest.component';
import { PunchOutComponent } from './components/security/punchOut/punchOut.component';
import { ExpiredPasswordComponent } from './components/security/expiredPassword/expiredPassword.component';
import { AlmostExpiredPasswordComponent } from './components/security/almostExpiredPassword/almostExpiredPassword.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'catalogue/default',
    pathMatch: 'full',
  },
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: 'catalogue',
        loadChildren: () =>
          import('./components/catalogue/catalogue.module').then(
            (m) => m.CatalogueModule
          ),
      },
      {
        path: 'pages',
        loadChildren: () =>
          import('./components/pages/pages.module').then((m) => m.PagesModule),
      } /*
      {
        path: 'blog',
        loadChildren: () => import('./components/blog/blog.module').then(m => m.BlogModule)
      },*/,
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'confirm-request',
    component: ConfirmRequestComponent,
  },
  {
    path: 'init-password',
    component: InitPasswordComponent,
  },
  {
    path: 'expired-password',
    component: ExpiredPasswordComponent,
  },
  {
    path: 'almost-expired-password',
    component: AlmostExpiredPasswordComponent,
  },
  {
    path: 'punch-out',
    component: PunchOutComponent,
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
