import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {handleError, queryPaginated} from '../cors/handlers';
import {Page} from '../models/page';
import {ApiEnum} from '../enums/api.enum';
import {Agence} from '../models/agence';

@Injectable({
  providedIn: 'root'
})
export class AgenceService {
  constructor(private httpClient: HttpClient) { }

  list(): Observable<Page<Agence>> {
    return queryPaginated(this.httpClient, ApiEnum.AGENCES_LIST);
  }

  detail(id): Observable<Page<Agence>> {
    return queryPaginated(this.httpClient, ApiEnum.AGENCES_DETAIL + '?numero=' + id);
    /*
    return this.httpClient.get<Agence>(ApiEnum.AGENCES_DETAIL + '?numero=' + id)
      .pipe(
        catchError(handleError)
      );*/
  }
}
