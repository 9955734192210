// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// remplacer url_api par 'http://192.168.199.54'
// url_api: 'http://localhost:8000'

export const environment = {
  production: false,
  url_api: 'https://myetnapi-staging.etngroup.com',
  // url_api: 'http://localhost',
  client_id: '381claMXLzdYY6pCuMTzP8PMbsoKkzvsEZkh1i9T',
  client_secret:
    'TTBLdCO6hqtrDboQRVSwhfZWI8cTleliTtoDum2KXtKe2qAydC24XZuyg5Z6uXcGsNR8INXkQdfFDUGjG6uTCIMX2zzPTDC0mrzduQMNpYifEfr3sySuLqONwThkx4tS',
  url_images: 'https://s3.eu-central-1.wasabisys.com/extranet/images/',
  version: '1.0.9-staging',
  nb_filtres_visibles: 12,
  help_fr_punchout:
    'https://s3.eu-central-1.wasabisys.com/extranet/media/manual/Mode_d_emploi_-_MyEtn_-_Punch_Out_-_Groupe_ETN_v2_FR.pdf',
  help_fr_client:
    'https://s3.eu-central-1.wasabisys.com/extranet/media/manual/Mode_d_emploi_-_MyEtn_Groupe_ETN_v2_FR.pdf',
  help_en_punchout:
    'https://s3.eu-central-1.wasabisys.com/extranet/media/manual/Mode_d_emploi_-_MyEtn_-_Punch_Out_-_Groupe_ETN_v2_EN.pdf',
  help_en_client:
    'https://s3.eu-central-1.wasabisys.com/extranet/media/manual/Mode_d_emploi_-_MyEtn_Groupe_ETN_v2_EN.pdf',
};
/*
export const environment = {
  production: false,
  url_api: 'https://myetnapi.etngroup.com',
  client_id: 'iLmojuo56G1OHPldRXxgTlDXUl5ZlGbY2uDfzwD2',
  client_secret: 'ZSJlycShYDvWRe4J5yGePnRwNGfaXD4px839RBiP4TCHDOUoxxa99x0Zd1eH9tf3BMPg01j1v1ciBr0kWXQdh3rmimVeQRhFQaStb6g6EVOqdUFYDP9feitCYYlXtCpY',
  url_images: 'https://s3.eu-central-1.wasabisys.com/extranet/images/'
};*/

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
