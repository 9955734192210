<div class="my-account sec-padding">
  <div class="login-header">
    <img src="assets/images/etn-logo-groupe.png">
  </div>
  <div class="login-container">
    <mat-card>
      <div class="my-account-wrap p-0">
        <div class="login-wrapper">
          <div class="title">
            <h3 class="text-center">{{ 'initPassword.title'|translate }}</h3>
          </div>
          <form class="login-form" [formGroup]="initForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
              <mat-form-field class="w-200">
                <input type="password" matInput placeholder="{{ 'initPassword.password'|translate }}" class="form-control" formControlName="password1" [ngClass]="{ 'is-invalid': submitted && f.password1.errors }">
                <mat-error *ngIf="submitted && f.password1.errors">Mot de passe invalide</mat-error>
              </mat-form-field>
            </div>
            <div class="form-group">
              <mat-form-field class="w-200">
                <input type="password" matInput placeholder="{{ 'initPassword.confirm'|translate }}" class="form-control" formControlName="password2" [ngClass]="{ 'is-invalid': submitted && f.password2.errors }">
                <mat-error *ngIf="submitted && f.password2.errors">Mot de passe invalide</mat-error>
              </mat-form-field>
            </div>
            <div *ngIf="error else btActif" class="form-group text-center">
              <button [disabled]="true" mat-raised-button class="btn-project" color="primary">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                {{ 'initPassword.update'|translate }}
              </button>
            </div>
            <ng-template #btActif>
              <div class="form-group text-center">
                <button [disabled]="loading" mat-raised-button class="btn-project" color="primary">
                  <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                  {{ 'initPassword.update'|translate }}
                </button>
              </div>
            </ng-template>
            <mat-error *ngIf="error">{{error}}</mat-error>
          </form>
        </div>
      </div>
    </mat-card>

  </div>
</div>

