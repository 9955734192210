<div class="home-1-wrap">
  <div class="container min-container">
    <div class="banners-container" fxLayout="column" fxLayout.gt-sm="row" *ngIf="groupes != null && groupes.length > 0">
      <h3>{{titre}}</h3>
    </div>
    <div class="banners-container" fxLayout="column" fxLayout.gt-sm="row" *ngIf="groupes != null && groupes.length > 0">
      <div fxLayout="row" fxLayout.gt-sm="column" fxLayout.xs="column" fxFlex="100" fxFlex.gt-sm="100">
        <div *ngFor="let row of groupes" class="bn-wrap" fxLayout="column" fxLayout.gt-sm="row" fxFlexOrder="2" fxFlexOrder.gt-sm="1">
          <div *ngFor="let groupe of row" class="baner seccond pd-xs" fxFlex [ngStyle]="getGroupeImage(groupe)">
            <div class="home-bloc-info" (click)="afficherProduitsGroupe(groupe)">
              <div class="text-box" fxLayout="column" >
                <h6>{{getGroupeDescription(groupe)}}</h6>
                <div color="primary" class="edit" (click)="afficherProduitsGroupe(groupe)">{{ 'home-groups.consult'|translate }}</div>
              </div>
            </div>
          </div>
          <div *ngIf="row.length < 4" class="baner seccond pd-xs" fxFlex [ngStyle]="{'background': 'none', 'border': 'none'}"></div>
          <div *ngIf="row.length < 3" class="baner seccond pd-xs" fxFlex [ngStyle]="{'background': 'none', 'border': 'none'}"></div>
          <div *ngIf="row.length < 2" class="baner seccond pd-xs" fxFlex [ngStyle]="{'background': 'none', 'border': 'none'}"></div>
        </div>
      </div>
    </div>
  </div>
</div>
