import {Component, Inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BasketService} from '../../../../services/basket.service';
import {Basket} from '../../../../models/basket';

@Component({
  selector: 'app-confirm-delete-list-selection',
  templateUrl: './confirm-delete-list-selection.component.html',
  styleUrls: ['./confirm-delete-list-selection.component.sass']
})
export class ConfirmDeleteListSelectionComponent implements OnInit {

  constructor(private router: Router,
              public dialogRef: MatDialogRef<ConfirmDeleteListSelectionComponent>,
              private basketService: BasketService,
              @Inject(MAT_DIALOG_DATA) public panier: Basket) { }

  ngOnInit() { }

  public close(): void {
    this.dialogRef.close();
  }

  public removeItem() {
    this.basketService.delete(this.panier.id).subscribe({
      next: result => {
        this.dialogRef.close();
        this.router.navigate(['/pages/selection-list']);
      },
      error: err => {
        this.dialogRef.close();
        this.router.navigate(['/pages/selection-list']);
      }
    });
  }
}
