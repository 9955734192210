<div class="my-account sec-padding">
  <div class="login-header">
    <img src="assets/images/etn-logo-groupe.png">
  </div>
  <div class="login-container">
    <mat-card>
      <div class="my-account-wrap p-0">
        <div class="login-wrapper">
          <div class="title">
            <h5 class="text-center">{{ 'almostExpiredPassword.title'|translate }} {{user.profile.pwd_expire_in}} {{ 'almostExpiredPassword.days'|translate }}</h5>
          </div>
          <form class="login-form">
            <div class="form-group text-center">
              <button mat-raised-button class="btn-project" color="primary" (click)="changePassword()">
                {{ 'almostExpiredPassword.update'|translate }}
              </button>&nbsp;
              <button mat-raised-button class="btn-project" color="accent" (click)="continuer()">
                {{ 'almostExpiredPassword.skip'|translate }}
              </button>
            </div>
            <div class="form-group text-center">

            </div>
            <mat-error *ngIf="error">{{error}}</mat-error>
          </form>
        </div>
      </div>
    </mat-card>

  </div>
</div>

