import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { FlexLayoutModule } from '@angular/flex-layout';

import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSelectModule} from '@angular/material/select';
import {MatTabsModule} from '@angular/material/tabs';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatDividerModule} from '@angular/material/divider';
import {MatRadioModule} from '@angular/material/radio';
import {MatListModule} from '@angular/material/list';
import {MatSliderModule} from '@angular/material/slider';
import {MatInputModule} from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { MenuComponent } from './menu/menu.component';
import { OrderByPipe } from './pipes/order-by.pipe';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ShoppingWidgetsComponent } from './shopping-widgets/shopping-widgets.component';
import { CategoriesMenuComponent } from './categories-menu/categories-menu.component';
import { CategoriesSectionComponent } from './categories-section/categories-section.component';
import {MatBadgeModule} from '@angular/material/badge';
import {NgxTypeaheadModule} from 'ngx-typeahead';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import {FormsModule} from '@angular/forms';
import {CategoriesHomeComponent} from './categories-home/categories-home.component';
import {ConfirmPanierDialogComponent} from './confirm-panier-dialog/confirm-panier-dialog.component';
import {TranslateModule} from '@ngx-translate/core';



@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    MenuComponent,
    SidebarComponent,
    OrderByPipe,
    ShoppingWidgetsComponent,
    CategoriesMenuComponent,
    CategoriesSectionComponent,
    CategoriesHomeComponent,
    ConfirmPanierDialogComponent
  ],
    imports: [
        CommonModule,
        RouterModule,
        MatButtonModule,
        MatSnackBarModule,
        MatToolbarModule,
        MatListModule,
        MatSliderModule,
        MatExpansionModule,
        MatBadgeModule,
        MatMenuModule,
        MatTableModule,
        MatRadioModule,
        MatDialogModule,
        MatChipsModule,
        MatInputModule,
        MatIconModule,
        MatSidenavModule,
        MatSelectModule,
        MatTabsModule,
        MatDividerModule,
        MatCardModule,
        FlexLayoutModule,
        TypeaheadModule,
        NgxTypeaheadModule,
        FormsModule,
        TranslateModule
    ],
  exports: [
    CommonModule,
    MatButtonModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatListModule,
    MatExpansionModule,
    MatMenuModule,
    MatTableModule,
    MatSliderModule,
    MatRadioModule,
    MatDialogModule,
    MatChipsModule,
    MatInputModule,
    MatIconModule,
    MatSidenavModule,
    MatSelectModule,
    MatTabsModule,
    MatDividerModule,
    MatCardModule,
    OrderByPipe,
    HeaderComponent,
    FooterComponent,
    MenuComponent,
    SidebarComponent,
    FlexLayoutModule,
    ShoppingWidgetsComponent,
    CategoriesMenuComponent,
    CategoriesSectionComponent,
    CategoriesHomeComponent,
    ConfirmPanierDialogComponent,
    TranslateModule

  ],
  providers: [],
  entryComponents: [
    ConfirmPanierDialogComponent
  ]
})
export class SharedModule {}
