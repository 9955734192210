<div class="container product-dialog zoom-product-dialog" style="min-width: auto!important;">

  <div class="close-btn-outer">
    <button mat-mini-fab color="warn" (click)="close()"><mat-icon>close</mat-icon></button>
  </div>
  <div mat-dialog-content style="min-width: auto!important;">
    <img [src]="images[index]?.full" alt="" class="img-fluid">
  </div>

</div>
