import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-confirm-request',
  templateUrl: './confirmRequest.component.html',
  styleUrls: ['./confirmRequest.component.sass']
})
export class ConfirmRequestComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {}

  backToHome() {
    this.router.navigate(['/login']);
  }
}
