import { Component, OnInit, Inject, ViewEncapsulation, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {environment} from '../../../../../environments/environment';
import {Produit} from '../../../../models/produit';

@Component({
  selector: 'app-product-zoom',
  templateUrl: './product-zoom.component.html',
  styleUrls: ['./product-zoom.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class ProductZoomComponent implements OnInit {
  public produit: Produit;
  public index;
  public images: Array<{mini?: string, full?: string}> = new Array<{mini?: string, full?: string}>();

  @ViewChild('zoomImage', { static: true }) zoomImage;

  constructor(public dialogRef: MatDialogRef<ProductZoomComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {product, index}) {
                this.produit = data.product;
                this.index = data.index;
               }

  ngOnInit() {
    this.construireListeImages();
  }

  public close(): void {
    this.dialogRef.close();
  }

  private construireListeImages() {
    const imageDefaut = 'assets/images/product/materiel_default.jpg';

    if (this.produit != null && this.produit.documentations != null && this.produit.documentations.length > 0) {
      let imgList = this.produit.documentations.filter(doc => doc.typedoc.toUpperCase() === 'ISW');

      if (imgList != null && imgList.length > 0) {
        imgList = imgList.sort((d1, d2) => d2.priorite - d1.priorite);

        imgList.forEach(i => {
          this.images.push({
            mini: environment.url_images + 'W50/' + i.url,
            full: environment.url_images + 'W400/' + i.url
          });
        });
      } else {
        this.images.push({
          mini: imageDefaut,
          full: imageDefaut
        });
      }
    }
  }

}
