import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Artclass, EtimGroup} from '../../../models/etimclass';
import {GeneralEnum} from '../../../enums/general.enum';
import {ProduitService} from '../../../services/produit.service';
import {Filtersclasscount} from '../../../models/filtersclasscount';

@Component({
  selector: 'app-etimclasses',
  templateUrl: './etimclasses.component.html',
  styleUrls: ['./etimclasses.component.sass']
})
export class EtimclassesComponent implements OnInit {

  @Output() classChanged = new EventEmitter();
  @Input() etimGroup: EtimGroup;
  @Input() preselectedClass: Artclass;
  @Input() isStock: boolean;

  public selectedClass: Artclass;
  public classCount: Array<Filtersclasscount>;

  constructor(public produitService: ProduitService) { }

  ngOnInit() {
    this.selectedClass = this.preselectedClass;

    if (this.isStock) {
      this.produitService.filtersClassCountFiltered(this.etimGroup.artgroupid, null, this.isStock).subscribe({
        next: value => {
          this.classCount = value;
        },
        error: err => {
          this.classCount = null;
        }
      });
    }
  }

  classSelect(etimclass) {
    this.selectedClass = etimclass;

    this.classChanged.emit(
      etimclass
    );
  }

  getClassDescription(classe: Artclass) {
    const langue = localStorage.getItem(GeneralEnum.STORAGE_LANGUAGE);

    if (classe != null) {
      let desc = classe.arclassdesc.filter(d => d.langue === langue);

      if (desc == null || desc.length === 0) {
        desc = classe.arclassdesc.filter(d => d.langue === 'en-GB');
      }
      if ((desc == null || desc.length === 0) && this.selectedClass.arclassdesc.length > 0) {
        desc = classe.arclassdesc;
      }

      return desc[0].arclassdesc;
    }

    return null;
  }

  getNombreProduit(classe: Artclass) {
    if (this.isStock && this.classCount != null) {
      const searchClass = this.classCount.filter(c => c.artclassid === classe.artclassid);

      if (searchClass != null && searchClass.length > 0 && searchClass[0].ref__count > 0) {
        return searchClass[0].ref__count;
      }
    } else {
      const filtersClass = JSON.parse(localStorage.getItem(GeneralEnum.STORAGE_FILTERS_CLASS_COUNT));
      const filter = filtersClass.filter(c => c.artclassid === classe.artclassid);

      if (filter != null && filter.length > 0 && filter[0].ref__count > 0) {
        return filter[0].ref__count;
      }
    }

    return null;
  }

  getClassesTriees(groupe) {
    return groupe.etimartclass.sort((class1, class2) => {
      if (this.getClassDescription(class1).normalize('NFD').replace(/[\u0300-\u036f]/g, '') > this.getClassDescription(class2).normalize('NFD').replace(/[\u0300-\u036f]/g, '')) {
        return 1;
      }
      if (this.getClassDescription(class1).normalize('NFD').replace(/[\u0300-\u036f]/g, '') < this.getClassDescription(class2).normalize('NFD').replace(/[\u0300-\u036f]/g, '')) {
        return -1;
      }
      return 0;
    });
  }
}
