import {GeneralEnum} from '../enums/general.enum';
import {Famille, FamilleClass, FamilleGroup} from '../models/famille';
import {ClassementEtim} from '../models/classementetim';

export abstract class Etimclassutil {

  public static getFilArianeFromClasse(classId: string): string {
    const familles: Array<Famille> = JSON.parse(localStorage.getItem(GeneralEnum.STORAGE_FAMILLES));
    const langue = localStorage.getItem(GeneralEnum.STORAGE_LANGUAGE);
    let filAriane = null;

    familles.filter(f => f !== null).forEach(f => {
      f.groups.forEach(g => {
        g.etimartclass.forEach(c => {
          if (c.artclassid === classId) {
            filAriane =  this.getFamilleDescription(f, langue) + ' -\u{003e} ' + this.getGroupeDescription(g, langue) + ' -\u{003e} ' + this.getClasseDescription(c, langue);
          }
        });
      });
    });

    return filAriane;
  }

  public static getGroupeArianeFromClasse(classId: string): ClassementEtim {
    const familles: Array<Famille> = JSON.parse(localStorage.getItem(GeneralEnum.STORAGE_FAMILLES));
    const langue = localStorage.getItem(GeneralEnum.STORAGE_LANGUAGE);
    let classement: ClassementEtim = null;

    familles.filter(f => f !== null).forEach(f => {
      f.groups.forEach(g => {
        g.etimartclass.forEach(c => {
          if (c.artclassid === classId) {
            classement = {
              familleid: f.artfamilleid,
              familledesc: this.getFamilleDescription(f, langue),
              groupeid: g.artgroupid,
              groupedesc: this.getGroupeDescription(g, langue),
              classeid: c.artclassid,
              classedesc: this.getClasseDescription(c, langue)
            };
          }
        });
      });
    });

    return classement;
  }

  public static getHierarchieIds(classId: string) {
    const familles: Array<Famille> = JSON.parse(localStorage.getItem(GeneralEnum.STORAGE_FAMILLES));
    let familleId;
    let groupeId;
    let classeId;

    familles.filter(f => f !== null).forEach(f => {
      f.groups.forEach(g => {
        g.etimartclass.forEach(c => {
          if (c.artclassid === classId) {
            familleId = f.artfamilleid;
            groupeId = g.artgroupid;
            classeId = c.artclassid;
          }
        });
      });
    });

    return {
      famille: familleId,
      groupe: groupeId,
      classe: classeId
    };
  }

  private static getFamilleDescription(famille: Famille, langue: string) {
    let description = null;

    if (famille != null && famille.descriptions) {
      const desc = famille.descriptions.filter(d => d.langue === langue);

      if (desc != null && desc.length === 1) {
        description = desc[0].artfamilledesc;
      }
    }

    return description;
  }

  private static getGroupeDescription(groupe: FamilleGroup, langue: string) {
    let description = null;

    if (groupe != null && groupe.groupdesc != null) {
      const desc = groupe.groupdesc.filter(d => d.langue === langue);

      if (desc != null && desc.length === 1) {
        description = desc[0].groupdesc;
      }
    }

    return description;
  }

  private static getClasseDescription(classe: FamilleClass, langue: string) {
    let description = null;

    if (classe != null && classe.arclassdesc != null) {
      const desc = classe.arclassdesc.filter(d => d.langue === langue);

      if (desc != null && desc.length === 1) {
        description = desc[0].arclassdesc;
      }
    }

    return description;
  }
}
