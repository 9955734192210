import { environment } from '../../environments/environment';

export const LangueEnum = {
  FRANCAIS : 'fr-FR',
  ANGLAIS : 'en-GB',
  ESPAGNOL: 'es-ES',
  PORTUGAIS: 'pt-PT',
  ALLEMAND : 'de-DE',
  TCHEQUE: 'cz-CZ',
  BELGE : 'fr-BE',
  SOLVAQUE : 'SVK',
  HOLLANDAIS : 'NLD',
  INCONNU : 'CHE'
};
