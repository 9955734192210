import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PriceComponent } from '../products/price/price.component';
import { ProductComponent } from '../products/product/product.component';
import { ProductDetailsComponent } from '../products/product-details/product-details.component';
import { ProductDialogComponent } from '../products/product-dialog/product-dialog.component';
import { CommonModule } from '@angular/common';
import { CatalogueRoutingModule } from './catalogue-routing.module';
import { SharedModule } from '../shared/shared.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxPaginationModule } from 'ngx-pagination';
// Import the library
import { NgxImageZoomModule } from 'ngx-image-zoom';

import { ProductZoomComponent } from '../products/product-details/product-zoom/product-zoom.component';
import { HomeComponent } from './home/home.component';
import { ProductSearchComponent } from './product-search/product-search.component';
import { ProductSearchAutoComponent } from './product-search-auto/product-search-auto.component';
import { CategoriesSearchComponent } from './categories-search/categories-search.component';
import { EtimgroupsComponent } from '../widgets/etimgroups/etimgroups.component';
import { EtimfeaturesComponent } from '../widgets/etimfeatures/etimfeatures.component';
import { EtimclassesComponent } from '../widgets/etimclasses/etimclasses.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouterModule } from '@angular/router';
import { ReferencePipe } from '../../pipes/reference.pipe';
import { SaveDialogComponent } from '../pages/selection/save-dialog/save-dialog.component';
import { PricesDmdDialogComponent } from '../pages/selection/prices-dmd-dialog/prices-dmd-dialog.component';
import { CommandeDialogComponent } from '../pages/selection/commande-dialog/commande-dialog.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ProductDetailsDialogComponent } from '../products/product-details-dialog/product-details-dialog.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ProductAutoComponent } from '../products/product-auto/product-auto.component';
import { HomeGroupsComponent } from './home-groups/home-groups.component';
import { ConfirmDeleteSelectionComponent } from '../pages/selection/confirm-delete-selection/confirm-delete-selection.component';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { ConfirmDeleteListSelectionComponent } from '../pages/selection/confirm-delete-list-selection/confirm-delete-list-selection.component';
import { ConfirmDeleteSelectionConsultComponent } from '../pages/selection/confirm-delete-selection-consult/confirm-delete-selection-consult.component';
import { DevisDialogComponent } from '../pages/selection/devis-dialog/devis-dialog.component';
import { SignalerErreurComponent } from '../products/signalerErreur/signalerErreur.component';

@NgModule({
  declarations: [
    PriceComponent,
    ProductComponent,
    ProductAutoComponent,
    ProductDetailsComponent,
    ProductDetailsDialogComponent,
    ProductDialogComponent,
    SignalerErreurComponent,
    HomeComponent,
    HomeGroupsComponent,
    ProductSearchComponent,
    ProductSearchAutoComponent,
    CategoriesSearchComponent,

    EtimfeaturesComponent,
    EtimclassesComponent,
    EtimgroupsComponent,
    ProductZoomComponent,

    ReferencePipe,
  ],
  imports: [
    CommonModule,
    CatalogueRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    NgxPaginationModule,
    NgxImageZoomModule,
    MatExpansionModule,
    RouterModule,
    NgxSpinnerModule,
    MatCheckboxModule,
    SwiperModule,
    // NgxImageZoomModule.forRoot() // <-- Add this line
  ],
  exports: [
    ProductDialogComponent,
    ProductZoomComponent,
    HomeComponent,
    HomeGroupsComponent,
    ProductSearchComponent,
    ProductSearchAutoComponent,
    CategoriesSearchComponent,
    ProductDetailsDialogComponent,
    SignalerErreurComponent,
  ],

  entryComponents: [
    ProductDialogComponent,
    ProductDetailsDialogComponent,
    SignalerErreurComponent,
    ProductZoomComponent,
    SaveDialogComponent,
    PricesDmdDialogComponent,
    CommandeDialogComponent,
    DevisDialogComponent,
    ConfirmDeleteSelectionComponent,
    ConfirmDeleteSelectionConsultComponent,
    ConfirmDeleteListSelectionComponent,
  ],
})
export class CatalogueModule {}
