<div class="commerce-buttons" fxLayout="row" fxLayoutAlign="end center">
  <button [matMenuTriggerFor]="menu" class="flex-row-button mat-button" fxLayoutAlign="space-between center" mat-button>
    <div class="mat-button-wrapper">
      <div class="" >
        <mat-icon class="mat-icon-lg mat-icon material-icons">local_grocery_store</mat-icon>
        <span class="cart-count-wrapper">{{produits.length}}</span>
      </div>
      <div class="top-cart">
        <span *ngIf="getTotal() != null">{{ getTotal() | number: '1.2':'fr' }} {{ devise }}</span>
        <span *ngIf="getTotal() == null">- {{ devise }}</span>
      </div>
    </div>
  </button>
  <mat-menu #menu="matMenu"  class="spCard-main">
    <div class="spCard-dropdown">
      <div class="card-list-title" fxLayout="row" fxLayoutAlign="space-between start">
        <a [routerLink]="['/pages/selection']" routerLinkActive="router-link-active" ><p>{{ 'searchBar.selection'|translate|uppercase }}</p></a>
        <p>{{produits.length}} {{ 'searchBar.products'|translate }}</p>
      </div>
      <mat-divider></mat-divider>
      <p *ngIf="!produits.length" class="woo-message">{{ 'searchBar.emptySelection'|translate }}</p>
      <div *ngIf="produits.length" class="new-product" fxLayout="column">
        <div *ngFor="let item of produits" class="item" fxLayout="row" fxLayoutAlign="start start">
          <div class="product">
            <img [src]="getImagePrincipale(item.produit, false)" alt="">
          </div>
          <div class="desc">
            <p>{{getLibelleProduit(item.produit)}}</p>
            <span>{{getArrondi(item.quantite)}} X {{getArrondi(item.price)}} {{item.devise}}</span>
          </div>
          <div class="close-circle">
            <a (click)="removeItem(item)"><i class="material-icons">delete</i></a>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div *ngIf="getTotal() != null" class="total" fxLayout="row" fxLayoutAlign="space-between center">
          <p class="text-muted">{{ 'searchBar.total'|translate }}: </p>
          <span>{{ getTotal()  | number: '1.2':'fr' }} {{ devise }}</span>
        </div>
      </div>
    </div>
  </mat-menu>
</div>

