import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralEnum } from '../../../enums/general.enum';
import { Famille } from '../../../models/famille';
import { EtimGroup } from '../../../models/etimclass';
import { LangueEnum } from '../../../enums/langue.enum';
import { EtimgroupService } from '../../../services/etimgroup.service';

@Component({
  selector: 'app-home-groups',
  templateUrl: './home-groups.component.html',
  styleUrls: ['./home-groups.component.sass'],
})
export class HomeGroupsComponent implements OnInit {
  public groupes: Array<any> = [];
  public idFamille: string;
  public titre: string;
  error: string;

  constructor(private route: ActivatedRoute, private router: Router) {
    this.route.queryParams.subscribe((params) => {
      this.idFamille = params.id;

      const familles = JSON.parse(
        localStorage.getItem(GeneralEnum.STORAGE_FAMILLES)
      );
      const selectedFamille = familles.filter(
        (f) => f.artfamilleid === this.idFamille
      );

      if (selectedFamille != null && selectedFamille.length > 0) {
        this.titre = this.getFamilleDescription(selectedFamille[0]);

        const sortedGroups = this.trierGroupes(selectedFamille[0].groups);

        let row: Array<any> = [];
        let iCol = 0;
        let iRow = 0;
        for (let i = 0; i < sortedGroups.length; i++) {
          row[iCol] = sortedGroups[i];
          iCol++;
          if ((i + 1) % 4 === 0) {
            this.groupes[iRow] = row;
            row = [];
            iRow++;
            iCol = 0;
          }
        }
        if (row.length > 0) {
          this.groupes[iRow] = row;
        }
      }
    });
  }

  ngOnInit() {}

  trierGroupes(groups?: Array<EtimGroup>): Array<EtimGroup> {
    if (groups != null) {
      return groups.sort((g1, g2) =>
        this.getGroupeDescription(g1).localeCompare(
          this.getGroupeDescription(g2)
        )
      );
    }

    return null;
  }

  public getFamilleDescription(famille: Famille) {
    const langue = localStorage.getItem(GeneralEnum.STORAGE_LANGUAGE);
    let desc = famille.descriptions.filter((d) => d.langue === langue);

    if (desc == null || desc.length === 0) {
      desc = famille.descriptions.filter(
        (d) => d.langue === LangueEnum.ANGLAIS
      );
    }
    if (
      (desc == null || desc.length === 0) &&
      famille.descriptions.length > 0
    ) {
      desc = famille.descriptions;
    }

    return desc[0].artfamilledesc;
  }

  public getGroupeImage(groupe) {
    return {
      'background-image':
        groupe != null && groupe.image != null
          ? 'url(' + groupe.image + ')'
          : 'url(' + 'assets/images/categories/inventory.png' + ')',
    };
  }

  public afficherProduitsGroupe(groupe) {
    this.router.navigate(['/catalogue/category/search'], {
      queryParams: {
        type: 'groupe',
        id: groupe.artgroupid,
        idFamille: this.idFamille,
      },
    });
  }

  public getGroupeDescription(group: EtimGroup) {
    const langue = localStorage.getItem(GeneralEnum.STORAGE_LANGUAGE);
    let desc = group.groupdesc.filter((d) => d.langue === langue);

    if (desc == null || desc.length === 0) {
      desc = group.groupdesc.filter((d) => d.langue === LangueEnum.ANGLAIS);
    }
    if ((desc == null || desc.length === 0) && group.groupdesc.length > 0) {
      desc = group.groupdesc;
    }

    return desc[0].groupdesc;
  }
}
