import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { queryPaginated } from '../cors/handlers';
import { Page } from '../models/page';
import {Produit, SearchMarque} from '../models/produit';
import {ApiEnum} from '../enums/api.enum';
import {Produitautocomplete} from '../models/produitautocomplete';
import {Produitautocompletefull} from '../models/produitautocompletefull';
import {Filtersclasscount} from '../models/filtersclasscount';
import {Filtersmarquecount} from '../models/filtersmarquecount';
import {Filtersfeaturecount} from '../models/filtersfeaturecount';
import {FiltersGlobalCount} from '../models/filtersglobalcount';
import {ErreurProduit} from '../models/erreurProduit';

@Injectable({
  providedIn: 'root'
})
export class ProduitService {
  constructor(private httpClient: HttpClient) { }

  listByCriteres(url?: string, urlOrFilter?: string | object): Observable<Page<Produit>> {
    if (url == null) {
      url = ApiEnum.PRODUITS_SEARCH_CRITERES;
    }

    return queryPaginated<Produit>(this.httpClient, url, urlOrFilter);
  }

  listMarques(): Observable<Array<SearchMarque>> {
    return this.httpClient.get<Array<SearchMarque>>(ApiEnum.PRODUITS_SEARCH_MARQUES);
  }

  listMarquesForEtimGroup(etimgroup: string, filter: string = null): Observable<Array<SearchMarque>> {
    let params = '?etimgroup=' + etimgroup;

    if (filter != null) {
      params += '&' + filter;
    }

    return this.httpClient.get<Array<SearchMarque>>(ApiEnum.PRODUITS_SEARCH_MARQUES + params);
  }

  listMarquesForEtimClass(etimclass: string, filter: string = null): Observable<Array<SearchMarque>> {
    let params = '?etimclass__artclassid=' + etimclass;

    if (filter != null) {
      params += '&' + filter;
    }

    return this.httpClient.get<Array<SearchMarque>>(ApiEnum.PRODUITS_SEARCH_MARQUES + params);
  }

  get(id: number): Observable<Produit> {
    return this.httpClient.get<Produit>(ApiEnum.PRODUIT_DETAIL + id + '/');
  }

  searchAutocomplete(text: string, lang: string, pageNumber?: number) {
    const params = {
      q: text,
      langue: lang
    };

    if (pageNumber != null) {
      params['page'] = pageNumber;
    }

    return queryPaginated<Produitautocomplete>(this.httpClient, ApiEnum.PRODUITS_AUTOCOMPLETE_LIGHT, params);
  }

  searchAutocompleteFull(text: string, lang: string, pageNumber?: number, ordering?: string, isStock?: boolean) {
    const params = {
      q: text,
      langue: lang
    };

    if (isStock != null) {
      params['isStock'] = isStock ? 1 : 0;
    }

    if (pageNumber != null) {
      params['page'] = pageNumber;
    }

    if (ordering != null) {
      params['ordering'] = ordering;
    }

    // console.log(params);

    return queryPaginated<Produitautocompletefull>(this.httpClient, ApiEnum.PRODUITS_AUTOCOMPLETE, params);
  }

  searchAutocompleteWithParams(params: Array<any>, pageNumber?: number) {
    if (pageNumber != null) {
      params['page'] = pageNumber;
    }

    return queryPaginated<Produitautocompletefull>(this.httpClient, ApiEnum.PRODUITS_AUTOCOMPLETE, params);
  }

  searchAutocompleteWithUrl(url: string) {
    return queryPaginated<Produitautocompletefull>(this.httpClient, url);
  }

  filtersClassCount() {
    return this.httpClient.get<Array<Filtersclasscount>>(ApiEnum.PRODUITS_FILTERS_CLASS_COUNT);
  }

  filtersClassCountFiltered(groupe: string, marques?: string, isStock?: boolean) {
    let params = '?etimgroup=' + groupe;

    if (marques != null) {
      params += '&' + marques;
    }

    if (isStock != null) {
      params += isStock ? '&isStock=1' : '&isStock=0';
    }

    return this.httpClient.get<Array<Filtersclasscount>>(ApiEnum.PRODUITS_FILTERS_CLASS_COUNT + params);
  }

  filtersMarqueCount(etimclass?: string, filter: string = null) {
    let params = etimclass !== null ? '?etimclass__artclassid=' + etimclass : '';

    if (filter != null) {
      params += '&' + filter;
    }

    return this.httpClient.get<Array<Filtersmarquecount>>(ApiEnum.PRODUITS_FILTERS_MARQUE_COUNT + params);
  }

  filtersFeatureCount(etimgroup: string, etimclass?: string, filter: string = null) {
    let params = etimclass != null ? '?etimclass__artclassid=' + etimclass : '?etimgroup=' + etimgroup;

    if (filter != null) {
      params += '&' + filter;
    }

    return this.httpClient.get<Array<Filtersfeaturecount>>(ApiEnum.PRODUITS_FILTERS_FEATURE_COUNT + params);
  }

  filtersGlobalCount(etimgroup: string, etimclass?: string, filter: string = null) {
    let params = etimclass != null ? '?etimclass__artclassid=' + etimclass : '?etimgroup=' + etimgroup;

    if (filter != null) {
      params += '&' + filter;
    }

    return this.httpClient.get<FiltersGlobalCount>(ApiEnum.PRODUITS_FILTERS_GLOBAL_COUNT + params);
  }

  signalerErreurProduit(erreurProduit: ErreurProduit): Observable<ErreurProduit> {
    return this.httpClient.post<ErreurProduit>(ApiEnum.PRODUIT_SIGNALER_ERREUR, erreurProduit);
  }
}
