import { environment } from '../../environments/environment';

export const ApiEnum = {
  GET_TOKEN : environment.url_api + '/o/token/',
  USER_INFO : environment.url_api + '/users/me/',
  USER_UPDATE : environment.url_api + '/users/',
  PRODUITS_SEARCH_CRITERES : environment.url_api + '/produits/',
  PRODUITS_SEARCH_MARQUES : environment.url_api + '/produits/marque',
  PRODUITS_AUTOCOMPLETE : environment.url_api + '/search/',
  PRODUITS_AUTOCOMPLETE_LIGHT : environment.url_api + '/search-light/',
  PRODUITS_FILTERS_CLASS_COUNT : environment.url_api + '/produits/filters_class_count/',
  PRODUITS_FILTERS_FEATURE_COUNT : environment.url_api + '/produits/filters_feature_count',
  PRODUITS_FILTERS_MARQUE_COUNT : environment.url_api + '/produits/filters_marque_count',
  PRODUITS_FILTERS_GLOBAL_COUNT : environment.url_api + '/produits/filters_count',
  PRODUIT_DETAIL : environment.url_api + '/produits/',
  PRODUIT_SIGNALER_ERREUR : environment.url_api + '/produits/issue/',
  AGENCES_LIST : environment.url_api + '/agences/',
  AGENCES_DETAIL : environment.url_api + '/agences/',
  BASKET_LIST : environment.url_api + '/baskets/',
  BASKET_DETAIL : environment.url_api + '/baskets/',
  BASKET_CREATE : environment.url_api + '/baskets/',
  BASKET_UPDATE : environment.url_api + '/baskets/',
  BASKET_DELETE : environment.url_api + '/baskets/',
  CONTACT : environment.url_api + '/contact/',
  CUSTOMERS_LIST : environment.url_api + '/customers/',
  CUSTOMERS_DETAIL : environment.url_api + '/customers/',
  CUSTOMERS_AUTHORIZED : environment.url_api + '/customer-authorized/',
  CUSTOMERS_CHANGE : environment.url_api + '/change-customer/',
  ETIM_CLASS_LIST : environment.url_api + '/etimclass/',
  ETIM_CLASS_DETAIL : environment.url_api + '/etimclass/',
  ETIM_FAMILLE_LIST : environment.url_api + '/etimfamilles/',
  ETIM_FAMILLE_DETAIL : environment.url_api + '/etimfamilles/',
  ETIM_GROUP_LIST : environment.url_api + '/etimgroups/',
  ETIM_GROUP_DETAIL : environment.url_api + '/etimgroups/',
  ETIM_FEATURE_LIST : environment.url_api + '/etimfeatures/',
  ETIM_FEATURE_DETAIL : environment.url_api + '/etimfeatures/',
  ETIM_VALUES_DETAIL : environment.url_api + '/etimvalues/',
  ORDER_LIST : environment.url_api + '/orders/',
  ORDER_DETAIL : environment.url_api + '/orders/',
  ORDER_CREATE : environment.url_api + '/orders/',
  ORDER_UPDATE : environment.url_api + '/orders/',
  ORDER_DELETE : environment.url_api + '/orders/',
  PRICE_LIST : environment.url_api + '/prices/',
  PRICE_DETAIL : environment.url_api + '/prices/',
  SEARCH_ETIM : environment.url_api + '/search-etim/',
  SECURITY_RESET_PASSWORD : environment.url_api + '/reset-password/',
  SECURITY_SET_PASSWORD : environment.url_api + '/set-password/',
  SECURITY_CHANGE_PASSWORD : environment.url_api + '/change-password/',
};
