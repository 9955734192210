import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router, UrlSerializer} from '@angular/router';
import {Location} from '@angular/common';
import {GeneralEnum} from '../../../enums/general.enum';
import {UserBack} from '../../../models/userback';

@Component({
  selector: 'app-almost-expired-password',
  templateUrl: './almostExpiredPassword.component.html',
  styleUrls: ['./almostExpiredPassword.component.sass']
})
export class AlmostExpiredPasswordComponent implements OnInit {

  error = '';

  user: UserBack = null;
  returnUrl: string = null;

  constructor(private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private urlSerializer: UrlSerializer,
              private location: Location,
              private router: Router) {
    this.route.queryParams.subscribe(params => {
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    });
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem(GeneralEnum.STORAGE_USER));
  }

  changePassword() {
    this.router.navigate(['/expired-password']);
  }

  continuer() {
    this.router.navigate([this.returnUrl]);
  }
}
