import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Customer } from '../models/customer';
import {handleError, queryPaginated} from '../cors/handlers';
import {Page} from '../models/page';
import {ApiEnum} from '../enums/api.enum';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  constructor(private httpClient: HttpClient) { }

  listCustomers(): Observable<Page<Customer>> {
    return queryPaginated(this.httpClient, ApiEnum.CUSTOMERS_LIST);
  }

  listCustomersAuthorized(): Observable<Page<Customer>> {
    return queryPaginated(this.httpClient, ApiEnum.CUSTOMERS_AUTHORIZED);
  }

  detail(id): Observable<Customer> {
    return this.httpClient.get<Customer>(ApiEnum.CUSTOMERS_DETAIL + id)
      .pipe(
        catchError(handleError)
      );
  }

  // Disponible seulement pour les utilisateurs du groupe Agence
  changeCustomer(customer: {id: number}): Observable<boolean> {
    return this.httpClient.put<any>(ApiEnum.CUSTOMERS_CHANGE, customer)
    .pipe(
      tap(data => {
        if (data.status === 'success') {
          return of(true);
        }
        return of(false);
      }),
      catchError(error => {
        return of(false);
      })
    );
  }

}
